import React, { useState, useEffect } from 'react';
import Sidebar from '../../Common/Sidebar/Sidebar';
import { accountMenuItems, menuItems, navMenuItems } from "../../Constants/LayoutCommon";
import { Button, Drawer, Select, Table, Tooltip, Input } from "antd";
import AddUser from './AddUser';
import { EditOutlined, EyeOutlined, CheckCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { allactiveUserslist, deleteUser, actUser, getAllManager } from '../../utils/servies/common';
import ConfirmBox from '../../UIComponent/ConfirmBox';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { actUser } from '../../utils/servies/common';

const ManageUser = () => {

  const [selectedUser, setSelectedUser] = useState({});
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false)
  const [openActive, setOpenActive] = useState(false)
  const [data, setData] = useState()
  const [deleteData, setDeleteData] = useState({})
  const [dataActive, setDataActive] = useState()
  const [managerList, setManagerList] = useState([])
  const [selectedManager, setSelectedManager] = useState()

  const role = localStorage.getItem('role')
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10
    }
  });
  const [filterUser, setFilterUser] = useState({})
  const getData = (datas) => {
    allactiveUserslist(datas).then((res) => {
      setFilterUser(res.data)
      setData(res.data)
    })
  }
  const getAllmangerData = () => {
    getAllManager().then(res => {
      setManagerList(res)
    })
  }
  useEffect(() => {
    getData({});
  }, [visible]);

  useEffect(() => {
    getAllmangerData()
  }, [])


  const addUser = () => {
    setVisible(true);
    setSelectedUser({});
  };
  const backToDashboard = () => {
    setVisible(false);
    setSelectedUser({});
  };

  const editUser = (element) => {
    setVisible(true);
    setSelectedUser(element);
  };

  const viewUser = (element) => {
    const el = { ...element };
    el.isView = true;
    setVisible(true)
    setSelectedUser(el)
  }
  const deActive = async () => {
    await deleteUser({ userId: deleteData._id, isUserActive: dataActive }).then(() => {
      if (dataActive === false) {
        toast.success("successfully Deactivated")
      }
      else {
        toast.success("successfully Activated")
      }
    }).catch(() => {
      toast.error("Something Went Wrong")
    })
    getData({});
    setOpen(false)
    setOpenActive(false)
    backToDashboard()
  }
  // const Active = async () => {
  //   await actUser({ userId: deleteData._id }).then(() => {
  //     toast.success("successfully Activated")
  //   }).catch(() => {
  //     toast.error("Something Went Wrong")
  //   })
  //   getData();
  //   setOpen(false)
  // }

  const activeUser = (element, value) => {
    if (value === "deactive") {
      setOpen(true);
      setDeleteData(element)
      setDataActive(false)
      // console.log(element)
    }
    else {
      setOpenActive(true)
      setDeleteData(element)
      setDataActive(true)
    }
  }
  const handlemangerSelect = (event, key) => {
    if (event != "All") {
      getData({ branch: event })
    } else {
      getData({})
    }
  }

  const onSearch = (searchText) => {
    filterUser.length ?
      setData(filterUser.filter(el =>
        `${el.name},${el.userName},${el.mobile},${el.email},${el.branch},${el.role},${el.ifsc},${el.micr},${el.bankBranch},${el.accountNumber},${el.bankName},${el?.branch?.branchName},${el.accountHolderName}`.toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1)
      ) : setData(filterUser)
  }

  const searchOnChange = (event) => {
    onSearch(event.target.value)
    setConfiguration(config => {
      return {
        ...config,
        filter: event.target.value
      }
    })
  }
  const columns = [
    // {
    //   title: 'Name',
    //   dataIndex: 'name',
    //   key: 'name',

    // },
    {
      title: 'Name',
      dataIndex: 'userName',
      key: 'userName'
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    // {
    //   title: 'Aadhar Number',
    //   dataIndex: 'aadharNumber',
    //   key: 'aadharNumber'
    // },
    // {
    //   title: 'Pancard Number',
    //   dataIndex: 'panNumber',
    //   key: 'panNumber'
    // },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role) => {
        return role.toUpperCase()
      }
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      render: (branch) => {
        return branch?.branchName

      }
    },
    // {
    //   title: 'Account Holder Name',
    //   dataIndex: 'accountHolderName',
    //   key: 'accountHolderName'
    // },
    // {
    //   title: 'Account Number',
    //   dataIndex: 'accountNumber',
    //   key: 'accountNumber'
    // },
    // {
    //   title: 'Bank Name',
    //   dataIndex: 'bankName',
    //   key: 'bankName'
    // },
    // {
    //   title: 'Bank Branch',
    //   dataIndex: 'bankBranch',
    //   key: 'bankBranch'
    // },
    // {
    //   title: 'IFSC Code',
    //   dataIndex: 'ifsc',
    //   key: 'ifsc'
    // },
    // {
    //   title: 'MICR Number',
    //   dataIndex: 'micr',
    //   key: 'micr'
    // },
    {
      title: 'Active',
      dataIndex: 'isUserActive',
      key: 'isUserActive',
      render: (isUserActive, element) => {
        return (
          isUserActive ? <Button style={{ backgroundColor: "#23f75b", borderRadius: "4px", textAlign: "center", padding: "4px 4px", marginTop: "16px", color: "white" }} onClick={() => activeUser(element, "deactive")
          }>Active</Button> :
            <Button style={{ backgroundColor: "#ff3030", borderRadius: "4px", textAlign: "center", padding: "4px 4px", marginTop: "16px", color: "white" }} onClick={() => activeUser(element, "active")}>DeActive</Button>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, element, index) => <div className="actionContainer">
        <Tooltip placement="bottom" title="Edit">
          <EditOutlined onClick={() => editUser(element)} />
        </Tooltip>
        <Tooltip placement="bottom" title="View">
          <EyeOutlined onClick={() => viewUser(element)} />
        </Tooltip>
        {/* <Tooltip placement="bottom" title="Active">
          <CheckCircleOutlined  onClick={() => activeUser(element)} />
        </Tooltip> */}
      </div>,
      key: 'action'
    }
  ];

  return (

    /*<Sidebar
          navTitle={""}
          navMenuItems={navMenuItems}
          menuItems={menuItems}
          accountMenuItems={accountMenuItems}
        >*/
    <div className="mainContainer">
      <div className="headerContainer row">
        <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
          Add User
        </h4>
        <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px"
            }}
            onInput={(event) => searchOnChange(event)}
          >
          </Input><SearchOutlined className='searchSymbol' />&nbsp;&nbsp;
          <Button size="large" className="button" onClick={() => addUser()}>
            Add User
          </Button>
        </div>
      </div>
      <br></br>
      {
        role != 'agent' && role != "manager" &&
        <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex">
          <Select
            showSearch
            defaultValue="Select Manager"
            style={{ width: "280px" }}
            onSelect={
              handlemangerSelect
            }
          >
            <Select.Option value="All" key="All">
              All
            </Select.Option>
            {managerList?.length > 0 &&
              managerList?.map((ele) => {
                return <Select.Option value={ele.branch._id} key={ele.branch._id}>{ele.userName} ({ele.branch.branchName.toUpperCase()})</Select.Option>;
              })}
          </Select>
        </div>
      }
      <div className="tableContainer">
        <Table columns={columns} dataSource={data}
          // scroll={{
          //   x: "90vw",
          // }}
          pagination={{
            pageSize: configuration.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            pageSizeOptions: ['10', '30', '40'],
            total: configuration.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        />


        <Drawer
          title={`${selectedUser._id ? 'Edit' : 'Add'} User`}
          className="drawer-dropDown"
          onClose={() => setVisible(false)}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          zIndex={200}
        >
          {visible ? <AddUser formType={selectedUser.isView ? 'view' : selectedUser.email ? 'edit' : 'add'}
            backToDashboard={backToDashboard}
            selectedUser={selectedUser}
            getData={getData}
          /> : null}
        </Drawer>
        <ConfirmBox
          open={open}
          closeDialog={() => setOpen(false)}
          Function={deActive}
          tittle={deleteData.name}
          confirmButton={"Deactive"}
          heading={"Deactive"}
          Content={`Are you sure to Deactivate the ${deleteData.userName}`}
        ></ConfirmBox>
        <ConfirmBox
          open={openActive}
          closeDialog={() => setOpenActive(false)}
          Function={deActive}
          tittle={deleteData.name}
          confirmButton={"Active"}
          heading={"Active"}
          Content={`Are you sure to Deactivate the ${deleteData.userName}`}
        ></ConfirmBox>
      </div>
      <ToastContainer />
    </div>
    //</Sidebar>
  )
}

export default ManageUser;