import React, { useState, useEffect } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button, Select } from "antd";
import { FloatLabel } from "../../../../UIComponent/_index";
import { productPost } from "../../../../utils/servies/common";
import { productUpdate,companyCodeIdGet } from "../../../../utils/servies/common";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from "../../../../Resources/Loader/Loader";
const { Option } = Select;

const product = {
  productName: "",
  description: "",
};
const AddProduct = (props) => {
  const { formType, backToDashboard, selectedUserProduct, dataGet } = props;
  const status = formType === "edit" ? selectedUserProduct : product
  const [data, setData] = useState(status);
  const [company, setCompany] = useState();
  const [companyName, setCompanyName] = useState(status.companyId?.map(ele=>{return ele._id}));

  const [open,setOpen] = useState(false)
  const handleSave = (e) => {
    if (companyName && data.productName) {
      setOpen(true)
      let sendData = {
        companyId: companyName,
        productName: data.productName,
      }
      productPost(sendData)
        .then((res) => {
          if (!res.message) {
            toast.success("Successfully Saved")
            setOpen(false)
          }
          else {
            toast.error(res.message)
          }
          backToDashboard()
          dataGet()
        })
        .catch(() => {
          toast.error("Something Went Wrong")
          backToDashboard()
          setOpen(false)
        });
    }
    else {
      toast.warning("Please Enter All Details")
    }
  }
  const handleUpdate = () => {
    if (companyName && data.productName) {
      setOpen(true)
      let sendData = {
        companyId: companyName,
        productName: data.productName,
      }
      productUpdate(sendData, data._id).then((res) => {
        setData(res.data)
        toast("Updated Successfully")
        backToDashboard()
        dataGet()
        setOpen(false)
      })
        .catch((err) => {
          toast.error("Something Went Wrong")
          backToDashboard()
          setOpen(false)
        });
    }
    else {
      toast.warning("Please Enter All Details")
    }

  }
  const handleSubmit = (e) => {
    formType === "edit" ? handleUpdate() : handleSave()
  }
  const cancel = () => {
    backToDashboard();
  };
  const getCompanyName = () => {
    companyCodeIdGet()
      .then((res) => {
        setCompany(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCompanyName();
  }, []);
  return (
    <>
      <div className="formContainer h-100">
        <OverlayScrollbarsComponent className="formBody">
          <Formik innerRef={(ref) => { }}>
            {({ value }) => (
              <Form noValidate>
                <div className="innerContainer row">
                  <div className="dropDownContainer col-12 mb-2">
                    <FloatLabel label="Company Name" value={companyName}>
                      <Select
                        mode="multiple"
                        maxTagCount="responsive"
                        labelid="Company ID"
                        className="w-100"
                        value={companyName}
                        onChange={(e) => {
                          console.log(e)
                          setCompanyName(e);
                        }}
                      >
                        {company?.length > 0 &&
                          company.map((ele) => {
                            return (
                              <Option value={ele._id} key={ele._id}>
                                {ele.companyName}
                              </Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="Product Name" value={data?.productName}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        disabled={formType === "view" ? true : false}
                        value={data?.productName}
                        onChange={(e) => {
                          setData({ ...data, productName: e.target.value });
                        }}
                      />
                    </FloatLabel>
                  </div>
                  {/* <div className="col-12 mb-2">
                    <FloatLabel label="Descripition" value={data?.description}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        disabled={formType === "view" ? true : false}
                        value={data?.description}
                        onChange={(e) => {
                          setData({ ...data, description: e.target.value });
                        }}
                      />
                    </FloatLabel>
                  </div> */}
                </div>
              </Form>
            )}
          </Formik>
        </OverlayScrollbarsComponent>
        <footer>
          <Button className="button transButton" onClick={cancel}>
            Cancel
          </Button>
          <Button className="button" onClick={handleSubmit}>
            {formType === "edit" ? "Update" : "Save"}
          </Button>
        </footer>
        <ToastContainer />
      </div>
      <Loader open={open} />
    </>
  );
};

export default AddProduct;
