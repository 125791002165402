import React from "react";
import Dialog from "@mui/material/Dialog";

export function Loader({ open }) {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="loader">
        <div className="loader-text">Loading</div>
        <div>
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only"></span>
          </div>
          <div className="spinner-grow text-warning" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
