import React, { useState, useEffect } from "react";
import { Button, Select, Table, Input, Space, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { agentReport, userConfigBookingCode, ProductGet,ProductGetByCompanyId } from "../../utils/servies/common";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { policyCreateUserGet, policyCreateCompanyGet, getAllUsersDropDown, getAllManager, companyLoginCompanyCodeGet } from "../../utils/servies/common";
import moment from "moment";
import { CSVLink } from "react-csv";

const Reports = () => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });
  const [user, setUser] = useState();
  const [userName, setUsername] = useState();
  const [value, getvalue] = useState()
  const [data, setData] = useState()
  const [csvData, setCsvData] = useState()
  const [saveManager, setSaveManager] = useState()
  const role = localStorage.getItem("role")
  const [managerList, setManagerList] = useState([])
  const [selectedManager, setSelectedManager] = useState()
  const [getCompany, setGetCompany] = useState();
  const [dataCompany, setDataCompany] = useState();
  const [mapBookingCode, setMapBookingCode] = useState();
  const [saveBookingCode, setSaveBookingCode] = useState();
  const [dataProduct, setDataProduct] = useState()
  const [selectedProduct, setSelectedProduct] = useState()

  const GetUser = (data) => {
    var data = data
    // if (localStorage.getItem("role") == 'admin') {
    //   data={data,...{role:'agent'}}
    // }
    getAllUsersDropDown(data).then((res) => {
      setUser(res.data);
    }).catch(() => {
      toast.warning("Something Went Wrong")
    });
  };
  const getAllmangerData = () => {
    getAllManager().then(res => {
      setManagerList(res)
    })
  }

  const onchange = (value) => {
    getvalue(value)
  };
  const handlemangerSelect = (event, key) => {
    managerList.map(ele => {
      if (ele.branch.branchName == event) {
        setSelectedManager(ele._id)
      }
    })
    var senddata = {}
    setUsername('')
    if (localStorage.getItem('role') == 'admin') {
      senddata.role = 'agent'
    }
    if (key.key == "All") {
      GetUser(senddata)
    } else {
      senddata.branch = key.key
      GetUser(senddata)
    }
  }

  const handleSubmit = async () => {
    if (value && getCompany?.key && saveBookingCode?.key && selectedProduct.key) {
      let datta = {
        startDate: value[0],
        endDate: value[1],
        userId: userName,
        companyId: getCompany?.key,
        companyCodeId: saveBookingCode?.key,
        productId: selectedProduct.key,
        searchField: { userId: userName }
      }
      agentReport(datta).then(async (res) => {
        setData(res.data)
        toast.success("Data Retrieved")
        var pushdata = []
        await res.data.map(ele => {
          ele.policyNumber = `'` + ele.policyNumber
          ele.policyDate = moment(ele["policyDate"]).format("DD-MMM-YYYY")
          pushdata.push(ele)
        })
        setCsvData(pushdata)

      }).catch((error) => {
        toast.error("Something Went Wrong")
      })
    }
    else {
      toast("Please Select Date / Company ")
    }
  }

  const findManagerRole = user?.filter(obj => {
    return obj.role === "manager"
  })
  const getDataCompany = () => {
    policyCreateCompanyGet().then((res) => {
      setDataCompany(res.data);
    });
  };
  const getBookingCodeList = (e) => {
    if (e.key == "All") {
      companyLoginCompanyCodeGet()
        .then((res) => {
          setMapBookingCode(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      userConfigBookingCode(e.key)
        .then((res) => {
          setMapBookingCode(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    getAllmangerData()
    GetUser({});
    getDataCompany();
    if (getCompany) {
      getBookingCodeList(getCompany)
      getProductBycomp(getCompany)
    }
  }, []);

  const headers = role == 'agent' ? [

    { label: 'Company Name', key: "companyId.companyName" },
    { label: 'Company Code', key: "companyCodeId.companyCode" },
    { label: 'policy Date', key: "policyDate" },
    // { label: 'Approved Date', key: "approvedDate" },
    { label: 'Policy Number', key: "policyNumber" },
    { label: 'Customer Name', key: "customerName" },
    { label: 'Customer Email', key: "customerEmail" },
    { label: "Registration Year", key: "RegistrationYear" },
    { label: 'Registration Number', key: "RegistrationNumber" },

    // { label: 'product ', key: "product.productName" },
    { label: 'Od Premium', key: "odPremium" },
    { label: 'TP Premium', key: "tpPremium" },
    { label: 'Net Premium', key: "netPremium" },
    { label: 'Total Premium', key: "totalPremium" },
    { label: 'policy Status', key: "status" },
    // { label: 'Agent Note', key: "agentNote" },
    // { label: 'Payment Mode', key: "paymentMode" },
    // { label: "Agent Share", key: "agentShare" },
    // { label: 'Paid Amount', key: "paidAmount" },

    // { label: 'makeModel', key: "makeModel" },
    // { label: 'GVW', key: "gvw" },
    // { label: 'cc', key: "cc" },
    // { label: 'seating Capacity', key: "seatingCapacity" },
    // { label: 'odPolicyStartDate', key: "odPolicyStartDate" },
    // { label: 'odPeriod', key: "odPeriod" },
    // { label: 'tpPolicyStartDate', key: "tpPolicyStartDate" },
    // { label: 'tpPeriod', key: "tpPeriod" },
    // { label: 'odPolicyEndDate', key: "odPolicyEndDate" },
    // { label: 'tpPolicyEndDate', key: "tpPolicyEndDate" },
    // { label: 'engineNumber', key: "engineNumber" },
    // { label: 'fuelType', key: "fuelType" },
    // { label: 'chassisNumber', key: "chassisNumber" },
    // { label: 'vehicleSubclass', key: "vehicleSubclass" },
  ] : [
    { label: 'policy Date', key: "policyDate" },
    { label: 'Approved Date', key: "approvedDate" },
    { label: 'Policy Number', key: "policyNumber" },
    { label: 'Company Name', key: "companyId.companyName" },
    { label: 'Company Code', key: "companyCodeId.companyCode" },
    { label: 'policyType', key: "policyType.name" },
    { label: 'product ', key: "product.productName" },
      { label: 'policy Status', key: "status" },
    
    { label: 'Customer Name', key: "customerName" },
    { label: 'Customer Email', key: "customerEmail" },
    { label: 'Customer Mobile', key: "customerMobile" },
    
    { label: "Registration Year", key: "RegistrationYear" },
    { label: 'Registration Number', key: "RegistrationNumber" },
      { label: 'Rto Location', key: "rtoLocation.rtoName" },
      { label: 'Year', key: "year.year" },
    { label: 'makeModel', key: "makeModel" },
    { label: 'GVW', key: "gvw" },
    { label: 'cc', key: "cc" },
    { label: 'seating Capacity', key: "seatingCapacity" },
    { label: 'cpa Cover', key: "cpaCover" },
    { label: 'fuelType', key: "fuelType" },
    
    { label: 'Od Premium', key: "odPremium" },
    { label: 'TP Premium', key: "tpPremium" },
    { label: 'Net Premium', key: "netPremium" },
    { label: 'Total Premium', key: "totalPremium" },
    { label: 'OD Discount', key: "odDiscount" },
    
    { label: 'Payment Mode', key: "paymentMode" },
    { label: 'Cash Mode Amount', key: "CashModeAmount" },
    
    { label: "Agent Name", key: "userId.name" },
    { label: "Agent Share", key: "agentShare" },
    { label: 'Paid Amount', key: "paidAmount" },
    { label: "Manager Share", key: "managerShare" },
    
    { label: 'OD Policy StartDate', key: "odPolicyStartDate" },
    { label: 'OD Policy EndDate', key: "odPolicyEndDate" },
    { label: 'odPeriod', key: "odPeriod" },
    { label: 'tpPolicyStartDate', key: "tpPolicyStartDate" },
    { label: 'tpPolicyEndDate', key: "tpPolicyEndDate" },
    { label: 'tpPeriod', key: "tpPeriod" }
    // { label: 'engineNumber', key: "engineNumber" },

    // { label: 'chassisNumber', key: "chassisNumber" },
    // { label: 'vehicleSubclass', key: "vehicleSubclass" },
    // { label: 'Agent Note', key: "agentNote" },
  ]
  const getdataproduct = async () => {
    await ProductGet()
      .then((res) => {
        setDataProduct(res.data);
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
  };

  const getCompanyName = (event, key) => {
    setSaveBookingCode()
    getBookingCodeList(key)
    setGetCompany(key);
    setSelectedProduct()
    getProductBycomp(key)
  };

  const getProductBycomp = (id) => {
    if (id.key !== "All") {
      ProductGetByCompanyId(id.key)
      .then((res) => {
        setDataProduct(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
      getdataproduct()
    }
    
  };

  const getproductName = (event, key) => {
    setSelectedProduct(key)
  };

  const getBookingName = (event, key) => {
    setSaveBookingCode(key);
  };
  const csvFile = {
    filename: "Reports.csv",
    headers: headers,
    data: (csvData) ? csvData : ""
  }


  const columns = role == 'agent' ?
    [
      {
        title: "Company Name",
        dataIndex: "companyId",
        key: "companyId",
        render: (companyId) => {
          return (companyId?.companyName) ? (companyId?.companyName) : ("") ? "" : (!companyId) ? "" : companyId[0]?.companyName;
        },
        // ellipsis: true,
        // sorter: (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0),
        // sortDirections: ["ascend", "descend", ""],
        // defaultSortOrder: "",
      },
      {
        title: "Company Code",
        dataIndex: "companyCodeId",
        key: "companyCodeId",
        render: (companyCodeId) => {
          return (companyCodeId?.companyCode) ? (companyCodeId?.companyCode) : ("") ? "" : (!companyCodeId) ? "" : companyCodeId[0]?.companyCode;
        }
      },
      {
        title: "Product",
        dataIndex: "product",
        key: "product",
        render: (product) => {
          return (product?.productName) ? (product?.productName) : ("") ? "" : (!product) ? "" : product[0]?.productName;
        }
      },
      {
        title: "Policy Date",
        dataIndex: "policyDate",
        key: "policyDate",
        render: (_, element) => {
          return moment(element["policyDate"]).format("DD-MMM-YYYY");
        },
      },
      {
        title: "Policy Number",
        dataIndex: "policyNumber",
        key: "policyNumber",
        render: (_, element) => {
          return (element['policyNumber'].includes("'")) ? (element['policyNumber'].split("'")[1]) : (element['policyNumber'])
        }
      },
      {
        title: "Customer Name",
        dataIndex: "customerName",
        key: "customerName",
      },
      {
        title: "Registeration Number",
        dataIndex: "RegistrationNumber",
        key: "RegistrationNumber",
      },
      {
        title: "Customer Email",
        dataIndex: "customerEmail",
        key: "customerEmail",
      },
      {
        title: "Customer Mobile",
        dataIndex: "customerMobile",
        key: "customerMobile",
      },
      {
        title: "OD Premium",
        dataIndex: "odPremium",
        key: "odPremium",
      },
      {
        title: "TP Premium",
        dataIndex: "tpPremium",
        key: "tpPremium",
      },
      {
        title: "Net Premium",
        dataIndex: "netPremium",
        key: "netPremium",
      },
      {
        title: "Total Premium",
        dataIndex: "totalPremium",
        key: "totalPremium",
      },

      {
        title: "Policy Status",
        dataIndex: "status",
        key: "status",
      },
      // {
      //   title: "Agent Note",
      //   dataIndex: "agentNote",
      //   key: "agentNote"
      // },
      // {
      //   title: "Agent Share",
      //   dataIndex: "agentShare",
      //   key: "agentShare",
      // },
    ]
    : role == "admin" ?
      [
        {
          title: "Company Name",
          dataIndex: "companyId",
          key: "companyId",
          render: (companyId) => {
            return (companyId?.companyName) ? (companyId?.companyName) : ("") ? "" : (!companyId) ? "" : companyId[0]?.companyName;
          },
          // ellipsis: true,
          // sorter: (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0),
          // sortDirections: ["ascend", "descend", ""],
          // defaultSortOrder: "",
        },
        {
          title: "Company Code",
          dataIndex: "companyCodeId",
          key: "companyCodeId",
          render: (companyCodeId) => {
            return (companyCodeId?.companyCode) ? (companyCodeId?.companyCode) : ("") ? "" : (!companyCodeId) ? "" : companyCodeId[0]?.companyCode;
          }
        },
        {
          title: "Product",
          dataIndex: "product",
          key: "product",
          render: (product) => {
            return (product?.productName) ? (product?.productName) : ("") ? "" : (!product) ? "" : product[0]?.productName;
          }
        },
        {
          title: "Policy Date",
          dataIndex: "policyDate",
          key: "policyDate",
          render: (_, element) => {
            return moment(element["policyDate"]).format("DD-MMM-YYYY");
          },
        },
        {
          title: "Policy Number",
          dataIndex: "policyNumber",
          key: "policyNumber"
        },
        {
          title: "Customer Name",
          dataIndex: "customerName",
          key: "customerName",
        },
        {
          title: "Registeration Number",
          dataIndex: "RegistrationNumber",
          key: "RegistrationNumber",
        },
        {
          title: "Customer Email",
          dataIndex: "customerEmail",
          key: "customerEmail",
        },
        {
          title: "Customer Mobile",
          dataIndex: "customerMobile",
          key: "customerMobile",
        },
        {
          title: "OD Premium",
          dataIndex: "odPremium",
          key: "odPremium",
        },
        {
          title: "TP Premium",
          dataIndex: "tpPremium",
          key: "tpPremium",
        },
        {
          title: "Net Premium",
          dataIndex: "netPremium",
          key: "netPremium",
        },
        {
          title: "Total Premium",
          dataIndex: "totalPremium",
          key: "totalPremium",
        },

        {
          title: "Policy Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Agent Name",
          dataIndex: "userId",
          key: "userId",
          render: (userId) => {
            return (userId?.name) ? (userId?.name) : ("") ? "" : (!userId) ? "" : userId[0]?.name;
          }
        },
        {
          title: "Agent Share",
          dataIndex: "agentShare",
          key: "agentShare",
          render: (agentShare) => {
            return agentShare.toFixed(2)
          }
        },
        {
          title: "Manager Share",
          dataIndex: "managerShare",
          key: "managerShare",
          render: (managerShare) => {
            return managerShare.toFixed(2)
          }
        },
        {
          title: "Admin Share",
          dataIndex: "adminShare",
          key: "adminShare",
          render: (adminShare) => {
            return adminShare.toFixed(2)
          }
        },
        // {
        //   title: "Agent Note",
        //   dataIndex: "agentNote",
        //   key: "agentNote"
        // },
      ]
      :
      [
        {
          title: "Company Name",
          dataIndex: "companyId",
          key: "companyId",
          render: (companyId) => {
            return (companyId?.companyName) ? (companyId?.companyName) : ("") ? "" : (!companyId) ? "" : companyId[0]?.companyName;
          },
          // ellipsis: true,
          // sorter: (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0),
          // sortDirections: ["ascend", "descend", ""],
          // defaultSortOrder: "",
        },
        {
          title: "Company Code",
          dataIndex: "companyCodeId",
          key: "companyCodeId",
          render: (companyCodeId) => {
            return (companyCodeId?.companyCode) ? (companyCodeId?.companyCode) : ("") ? "" : (!companyCodeId) ? "" : companyCodeId[0]?.companyCode;
          }
        },
        {
          title: "Product",
          dataIndex: "product",
          key: "product",
          render: (product) => {
            return (product?.productName) ? (product?.productName) : ("") ? "" : (!product) ? "" : product[0]?.productName;
          }
        },
        {
          title: "Policy Date",
          dataIndex: "policyDate",
          key: "policyDate",
          render: (_, element) => {
            return moment(element["policyDate"]).format("DD-MMM-YYYY");
          },
        },
        {
          title: "Policy Number",
          dataIndex: "policyNumber",
          key: "policyNumber",
          render: (_, element) => {
            return (element['policyNumber'].includes("'")) ? (element['policyNumber'].split("'")[1]) : (element['policyNumber'])
          }
        },
        {
          title: "Customer Name",
          dataIndex: "customerName",
          key: "customerName",
        },
        {
          title: "Registeration Number",
          dataIndex: "RegistrationNumber",
          key: "RegistrationNumber",
        },
        {
          title: "Customer Email",
          dataIndex: "customerEmail",
          key: "customerEmail",
        },
        {
          title: "Customer Mobile",
          dataIndex: "customerMobile",
          key: "customerMobile",
        },
        {
          title: "OD Premium",
          dataIndex: "odPremium",
          key: "odPremium",
        },
        {
          title: "TP Premium",
          dataIndex: "tpPremium",
          key: "tpPremium",
        },
        {
          title: "Net Premium",
          dataIndex: "netPremium",
          key: "netPremium",
        },
        {
          title: "Total Premium",
          dataIndex: "totalPremium",
          key: "totalPremium",
        },

        {
          title: "Policy Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Agent Name",
          dataIndex: "userId",
          key: "userId",
          render: (userId) => {
            return (userId?.name) ? (userId?.name) : ("") ? "" : (!userId) ? "" : userId[0]?.name;
          }
        },
        // {
        //   title: "Agent Note",
        //   dataIndex: "agentNote",
        //   key: "agentNote"
        // }
        // {
        //   title: "Agent Share",
        //   dataIndex: "agentShare",
        //   key: "agentShare",
        // },
        // {
        //   title: "Manager Share",
        //   dataIndex: "managerShare",
        //   key: "managerShare",
        // }
      ]
  return (
    <>
      <div className="mainContainer">
        <div className="headerContainer row">
          <h4 className="col-lg-7 col-md-7 col-xl-7 col-sm-12 col-xs-12 headerTitle">
            Report
          </h4>
          {/* <div><h4>  </h4></div> */}
          { role != 'agent' && 
            <div className="row mt-2">
            {
              role != 'agent' && role != "manager" &&
              <div className="col-lg-3 col-md-3 col-xl-3 col-sm-4 col-xs-4">
                <Select
                  showSearch
                  defaultValue="Select Manager"
                  className="w-100"
                  onSelect={

                    handlemangerSelect
                  }
                >
                  <Select.Option>
                    All
                  </Select.Option>
                  {managerList?.length > 0 &&
                    managerList?.map((ele) => {
                      return <Select.Option value={ele.branch?.branchName} key={ele.branch?._id}>{ele.userName} ({ele.branch?.branchName.toUpperCase()})</Select.Option>;
                    })}
                </Select>
              </div>
            }
            {/* &nbsp;&nbsp; */}
            {role != 'agent' &&
              <div className="col-lg-3 col-md-3 col-xl-3 col-sm-4 col-xs-4">
                <Select
                  showSearch
                  defaultValue="Select User"
                  className="w-100"
                  value={userName}
                  onSelect={(e) => setUsername(e)}
                >
                  <Option value={selectedManager} key="All">
                    All
                  </Option>
                  {user?.length > 0 &&
                    user?.map((ele) => {
                      return <Option value={ele._id} key={ele._id} >{ele.userName} </Option>;
                    })}
                </Select>
              </div>
            }
            {/* &nbsp;&nbsp; */}
          </div>
          }
          

          <div className="row mt-3">
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-4 col-xs-4">
              <Select
                showSearch
                className="w-100"
                value={getCompany}
                onSelect={getCompanyName}
                placeholder="Company Name"
              >
                <Select.Option value="All" key="All">
                  All
                </Select.Option>
                {dataCompany?.length > 0 &&
                  dataCompany.map((ele) => {
                    return (
                      <Select.Option value={ele.companyName} key={ele._id}>
                        {ele.companyName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </div>
            {/* &nbsp;&nbsp; */}
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-4 col-xs-4">
              <Select
                showSearch
                className="w-100"
                value={saveBookingCode}
                onSelect={getBookingName}
                placeholder="Company Code"
              >
                <Select.Option value="All" key="All">
                  All
                </Select.Option>
                {mapBookingCode?.length > 0 && getCompany.key === "All" &&
                  mapBookingCode.map((ele) => {
                    return (
                      <Select.Option value={ele._id} key={ele._id}>
                        {ele.companyCode} ({ele.companyId.companyName})
                      </Select.Option>
                    );
                  })}
                {mapBookingCode?.length > 0 && getCompany.key !== "All" &&
                  mapBookingCode.map((ele) => {
                    return (
                      <Select.Option value={ele.companyCode} key={ele._id}>
                        {ele.companyCode}
                      </Select.Option>
                    );
                  })}
              </Select>
            </div>
            {/* &nbsp;&nbsp; */}
          </div>
          <div className="row mt-3">
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-4 col-xs-4">
              <Select
                showSearch
                className="w-100"
                value={selectedProduct}
                onSelect={getproductName}
                placeholder="Product"
              >
                <Select.Option value="All" key="All">
                  All
                </Select.Option>
                {dataProduct?.length > 0 &&
                  dataProduct.map((ele) => {
                    return (
                      <Select.Option value={ele.productName} key={ele._id}>
                        {ele.productName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </div>
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-3 col-xs-3">
              <Space direction="vertical">
                <RangePicker format="DD-MM-YYYY" style={{ height: "38px" }} onChange={onchange} />
              </Space>
            </div>
            <div className="col-lg-3 col-md-3 col-xl-2 col-sm-2 col-xs-2">
              <Space>
                <Button size="large" className="button" style={{ marginLeft: "4px" }} onClick={handleSubmit}>Submit</Button>
              </Space>
            </div>
            {/* &nbsp;&nbsp; */}
            <div className="col-lg-3 col-md-3 col-xl-2 col-sm-2 col-xs-2">
              {csvData &&
                <Button className="csvButton">
                  <CSVLink className="csv" {...csvFile}>Export All File</CSVLink>
                </Button>
              }
            </div>
          </div>

        </div>
        <div className="tableContainer">
          <Table
            columns={columns}
            dataSource={data}
            // scroll={{
            //   x: "5vw",
            // }}
            pagination={{
              pageSize: configuration.pagination.pageSize || 10,
              showQuickJumper: true,
              responsive: true,
              total: configuration.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}
          />
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Reports;
