import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {
  accountMenuItems,
  menuItems,
  navMenuItems,
} from "../../Constants/LayoutCommon";
import AddPolicyView from "./AddPolicyView";
import { Drawer, Table, Tooltip, Input, Button, Select } from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import { reportPost, specialSearch, policyCreateCompanyGet } from "../../utils/servies/common";
import { Space, DatePicker } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { CSVLink } from "react-csv";
import DownloadIcon from '@mui/icons-material/Download';
import {baseUrl} from '../../Constants/constants'

const PolicyView = () => {
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });
  const [data, setData] = useState()
  const [selectedPolicy, setSelectedPolicy] = useState({});
  const [selectedUserPolicy, setSelectedUserPolicy] = useState({});
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState();
  const [filterPolicyView, setFilterPolicyView] = useState([]);
  const [getButton, setButton] = useState();
  const [value, getvalue] = useState();
  const [special, setspecial] = useState();
  const [dataCompany, setDataCompany] = useState();
  const [getCompany, setGetCompany] = useState();
  const [rejButton, setRejButton] = useState(false);
  const [aproveButton, setAproveButton] = useState(false);
  const role = localStorage.getItem("role")

  const getDataCompany = () => {
    policyCreateCompanyGet().then((res) => {
      setDataCompany(res.data);
    });
  };
  const getCompanyName = (event, key) => {
    setGetCompany(key);
  };

  const { RangePicker } = DatePicker;

  const onChange = (value) => {
    getvalue(value)
  };
  const DownloadFunction = (ele) => {
    axios
      .get(
        `${baseUrl}/api/getpolicyFile?filename=${ele?.policyFile}`,
        {
          headers: {
            "x-access-token ": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        window.open(res.data?.data?.URL);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log("error", err)
      })
  };
  const fullValue = (e) => {
    if (!value) {
      toast.warning("Please Select Date")
    }
    else {
      let sendData = {
        searchField: {},
        startDate: value[0],
        endDate: value[1],
        status: e
      }
      if (getCompany) {
        if (getCompany.key != "1001") {
          sendData.companyId = getCompany.key
        }
      }
      if (data?.RegistrationNumber) {
        sendData.RegistrationNumber = data.RegistrationNumber
      }
      if (data?.policyNumber) {
        sendData.policyNumber = data.policyNumber
      }
      reportPost(sendData).then((res) => {
        setUser(res.data);
        setFilterPolicyView(res.data)
        if (res.data.length) {
          toast("Successfully Retrieved");
        }
        else {
          toast("No Data Retrieved")
        }
      })
    }
  }
  const backToDashBoard = () => {
    setVisible(false);
    setSelectedUserPolicy({});
  };

  const searchSpecial = () => {
    let data = {
      searchField: special,
    }
    if (special) {
      specialSearch(data).then((res) => {
        setUser(res)
      }).catch((err) => {
        console.log(err)
      })
    }
    else {
      toast.warning("please provide search value")
    }
  }

  const viewCustomerPolicy = (element) => {
    setVisible(true);
    const el = { ...element };
    el.isViewAttr = true;
    setSelectedPolicy(el);
  };

  useEffect(() => {
    getDataCompany()
  }, []);

  const onSearch = (searchText) => {
    filterPolicyView.length
      ? setUser(
        filterPolicyView.filter(
          (el) =>
            `${el.customerName},${el.customerEmail},${el.policyNumber},${el?.companyId?.companyName},${el.policyDate}`
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
        )
      )
      : setUser(filterPolicyView);
  };

  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setspecial(event.target.value)
    setConfiguration((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };
  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      render: (companyId) => {
        return (companyId?.companyName) ? (companyId?.companyName) : ("") ? "" : (!companyId) ? "" : companyId[0]?.companyName;
      },
      // ellipsis: true,
      // sorter: (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0),
      // sortDirections: ["ascend", "descend", ""],
      // defaultSortOrder: "",
    },
    {
      title: "Policy Date",
      dataIndex: "policyDate",
      key: "policyDate",
      render: (_, element) => {
        return moment(element["policyDate"]).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Policy Number",
      dataIndex: "policyNumber",
      key: "policyNumber",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Registeration Number",
      dataIndex: "RegistrationNumber",
      key: "RegistrationNumber",
    },
    {
      title: "Customer Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
    },
    {
      title: "Customer Mobile",
      dataIndex: "customerMobile",
      key: "customerMobile",
    },
    {
      title: "OD Premium",
      dataIndex: "odPremium",
      key: "odPremium",
    },
    {
      title: "TP Premium",
      dataIndex: "tpPremium",
      key: "tpPremium",
    },
    {
      title: "Net Premium",
      dataIndex: "netPremium",
      key: "netPremium",
    },
    {
      title: "Total Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
    },

    {
      title: "Policy Status",
      dataIndex: "status",
      key: "status",
    },
    // {
    //   title: "Agent Mail",
    //   dataIndex: "userId",
    //   key: "userId",
    //   render: (userId) => {
    //     return (userId?.name) ? (userId?.name) : ("") ? "" : (!userId) ? "" : userId[0]?.name;
    //   }
    // },
    // {
    //   title: "Agent Share",
    //   dataIndex: "agentShare",
    //   key: "agentShare",
    // },
    {
      title: "Remarks",
      dataIndex: "RejectedRemarks",
      key: "RejectedRemarks",
      show:false,
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: (_, element, index) => (
    //     <div className="actionContainer">
    //       <Tooltip placement="bottom" title="Edit">
    //         <EyeOutlined onClick={() => viewCustomerPolicy(element)} />
    //       </Tooltip>
    //       <Tooltip placement="bottom" title="Download">
    //         <DownloadIcon onClick={() => DownloadFunction(element)} />
    //       </Tooltip>
    //     </div>
    //   ),
    //   key: "action",
    // },
  ];
  const columnwithOut=[
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      render: (companyId) => {
        return (companyId?.companyName) ? (companyId?.companyName) : ("") ? "" : (!companyId) ? "" : companyId[0]?.companyName;
      },
      // ellipsis: true,
      // sorter: (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0),
      // sortDirections: ["ascend", "descend", ""],
      // defaultSortOrder: "",
    },
    {
      title: "Policy Date",
      dataIndex: "policyDate",
      key: "policyDate",
      render: (_, element) => {
        return moment(element["policyDate"]).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Policy Number",
      dataIndex: "policyNumber",
      key: "policyNumber",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Registeration Number",
      dataIndex: "RegistrationNumber",
      key: "RegistrationNumber",
    },
    {
      title: "Customer Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
    },
    {
      title: "Customer Mobile",
      dataIndex: "customerMobile",
      key: "customerMobile",
    },
    {
      title: "OD Premium",
      dataIndex: "odPremium",
      key: "odPremium",
    },
    {
      title: "TP Premium",
      dataIndex: "tpPremium",
      key: "tpPremium",
    },
    {
      title: "Net Premium",
      dataIndex: "netPremium",
      key: "netPremium",
    },
    {
      title: "Total Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
    },

    {
      title: "Policy Status",
      dataIndex: "status",
      key: "status",
    },
    // {
    //   title: "Agent Mail",
    //   dataIndex: "userId",
    //   key: "userId",
    //   render: (userId) => {
    //     return (userId?.name) ? (userId?.name) : ("") ? "" : (!userId) ? "" : userId[0]?.name;
    //   }
    // },
    
  ];
  const headers = role =='agent' ? [
    
    { label: 'Company Name', key: "companyId.companyName" },
    { label: 'policy Date', key: "policyDate" },
    // { label: 'Approved Date', key: "approvedDate" },
    { label: 'Policy Number', key: "policyNumber" },
    { label: 'Customer Name', key: "customerName" },
    { label: 'Customer Email', key: "customerEmail" },
    { label: 'Registration Number', key: "RegistrationNumber" },
    // { label: 'product ', key: "product.productName" },
    { label: 'Od Premium', key: "odPremium" },
    { label: 'TP Premium', key: "tpPremium" },
    { label: 'Net Premium', key: "netPremium" },
    { label: 'Total Premium', key: "totalPremium" },
    { label: 'policy Status', key: "status" },
    // { label: 'Agent Note', key: "agentNote" },
    // { label: 'Payment Mode', key: "paymentMode" },
    // { label: "Agent Share", key: "agentShare" },
    // { label: 'Paid Amount', key: "paidAmount" },
    
    // { label: 'makeModel', key: "makeModel" },
    // { label: 'GVW', key: "gvw" },
    // { label: 'cc', key: "cc" },
    // { label: 'seating Capacity', key: "seatingCapacity" },
    // { label: 'odPolicyStartDate', key: "odPolicyStartDate" },
    // { label: 'odPeriod', key: "odPeriod" },
    // { label: 'tpPolicyStartDate', key: "tpPolicyStartDate" },
    // { label: 'tpPeriod', key: "tpPeriod" },
    // { label: 'odPolicyEndDate', key: "odPolicyEndDate" },
    // { label: 'tpPolicyEndDate', key: "tpPolicyEndDate" },
    // { label: 'engineNumber', key: "engineNumber" },
    // { label: 'fuelType', key: "fuelType" },
    // { label: 'chassisNumber', key: "chassisNumber" },
    // { label: 'vehicleSubclass', key: "vehicleSubclass" },
  ] : [
    { label: 'policy Date', key: "policyDate" },
    { label: 'Approved Date', key: "approvedDate" },
    { label: 'Policy Number', key: "policyNumber" },
    { label: 'Company Name', key: "companyId.companyName" },
    { label: 'policyType', key: "policyType.name" },
    { label: 'product ', key: "product.productName" },
    { label: 'Od Premium', key: "odPremium" },
    { label: 'TP Premium', key: "tpPremium" },
    { label: 'Net Premium', key: "netPremium" },
    { label: 'Total Premium', key: "totalPremium" },
    { label: 'policy Status', key: "status" },
    { label: 'Payment Mode', key: "paymentMode" },
    { label: "Agent Share", key: "agentShare" },
    { label: 'Paid Amount', key: "paidAmount" },
    { label: "Manager Share", key: "managerShare" },
    { label: 'Customer Name', key: "customerName" },
    { label: 'Customer Email', key: "customerEmail" },
    { label: 'Customer Mobile', key: "customerMobile" },
    { label: 'Registration Number', key: "RegistrationNumber" },
    { label: 'makeModel', key: "makeModel" },
    { label: 'GVW', key: "gvw" },
    { label: 'cc', key: "cc" },
    { label: 'seating Capacity', key: "seatingCapacity" },
    // { label: 'odPolicyStartDate', key: "odPolicyStartDate" },
    // { label: 'odPeriod', key: "odPeriod" },
    // { label: 'tpPolicyStartDate', key: "tpPolicyStartDate" },
    // { label: 'tpPeriod', key: "tpPeriod" },
    // { label: 'odPolicyEndDate', key: "odPolicyEndDate" },
    // { label: 'tpPolicyEndDate', key: "tpPolicyEndDate" },
    // { label: 'engineNumber', key: "engineNumber" },
    { label: 'fuelType', key: "fuelType" },
    // { label: 'chassisNumber', key: "chassisNumber" },
    // { label: 'vehicleSubclass', key: "vehicleSubclass" },
    // { label: 'Agent Note', key: "agentNote" },
    ]

  const csvFile = {
    filename: "PolicyList.csv",
    headers: headers,
    data: user ? user : "",
  };
  return (
    /*<Sidebar
      navTitle={""}
      navMenuItems={navMenuItems}
      menuItems={menuItems}
      accountMenuItems={accountMenuItems}
    >*/
    <>
      <div className="mainContainer">
        <div className="headerContainer row">
          <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
            Policy List
          </h4>
          <span></span>
          <div className="row mt-3">
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-8 col-xs-8 d-flex">
              <Space
                  direction="vertical"
                  style={{ marginLeft: "2px" }}
                  size={12}
                >
                <RangePicker format="DD-MM-YYYY" style={{ height: "40px" }} onChange={onChange} />
              </Space>
            </div>
              <div
              className="col-lg-3 col-md-3 col-xl-3 col-sm-8 col-xs-8 d-flex"
            >
              <Select
                showSearch
                className="w-100"
                value={getCompany}
                onSelect={getCompanyName}
                placeholder="Company Name"
              >
                <Select.Option value="all" key="1001">
                  All
                </Select.Option>
                {dataCompany?.length > 0 &&
                  dataCompany.map((ele) => {
                    return (
                      <Select.Option value={ele.companyName} key={ele._id}>
                        {ele.companyName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </div>
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-8 col-xs-8 d-flex">
              <Input
                style={{ height: 40 }}
                placeholder="Policy Number"
                className="w-100"
                value={data?.policyNumber}
                onChange={(e) => setData({ ...data, policyNumber: e.target.value })}
              />
            </div>
              <div className="col-lg-3 col-md-3 col-xl-3 col-sm-8 col-xs-8 d-flex">
                <Input
                  style={{ height: 40 }}
                  placeholder="Registration Number"
                  className="w-100"
                  value={data?.RegistrationNumber}
                  onChange={(e) => setData({ ...data, RegistrationNumber: e.target.value })}
                />
              </div>
          

          </div>
          {/* <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
            <Input
              placeholder="Policy/Registration Number"
              style={{
                height: "40px",
                width: "280px",
              }}
              onInput={(event) => searchOnChange(event)}
            ></Input>
            <Button style={{ height: "40px", borderRadius: "0px" }} onClick={searchSpecial}>Search</Button>
          </div> */}
        </div>
        <br></br>
        <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12 col-xs-12 d-flex justify-content-around">
          <Button
            className="reportAllbutton"
            onClick={() => {
              setRejButton(false)
              setAproveButton(false)
              fullValue("all");
            }}
          >
            All
          </Button>
          <Button
            className="reportAllbutton"
            onClick={() => {
              setRejButton(false)
              setAproveButton(false)
              fullValue("entry pending");
              
            }}
          >
            Entry Pending
          </Button>
          <Button
            className="reportAllbutton"
            onClick={() => {
              setRejButton(false)
              setAproveButton(false)
              fullValue("pending");
            }}
          >
            Pending
          </Button>
          
          <Button
            className="reportAllbutton"
            onClick={() => {
              setRejButton(false)
              setAproveButton(true)
              fullValue("approved");
            }}
          >
            Approved
          </Button>
          <Button
            className="reportAllbutton"
            onClick={() => {
              setRejButton(true)
              setAproveButton(false)
              fullValue("rejected");
            }}
          >
            Rejected
          </Button>
          {aproveButton && 
            <Button className="csvButton" style={{ width: "300px" }}>
              <CSVLink className="csv" {...csvFile}>
                Export All File
              </CSVLink>
            </Button>
          }
          
        </div>
        <br></br>
        <div className="tableContainer">
          <Table
            columns={!rejButton ? columnwithOut :columns}
            dataSource={user}
            scroll={{
              x: "5vw",
            }}
            pagination={{
              pageSize: configuration.pagination.pageSize || 10,
              showQuickJumper: true,
              responsive: true,
              total: configuration.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}
          />

          <Drawer
            title={`${selectedUserPolicy._id ? "Edit" : "Add"} UserPolicy`}
            className="UserPolicyModel"
            onClose={() => setVisible(false)}
            visible={visible}
            bodyStyle={{ padding: 0 }}
          >
            {visible ? (
              <AddPolicyView
                backToDashBoard={backToDashBoard}
                selectedUserPolicy={selectedUserPolicy}
                selectedPolicy={selectedPolicy}
                formType={
                  selectedUserPolicy.isViewAttr
                    ? "view"
                    : selectedUserPolicy._id
                      ? "edit"
                      : "add"
                }
              />
            ) : null}
          </Drawer>
        </div>
        <ToastContainer zIndex={100000}></ToastContainer>
      </div>
    </>
    //</Sidebar>
  );
};

export default PolicyView;
