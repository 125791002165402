import React, { useEffect, useState } from "react";
import { Button, Input, Table, Tooltip, Space, DatePicker, Drawer ,Select} from "antd";
import { renewalPolicyDateFilter } from "../../utils/servies/common";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { SearchOutlined } from "@ant-design/icons";
import { DataArray, FileDownloadRounded } from "@mui/icons-material";
import ConfirmBox from "../../UIComponent/ConfirmBox";
import axios from "axios";
import { CSVLink } from "react-csv";
import {baseUrl} from '../../Constants/constants'
import { policyCreateUserGet } from "../../utils/servies/common";
import { FloatLabel } from "../../UIComponent/_index";

const { Option } = Select;

const ManageRenewal = () => {
  const [report, setReport] = useState({
    pagination: {
      pageSize: 10,
    },
  });

  const { RangePicker } = DatePicker;
  const [date, setDate] = useState();
  const [data, setData] = useState();
  const [csvData,setCsvData] = useState()
  const [open, setOpen] = useState(false);
  const [resUpload, setUpload] = useState();
  const [download, setDownload] = useState();
  const [filterRenewal, setFilterRenewal] = useState([]);
  const role=localStorage.getItem('role')
  const [mapUser, setMapUser] = useState();
  const [saveUser, setSaveUser] = useState("All");

  const onChange = (value) => {
    setDate(value);
  };
  const onSearch = (searchText) => {
    filterRenewal.length
      ? setData(
        filterRenewal.filter(
          (el) =>
            `${el.RegistrationNumber},${el?.companyId?.companyName},${el.customerName},${el.policyNumber},${el.odPremium},${el.netPremium},${el.totalPremium},${el.customerEmail},${el.status},${el.policyDate}`
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1)
      )
      : setData(filterRenewal);
  };

  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setReport((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };

  const headers = role == 'agent' ? [
    { label: 'Company Name', key: "companyId.companyName" },
    { label: 'policy Date', key: "policyDate" },
    { label: 'Policy Number', key: "policyNumber" },
    { label: 'Customer Name', key: "customerName" },
    { label: 'Customer Email', key: "customerEmail" },
    { label: 'Registration Number', key: "RegistrationNumber" },
    { label: 'Od Premium', key: "odPremium" },
    { label: 'Net Premium', key: "netPremium" },
    { label: 'Total Premium', key: "totalPremium" },
    { label: 'policy Status', key: "status" }

  ] : [
    { label: 'policy Date', key: "policyDate" },
    { label: 'Approved Date', key: "approvedDate" },
    { label: 'Policy Number', key: "policyNumber" },
    { label: 'Company Name', key: "companyId.companyName" },
    { label: 'policyType', key: "policyType.name" },
    { label: 'product ', key: "product.productName" },
    { label: 'Od Premium', key: "odPremium" },
    { label: 'Net Premium', key: "netPremium" },
    { label: 'Total Premium', key: "totalPremium" },
    { label: 'policy Status', key: "status" },
    { label: 'Payment Mode', key: "paymentMode" },
    { label: "Agent Share", key: "agentShare" },
    { label: "Manager Share", key: "managerShare" },
    { label: 'Customer Name', key: "customerName" },
    { label: 'Customer Email', key: "customerEmail" },
    { label: 'Registration Number', key: "RegistrationNumber" },
    { label: 'makeModel', key: "makeModel" },
    { label: 'GVW', key: "gvw" },
    { label: 'cc', key: "cc" },
    { label: 'seating Capacity', key: "seatingCapacity" },
    { label: 'odPolicyStartDate', key: "odPolicyStartDate" },
    { label: 'odPeriod', key: "odPeriod" },
    { label: 'tpPolicyStartDate', key: "tpPolicyStartDate" },
    { label: 'tpPeriod', key: "tpPeriod" },
    { label: 'odPolicyEndDate', key: "odPolicyEndDate" },
    { label: 'tpPolicyEndDate', key: "tpPolicyEndDate" },
    { label: 'engineNumber', key: "engineNumber" },
    { label: 'fuelType', key: "fuelType" },
    { label: 'chassisNumber', key: "chassisNumber" },
    { label: 'vehicleSubclass', key: "vehicleSubclass" },
  ]
  const csvFile = {
    filename: "file.csv",
    headers: headers,
    data: (csvData) ? csvData : ""
  }
  const rangePicker = () => {
    if (date) {
      let sendData = {
        startDate: date[0],
        endDate: date[1],
      };
      if(saveUser !== "All"){
        sendData.userIds=saveUser
      }
      renewalPolicyDateFilter(sendData)
        .then(async(res) => {
          setData(res.data);
          setFilterRenewal(res.data)
          if (res.data.length) {
            toast("Successfully Retrieved");
            var pushdata=[]
            await res.data.map(ele => {
              ele.policyNumber = `'` + ele.policyNumber
              ele.policyDate=moment(ele["policyDate"]).format("DD-MMM-YYYY")
              pushdata.push(ele)
            })
            setCsvData(pushdata)
          }
          else {
            toast("No data Retrieved")
          }
          
        })
        .catch(() => {
          toast.error("Something went Wrong");
        });
    } else {
      toast.warning("Please Select Date");
    }
  };

  const fileUpload = (element) => {
    setOpen(true);
    setDownload(element);
  };

  const DownloadFunction = () => {
    axios
      .get(
        `${baseUrl}/api/getpolicyFile?filename=${download?.policyFile}`,
        {
          headers: {
            "x-access-token ": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        window.open(res.data?.data?.URL);
        toast(res.data?.message);
      }).catch(() => {
        toast.error("Something Went Wrong")
      })
    setOpen(false);
  };
  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      render: (companyId) => {
        return companyId?.companyName;
      },
    },
    {
      title: "Policy Date",
      dataIndex: "policyDate",
      key: "policyDate",
      render: (_, element) => {
        return moment(element["policyDate"]).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Policy Number",
      dataIndex: "policyNumber",
      key: "policyNumber",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Registration Number",
      dataIndex: "RegistrationNumber",
      key: "RegistrationNumber",
    },
    {
      title: "OD Premium",
      dataIndex: "odPremium",
      key: "odPremium",
    },
    {
      title: "Net Premium",
      dataIndex: "netPremium",
      key: "netPremium",
    },
    {
      title: "Total Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
    },
    {
      title: "User Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
    },
    {
      title: "Policy Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "File",
      dataIndex: "file",
      render: (_, element, index) => (
        <div>
          <Tooltip placement="bottom" title="Download">
            <FileDownloadRounded
              size="large"
              onClick={() => fileUpload(element)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const handleChangeSaveUser = (value) => {
    setSaveUser(value);
  };
  const getUserId = () => {
    policyCreateUserGet().then((res) => {
      setMapUser(res.data);
    });
  };
  useEffect(() => {
    getUserId()
  }, []);
  return (
    <div className="mainContainer">
      <h4>
        Renewal
      </h4>
      <div className="headerContainer row">
        <div className="col-lg-4 col-md-4">
          <Space direction="vertical" size={12} style={{ marginRight: "20px" }}>
            <RangePicker
              format="DD-MM-YYYY"
              onChange={onChange}
              style={{ padding: "8px" }}
            />
          </Space>
          
        </div>
        {
          role!=='agent' && 
          <div className="col-lg-3 col-md-3">
            <FloatLabel label="Users" value={saveUser}>
            <Select
                maxTagCount="responsive"
                className="w-100"
                onChange={handleChangeSaveUser}
                // disabled={formType === "view" ? true : false}
              >
                <Option value="All" key="All">All</Option>
                {mapUser?.length > 0 &&
                  mapUser.map((el) => {
                    return (
                      <Option value={el?._id} key={el?._id}>{el?.userName}</Option>
                    );
                  })}
              </Select>
            </FloatLabel>
          </div>
        }
        
        <div className="col-lg-3 col-md-3">
          <Button size="large" className="button" onClick={rangePicker}>
            Renewal
          </Button>
        </div>
        <br></br>
        <div className="col-lg-3 col-md-3">
          {/* <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px",
            }}
            onInput={(event) => searchOnChange(event)}
          ></Input> */}
          {/* <SearchOutlined className="searchSymbol" style={{ marginRight: "20px" }} /> */}
          { csvData &&
            <Button className="csvButton">
              <CSVLink className="csv" {...csvFile}>Export All File</CSVLink>
            </Button>
          }
        </div>
      </div>

      <br></br>
      <div className="tableContainer">
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: "95vw  " }}
          pagination={{
            pageSize: report.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            total: report.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        />
      </div>
      <ConfirmBox
        open={open}
        Function={DownloadFunction}
        heading={"Download"}
        closeDialog={() => setOpen(false)}
        Content={"You  Want to Download the File ?"}
        confirmButton={"Download"}
      />
      <ToastContainer />
    </div>
    //</Sidebar>
  );
};

export default ManageRenewal;
