import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CollapsibleMenu from "../CollapsibleMenu/CollapsibleMenu";
import { Avatar, Menu, MenuItem, Tooltip } from "@mui/material";
import CustomMenu from "../CustomMenu/CustomMenu";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Logo from "../../Resources/Images/Logo.png";
import { LogoutIcon } from "../../Resources/Icons/Icons";
import ConfirmBox from "../../UIComponent/ConfirmBox";
import {useEffect} from 'react'
import { tokenVerify } from '../../utils/servies/common'
// import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Sidebar.scss'
const drawerWidth = 268;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const settings = ["Profile", "Account", "Dashboard", "Logout"];

export default function Sidebar({
  children,
  navMenuItems = [],
  menuItems = [],
  accountMenuItems = [],
  navTitle = "N/A",
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [showBox, setShowBox] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userName, setUserName] = React.useState('')
  
  useEffect(() => {
    let user = localStorage.getItem("userName");
    if (user) {
      setUserName(user)
    } else {
      localStorage.clear()
    }
  }, [localStorage.getItem("userName")])
  
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const Logout = () => {
    setShowBox(true);
  };

  const LogoutFunction = () => {
    navigate("/login");
  };
  useEffect(() => {
    tokenVerify().then(res => {
      if (res.status === 401) {
        toast.warning('Session Expired')
        localStorage.clear()
        navigate('/login')
      }
    }).catch(e => {
      toast.error('Something Went Wrong')
      navigate('/login')
    })

  }, [localStorage.getItem("token")])

  return <>
    <section className="d-flex h-100">
      <div className={
        "sideBar " +
        (open ? "sideBarContainerOpened" : "sideBarContainerClosed")
      } >
        <Drawer variant="permanent" open={open} className="drawerSide">
          <DrawerHeader>
            <Box>
              <img src={Logo} style={{ height: 54, width: 160 }} alt="logo" />
            </Box>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menuItems.map((menu, index) => {
              return menu.children ? (
                <CollapsibleMenu
                  key={index}
                  defaultCollapsed={false}
                  menuItem={menu}
                  expanded={open}
                />
              ) : (
                <ListItem
                  key={menu.name}
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate(menu.path);
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={menu.name}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <ToastContainer></ToastContainer>
          <ListItem disablePadding sx={{ display: "block" }}
            onClick={Logout}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              &nbsp;<ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2.5 : "auto",
                  justifyContent: "center",
                }}

              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
        </Drawer>
      </div>
      <main className={
        "mainContainer " +
        (open ? "mainContainerOpened" : "mainContainerClosed")
      }>
        <AppBar position="fixed" sx={{ backgroundColor: "#29abe2" }} open={open}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 10,
                  ...(open && { display: "block" }),
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 0 }} mr={2}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={2}
              >
                <>
                  <span className="profile">{userName}</span>
                  <Tooltip title="Profile">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt={userName} src="/static/images/avatar/2.jpg">
                        <span className="profile">{userName[0]}</span>
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {accountMenuItems.map((accountMenu) => (
                      <MenuItem
                        key={accountMenu.name}
                        onClick={() => {
                          handleCloseUserMenu();
                          navigate(accountMenu.path);
                        }}
                      >
                        <Typography textAlign="center">
                          {accountMenu.name}
                        </Typography>
                      </MenuItem>
                    ))}
                    <Typography textAlign="center" onClick={Logout} sx={{ cursor: "pointer" }}>
                      &nbsp;Logout
                    </Typography>
                  </Menu>
                </>
              </Stack>
            </Box>
          </Toolbar>
        </AppBar>
        <div className="renderingContainer container-fluid" >
          {children}
        </div>
      </main>
    </section>
    <ConfirmBox
      open={showBox}
      heading={"Logout"}
      Function={LogoutFunction}
      closeDialog={() => setShowBox(false)}
      Content={"Are you Sure Want To Logout"}
      confirmButton={"Logout"}
    />
  </>
}
