import React, { useState, useEffect } from "react";
import "./Login.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../Resources/Images/Logo.png";
import Car from "../../Resources/Images/Car.png";
import "bootstrap/dist/css/bootstrap.min.css";
// const loginapi = require ("../../utils/helper.js");
import { loginapi } from "../../utils/servies/common";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const Data = {
    email: "",
    password: "",
  };
  const [data, setData] = useState(Data);
  const [showPass, setShowPass] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem("token")
    localStorage.removeItem("role")
    localStorage.removeItem("userName")
  }, []);
  const handleSubmit = (e) => {
    if (data.email && data.password) {
      loginapi(data)
        .then((res) => {
          console.log(res);
          if (res?.error) {
            toast(res.message);
          } else {
            const userName = res.data.name;
            localStorage.setItem("userName", userName)
            localStorage.setItem("token", res.data.token);

            if (res.data.isAdmin) {
              localStorage.setItem("role", res.data.role);
              navigate("/admin/newdashboard");
            } else if (res.data.role === "manager") {
              localStorage.setItem("role", res.data.role);
              navigate("/manager/newdashboard");
            } else {
              localStorage.setItem("role", res.data.role)
              navigate("/agent/dashboard");
            }
          }
        })
        .catch((err) => {
          toast("Something Went Wrong");
        });
    } else if (!(data.email && data.password)) {
      toast("Fill both email and password");
    } else if (!data.email) {
      toast("Fill the email");
    } else {
      toast("Fill the password");
    }
  };


  return (
    <>
      <div className="LoginPage ">
        <div className="inventoryLoginContainer row">
          <div className="loginLeft col-lg-6 col-md-6 col-xl-5 col-sm-12 col-xs-12">
            <h1 className="welcome">WELCOME TO</h1>
            <div>
              <img src={Logo} style={{ height: 100, width: 250 }} alt="logo" />
            </div>
            <h1 className="message">
              To Keep Connected With us Please Login<br></br>with your personal
              info
            </h1>
            <div className="input">
              <input
                className="email"
                value={data?.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                type="text"
                placeholder="E-mail"
                name="email"
              />
              <input
                className="password"
                value={data?.password}
                onChange={(e) => setData({ ...data, password: e.target.value })}
                type={showPass ? "text" : "password"}
                placeholder="Password"
                name="password"
              />
            </div>
            <div className="remember">
              <div>
                &nbsp;
                &nbsp;
                <input type="checkbox" onClick={() => { setShowPass(!showPass) }} />
                <span>&nbsp;Show Password</span>
              </div>
              {/* <div className="forgotPassword">
                <span>Forgot Password ?</span>
              </div> */}
            </div>
            <button
              className="button"
              variant="contained"
              onClick={handleSubmit}
            >
              Login
            </button>
            <ToastContainer></ToastContainer>
          </div>

          <div className="loginRight col-lg-6 col-md-6 col-xl-7 col-sm-12 col-xs-12">
            <div>

            </div>
            <div>
              <img src={Car} style={{ height: 510, width: 820 }} alt="car" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
