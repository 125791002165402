import React, { useEffect, useState } from "react";
import Sidebar from "../../../../Common/Sidebar/Sidebar";
import {
  accountMenuItems,
  menuItems,
  navMenuItems,
} from "../../../../Constants/LayoutCommon";
import AddProduct from "./AddProduct";
import { Button, Drawer, Select,Space,Table, Tooltip, Input } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ProductGet , companyCodeIdGet,ProductGetByCompanyId} from "../../../../utils/servies/common";
import moment from "moment/moment";
import ConfirmBox from "../../../../UIComponent/ConfirmBox";
import { ProductDelete } from "../../../../utils/servies/common";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
const ManageProduct = () => {
  const [getData, setData] = useState();

  const dataGet = () => {
    ProductGet()
      .then((res) => {
        setFilterProduct(res.data);
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    dataGet();
  }, []);

  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });

  const [selectedUserProduct, setSelectedUserProduct] = useState({});
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState({});
  const [filterProduct, setFilterProduct] = useState([]);
  const [company, setCompany] = useState();
  const [saveCompany, setSaveCompany] = useState({key:"All"});

  const afterProduct = () => {
    setVisible(false);
  };

  const addUserProduct = () => {
    setVisible(true);
    setSelectedUserProduct({});
  };
  const backToDashboard = () => {
    setVisible(false);
    setSelectedUserProduct({});
  };

  const editUserProduct = (element) => {
    setVisible(true);
    setSelectedUserProduct(element);
  };

  const deleteUserProduct = (element) => {
    setOpen(true);
    setDeleteProduct(element);
  };

  const deleteFunction = async () => {
    await ProductDelete(deleteProduct._id)
      .then((res) => {
        toast.success("Deleted Succesfully");
        setOpen(false);
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });

    dataGet();
  };

  const onSearch = (searchText) => {
    filterProduct.length
      ? setData(
        filterProduct.filter(
          (el) =>
            `${el.productName},${el.description}`
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
        )
      )
      : setData(filterProduct);
  };

  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setConfiguration((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };
  const getCompanyName = () => {
    companyCodeIdGet()
      .then((res) => {
        setCompany(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCompanyName();
  }, []);

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      width: 120,
      render: (companyId) => {
        return (companyId?.map(ele => { return (ele.companyName) }).join(', ')
        )
      }
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 100
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, element) => {
        return moment(element["createdAt"]).format("DD-MMM-YYYY");
      },
      width: 100
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 100
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, element, index) => (
        <div className="actionContainer">
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined onClick={() => editUserProduct(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="Edit">
            <DeleteOutlined onClick={() => deleteUserProduct(element)} />
          </Tooltip>
        </div>
      ),
      key: "action",
      width: 100
    },
  ];
  const getProductBycomp = () => {
    if (saveCompany.key!=="All") {
      ProductGetByCompanyId(saveCompany?.key)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
      dataGet()
    }
    
  };
  const setcompanyval = (e, key) => {
    setSaveCompany(key)
  }
  return (
    /* <Sidebar
       navTitle={""}
       navMenuItems={navMenuItems}
       menuItems={menuItems}
       accountMenuItems={accountMenuItems}
     >*/
    <div className="mainContainer">
      <div className="headerContainer row">
        <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
          Product
        </h4>
        <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px",
            }}
            onInput={(event) => searchOnChange(event)}
          ></Input>
          <SearchOutlined className="searchSymbol" />
          &nbsp;&nbsp;
          <Button
            size="large"
            className="button"
            onClick={() => addUserProduct()}
          >
            Add Product
          </Button>
        </div>
      </div>
      <div className="row mt-3">
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-4 col-xs-4">
              <Select
                showSearch
                className="w-100"
                value={saveCompany}
                onSelect={setcompanyval}
                placeholder="Company"
              >
                <Select.Option value="All" key="All">
                  All
                </Select.Option>
                {company?.length > 0 &&
                  company.map((ele) => {
                    return (
                      <Select.Option value={ele.companyName} key={ele._id}>
                        {ele.companyName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </div>
            <div className="col-lg-3 col-md-3 col-xl-2 col-sm-2 col-xs-2">
              <Space>
                <Button size="large" className="button" style={{ marginLeft: "4px" }} onClick={getProductBycomp}>Submit</Button>
              </Space>
            </div>
        </div>
      <br></br>
      <div className="tableContainer">
        <Table
          columns={columns}
          dataSource={getData}
          // scroll={{
          //   x: "90vw",
          // }}
          pagination={{
            pageSize: configuration.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            total: configuration.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        ></Table>

        <Drawer
          title={`${selectedUserProduct._id ? "Edit" : "Add"} Product`}
          className="drawer-dropDown"
          onClose={() => setVisible(false)}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          zIndex={200}
        >
          {visible ? (
            <AddProduct
              afterProduct={afterProduct}
              selectedUserProduct={selectedUserProduct}
              formType={selectedUserProduct._id ? "edit" : "add"}
              backToDashboard={backToDashboard}
              dataGet={dataGet}
            />
          ) : null}
        </Drawer>
        <ConfirmBox
          open={open}
          closeDialog={() => setOpen(false)}
          Function={deleteFunction}
          tittle={deleteProduct.productName}
          confirmButton={"Delete"}
          heading={"Delete"}
          Content={"Are you Sure want to Delete"}
        ></ConfirmBox>
      </div>
      <ToastContainer />
    </div>
    //</Sidebar>
  );
};
export default ManageProduct;
