import React, { useState, useEffect } from "react";
import { Button, Table, Input, Tooltip, Drawer } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AddCompanyContact from "./AddCompanyContact.js";
import {
  companyContactGet,
  companyContactDelete,
} from "../../utils/servies/common.js";
import ConfirmBox from "../../UIComponent/ConfirmBox.js";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CompanyContact = () => {
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });
  const [selectedcompanyContact, setSelectedCompanyContact] = useState();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [filterCompanyContact, setFilterCompanyContact] = useState([]);
  const getData = () => {
    companyContactGet().then((res) => {
      setData(res.data);
      setFilterCompanyContact(res.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  const AddCompanyContacts = () => {
    setVisible(true);
    setSelectedCompanyContact({});
  };

  const editUser = (element) => {
    setVisible(true);
    setSelectedCompanyContact(element);
  };

  const backToDashboard = () => {
    setVisible(false);
  };

  const deleteUser = (element) => {
    setOpen(true);
    setDeleteData(element);
  };
  const deleteFunction = async () => {
    await companyContactDelete(deleteData._id)
      .then(() => {
        toast.success("Successfully Deleted");
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
    getData();
    setOpen(false);
  };

  const onSearch = (searchText) => {
    filterCompanyContact.length
      ? setData(
        filterCompanyContact.filter(
          (el) =>
            `${el?.branch?.branchName},${el?.companyCodeId?.companyCode},${el.name},${el.email},${el?.companyId?.companyName},${el?.companyCodeId?.description},${el.mobile},${el.designation}`
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
        )
      )
      : setData(filterCompanyContact);
  };

  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setConfiguration((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };
  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      render: (companyId) => {
        return companyId?.companyName;
      },
    },
    {
      title: "Company Code",
      dataIndex: "companyCodeId",
      key: "companyCodeId",
      render: (companyCodeId) => {
        return companyCodeId?.companyCode;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (branch) => {
        return branch?.branchName;
      },
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, element) => {
        return moment(element["createdAt"]).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, element, index) => (
        <div className="actionContainer">
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined onClick={() => editUser(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="Delete">
            <DeleteOutlined onClick={() => deleteUser(element)} />
          </Tooltip>
        </div>
      ),
      key: "action",
    },
  ];

  return (
    <>
      <div className="mainContainer">
        <div className="headerContainer row">
          <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
            Company Contact
          </h4>
          <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
            <Input
              placeholder="Search"
              style={{
                height: "40px",
                width: "280px",
              }}
              onInput={(event) => searchOnChange(event)}
            ></Input>
            <SearchOutlined className="searchSymbol" />
            &nbsp;&nbsp;
            <Button
              size="large"
              className="button"
              onClick={AddCompanyContacts}
            >
              Add Company Contact
            </Button>
          </div>
        </div>
        <br></br>
        <div className="tableContainer">
          <Table
            columns={columns}
            dataSource={data}
            // scroll={{
            //   x: "90vw",
            // }}
            pagination={{
              pageSize: configuration.pagination.pageSize || 10,
              showQuickJumper: true,
              responsive: true,
              total: configuration.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}
          />
          <Drawer
            title={"Company Contact"}
            className="drawer-dropDown"
            visible={visible}
            bodyStyle={{ padding: 0 }}
            onClose={() => setVisible(false)}
            zIndex={200}
          >
            {visible ? (
              <AddCompanyContact
                formType={selectedcompanyContact?._id ? "edit" : "add"}
                getData={getData}
                selectedcompanyContact={selectedcompanyContact}
                backToDashboard={backToDashboard}
              />
            ) : null}
          </Drawer>
          <ConfirmBox
            open={open}
            heading={"Delete"}
            closeDialog={() => setOpen(false)}
            Function={deleteFunction}
            Content={"Are you Sure Want Delete"}
            tittle={deleteData.branchName}
            confirmButton={"Delete"}
          />
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default CompanyContact;
