import React, { useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button } from "antd";
import { FloatLabel } from "../../../../UIComponent/_index";
import { policyCreateCompanyPost } from "../../../../utils/servies/common";
import { policyCreateCompanyUpdate } from "../../../../utils/servies/common";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../../../../Resources/Loader/Loader";

const cmpData = {
  companyName: "",
  description: "",
};

const AddCompany = (props) => {
  const { formType, backToDashBoard, selectedUserCompany, getData } = props;
  let companyy = formType === "edit" ? selectedUserCompany : cmpData;
  const [data, setData] = useState(companyy);
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    backToDashBoard();
  };

  const handleSave = () => {
    if (data?.companyName) {
      setOpen(true);
      policyCreateCompanyPost(data)
        .then((res) => {
          getData();
          backToDashBoard();
          setOpen(false);
          if (!res.message) {
            toast.success("Successfully Saved");
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => toast.error("Something Went Wrong"));
      backToDashBoard();
      setOpen(false);
    } else {
      toast.warning("Please Enter All Details");
    }
  };

  const handleUpdate = () => {
      if (data?.companyName) {
        setOpen(true)
      policyCreateCompanyUpdate(data, data._id)
        .then((res) => {
            getData();
            setOpen(false)
          toast("Updated Successfully");
        })
        .catch((err) => {
            toast.error("Something Went Wrong");
            setOpen(false)
        });
      backToDashBoard();
    } else {
      toast.warning("Please Enter All Details");
    }
  };

  const handleSubmit = (e) => {
    formType === "edit" ? handleUpdate() : handleSave();
  };
  return (
    <>
      <div className="formContainer h-100">
        <OverlayScrollbarsComponent className="formBody">
          <Formik innerRef={(ref) => {}}>
            {({ values }) => (
              <Form noValidate>
                <div className="innerContainer row">
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="Company Name" value={data?.companyName}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        disabled={formType === "view" ? true : false}
                        value={data?.companyName}
                        onChange={(e) => {
                          setData({ ...data, companyName: e.target.value });
                        }}
                      />
                    </FloatLabel>
                  </div>
                  {/* <div className="col-12 mb-2">
                    <FloatLabel label="Descripition" value={data?.description}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        disabled={formType === "view" ? true : false}
                        value={data?.description}
                        onChange={(e) => {
                          setData({ ...data, description: e.target.value });
                        }}
                      />
                    </FloatLabel>
                  </div> */}
                </div>
              </Form>
            )}
          </Formik>
        </OverlayScrollbarsComponent>
        <footer>
          <Button className="button transButton" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="button" onClick={handleSubmit}>
            {formType === "edit" ? "Update" : "Save"}
          </Button>
        </footer>
        <ToastContainer />
      </div>
      <Loader open={open}></Loader>
    </>
  );
};

export default AddCompany;
