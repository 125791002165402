import * as React from "react";
import routes from "./routeConstants";
import PrivateRoute from "./PrivateRoute";
import { Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import CommonRoute from "./CommonRoute";
import Sidebar from '../../src/Common/Sidebar/Sidebar';
import { accountMenuItems, menuItems, navMenuItems } from "../Constants/LayoutCommon";

function Routers() {
  return (
    
    <Suspense
      fallback={
        <div className="row vh-100 align-items-center justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      }
    >
      <Routes>
        {routes.map(
          (
            { element: Component, path, isAuthenticated, isCommon, ...rest },
            i
          ) => {
            return (
              <Route
                key={i}
                {...rest}
                path={path}
                element={
                  isAuthenticated ? (
                    <PrivateRoute>
                      <Component />
                    </PrivateRoute>
                    
                  ) : (
                    <CommonRoute>
                      <Component />
                    </CommonRoute>
                  )
                }
              />
            );
          }
        )}
      </Routes>
    </Suspense>
    
  );
}

export default Routers;
