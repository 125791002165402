import React, { useEffect, useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button, Select } from "antd";
import { FloatLabel } from "../../UIComponent/_index";
import { policyCreateCompanyGet,ProductGetByCompanyId,getAllManager,RtoGetByCompanyId, yearGetByCompanyId } from "../../utils/servies/common";
import { ProductGet } from "../../utils/servies/common";
import { rtoapi , yearapi} from "../../utils/servies/common";

import { policyTypeGet } from "../../utils/servies/common";
import { multipleUserConfigPost} from "../../utils/servies/common";
import { policyCreateUserGet } from "../../utils/servies/common";
import { userConfigUpdate, userConfigBookingCode } from "../../utils/servies/common";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Stack } from "@mui/material";

const { Option } = Select;
let user = {
  userId: "",
  companyId: "",
  companyCodeId: "",
  productId: "",
  policyType: "",
  pacover: "",
  ODPercent: "",
  TPPercent: "",
  NETPercent: "",
  payOutType: "",
  adminPayout: "",
};
const AddUserConfig = (props) => {
  const { backToDashboard, formType, selectedUserConfig, getdata } = props;
  let addUser =
    formType === "view "
      ? selectedUserConfig
      : "edit"
        ? selectedUserConfig
        : user;
  const [data, setData] = useState(addUser);
  const [mapCompanyData, setMapCompanyData] = useState();
  const [rtoList, setRtoList] = useState();
  const [yearList, setYearList] = useState();
  const [selectedRto, setSelectedRto] = useState(addUser?.rto);
  const [selectedYear, setSelectedYear] = useState(addUser?.year);
  const [mapBookingData, setMapBookingData] = useState();
  const [mapProductData, setMapProductData] = useState();
  const [mapPolicyType, setMapPolicyType] = useState();
  const [mapUser, setMapUser] = useState();
  const [saveCompanyName, setSaveCompanyName] = useState(addUser?.companyId);
  const [saveBookingCode, setSaveBookingCode] = useState(addUser?.companyCodeId);
  const [isAllUser, setIsAllUser] = useState(false);
  const initialstate =
    formType === "edit" || formType === "view"
      ? addUser?.productId.map((el) => {
        return el?._id;
      })
      : addUser;
  const [saveProduct, setSaveProduct] = useState(initialstate);
  const [savePolicy, setSavePolicy] = useState(addUser?.policyType);
  const [saveUser, setSaveUser] = useState(addUser?.userId?.userName);
  const [saveDate, setSaveDate] = useState(addUser?.payOutType);
  const [adminAmount, setAdminAmount] = useState(addUser?.adminpayout);
  const [loader, setLoader] = useState(false);
  const role = localStorage.getItem("role")
  const handleSave = async () => {
    if (
      saveUser &&
      saveCompanyName &&
      // saveBookingCode &&
      saveProduct &&
      savePolicy &&
      saveDate && selectedRto && selectedYear
    ) {
      let val=saveUser
      if (saveUser.includes("ALL")) {
        val = await mapUser.map((el) => { return el._id })
      }
      let value = {
        userId: val,
        companyId: saveCompanyName?.key,
        companyCodeId: saveBookingCode?.key,
        rto: selectedRto?.key,
        year:selectedYear?.key,
        productId: saveProduct,
        policyType: savePolicy,
        pacover: data?.pacover,
        ODPercent: data?.ODPercent,
        TPPercent: data?.TPPercent,
        NETPercent: data?.NETPercent,
        payOutType: saveDate,
        remarks:data?.remarks,
        adminpayout: {
          ODPercent: adminAmount?.ODPercent,
          TPPercent: adminAmount?.TPPercent,
          NETPercent: adminAmount?.NETPercent,
        },
      };
      setLoader(true);
      multipleUserConfigPost(value)
        .then(() => {
          toast.success("Successfully Saved");
          // getdata();
          backToDashboard(value);
        })
        .catch(() => {
          toast.error("Something Went Wrong");
          backToDashboard({});
        });
      setLoader(false);

    } else {
      toast.warning("Please Enter All Datails");
    }
  };
  const timeout = () => {
    setTimeout(setLoader(true), 5000);
  }
  const handleUpdate = () => {
    if (
      saveUser &&
      saveCompanyName &&
      // saveBookingCode &&
      saveProduct &&
      savePolicy &&
      saveDate && selectedRto && selectedYear
    ) {
      let value = {
        _id: data?._id,
        userId: saveUser?.key,
        companyId: saveCompanyName?.key,
        rto: selectedRto?.key,
        year: selectedYear?.key,
        companyCodeId: saveBookingCode?.key,
        productId: saveProduct,
        policyType: savePolicy,
        pacover: data?.pacover,
        ODPercent: data?.ODPercent,
        TPPercent: data?.TPPercent,
        NETPercent: data?.NETPercent,
        payOutType: saveDate,
        remarks:data?.remarks,
        adminpayout: {
          ODPercent: adminAmount?.ODPercent,
          TPPercent: adminAmount?.TPPercent,
          NETPercent: adminAmount?.NETPercent,
        },
      };
      setLoader(true);

      userConfigUpdate(value, value._id)
        .then(() => {
          timeout();
          toast("Successfully updated");
          // getdata({});
          backToDashboard({});
        })
        .catch(() => {
          toast.error("Something Went Wrong");
          backToDashboard({});
        });
      setLoader(false);

    } else {
      toast.warning("Please Fill All Details");
    }
  };
  const handleChange = (value) => {
    setSaveProduct(value);
  };
  const handleChangeSaveUser = async (value, key) => {
    if (value.includes("ALL")) {
      setIsAllUser(true)
      setSaveUser(["ALL"]);
    } else {
      setIsAllUser(false)
      let val = await key.map((el) => { return el.key })
      setSaveUser(val);
    }
  };
  const getCompanyData = () => {
    policyCreateCompanyGet().then((res) => {
      setMapCompanyData(res.data);
    });
  };

  // const getBookingCode = () => {
  //   companyCodeGet().then((res) => {
  //     setMapBookingData(res.data);
  //   });
  // };
  const getProduct = () => {
    ProductGet().then((res) => {
      setMapProductData(res.data);
    });
  };

  const getRto = () => {
    rtoapi({}).then(res => {
      setRtoList(res.data)
    })
  }
  const getYear = () => {
    yearapi({}).then(res => {
      setYearList(res.data)
    })
  }
  const getPolicyType = () => {
    policyTypeGet().then((res) => {
      setMapPolicyType(res.data);
    });
  };

  const getUserId = () => {
    if (localStorage.getItem('role') == 'admin') {
      getAllManager().then((res) => {
        setMapUser(res);
      })
    } else {
      policyCreateUserGet().then((res) => {
        setMapUser(res.data);
      });
    }
  };

  const handleSubmit = async () => {
    formType === "edit" ? handleUpdate() : handleSave();
  };
  const onCancel = () => {
    backToDashboard({});
  };
  const handleChangepolicyType=(value)=>{
    setSavePolicy(value)
  }
  const getBookingCodeValue = (e) => {
    userConfigBookingCode(e.key).then((res) => {
      setMapBookingData(res.data)
    })
  }
  const getProductBycomp = (id) => {
    if (id.key !== "All") {
      ProductGetByCompanyId(id.key)
      .then((res) => {
        setMapProductData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
      getProduct()
    }
    
  };
  const getRtoBycomp = (id) => {
    if (id.key !== "All") {
      RtoGetByCompanyId(id.key)
      .then((res) => {
        setRtoList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
      getRto()
    }
    
  };

  const getYearBycomp = (id) => {
    if (id.key !== "All") {
      yearGetByCompanyId(id.key)
      .then((res) => {
        setYearList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
      getYear()
    }
    
  };

  useEffect(() => {
    getCompanyData();
    // getBookingCode();
    getProduct();
    getPolicyType();
    getUserId();
    getRto()
    getYear()
  }, []);
  return (
    <>
      <div className="formContainer h-100">
        <OverlayScrollbarsComponent className="formBody">
          <Formik innerRef={(ref) => { }}>
            {({ values }) => (
              <Form noValidate>
                <div className="innerContainer row">
                  {/* <div className="dropDownContainer col-12 mb-2 ">
                    <FloatLabel label="User" value={saveUser}>
                      <Select
                        showSearch
                        className="w-100"
                        value={saveUser?.userName ? saveUser?.userName : saveUser}
                        onSelect={(e, key) => {
                          setSaveUser(key);
                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        {mapUser?.length > 0 &&
                          mapUser.map((el) => {
                            return (
                              <Option value={el?.userName} key={el?._id}>{el?.userName} ({el?.role.toUpperCase()})</Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div> */}
                  <div className="col-12 mb-2" hidden={formType === "add"}>
                    <FloatLabel label="User" value={saveUser}>
                      <Select
                        showSearch
                        // maxTagCount="4"
                        className="w-100"
                        style={{ width: '100%' }}
                        value={saveUser}
                        onChange={handleChangeSaveUser}
                        disabled={formType === "view" ? true : false}
                      >
                        {mapUser?.length > 0 &&
                          mapUser.map((el) => {
                            return (
                              <Option value={el?.userName} key={el?._id}>{el?.userName} ({el?.role.toUpperCase()})({el?.branch?.branchName})</Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div
                    className="col-12 mb-2"
                    hidden={formType === "edit" || formType === "view"}
                  >
                    <FloatLabel label="User" value={saveUser}>
                      <Select
                        mode="multiple"
                        maxTagCount="responsive"
                        className="w-100"
                        // style={{ width: '100%' }}
                        onChange={handleChangeSaveUser}
                        value={saveUser}
                        disabled={formType === "view" ? true : false}
                      >
                        <Select.Option value="ALL" key="1001" >ALL</Select.Option>
                        {(mapUser?.length > 0 && !isAllUser) &&
                          mapUser.map((el) => {
                            return (
                              <Select.Option value={el?._id} key={el?._id}>{el?.userName} ({el?.role.toUpperCase()})</Select.Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="Company Name" value={saveCompanyName}>
                      <Select
                        showSearch
                        className="w-100"
                        value={saveCompanyName?.companyName ? saveCompanyName?.companyName : saveCompanyName}
                        onSelect={(e, key) => {
                          getBookingCodeValue(key);
                          setSaveCompanyName(key)
                          setSaveBookingCode()
                          setSaveProduct()
                          getProductBycomp(key)
                          setSelectedRto()
                          getRtoBycomp(key)
                          setSelectedYear()
                          getYearBycomp(key)

                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        {mapCompanyData?.length > 0 &&
                          mapCompanyData.map((el) => {
                            return (
                              <Option value={el.companyName} key={el._id}>
                                {el.companyName}
                              </Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="RTO Name" value={selectedRto}>
                      <Select
                        showSearch
                        className="w-100"
                        value={selectedRto?.rtoName ? selectedRto?.rtoName : selectedRto}
                        onSelect={(e, key) => {
                          setSelectedRto(key)
                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        {rtoList?.length > 0 &&
                          rtoList.map((el) => {
                            return (
                              <Option value={el.rtoName} key={el._id}>
                                {el.rtoName}
                              </Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="Year" value={selectedYear}>
                      <Select
                        showSearch
                        className="w-100"
                        value={selectedYear?.year ? selectedYear?.year : selectedYear}
                        onSelect={(e, key) => {
                          setSelectedYear(key)
                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        {yearList?.length > 0 &&
                          yearList.map((el) => {
                            return (
                              <Option value={el.year} key={el._id}>
                                {el.year}
                              </Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Booking Code" value={saveBookingCode}>
                      <Select
                        showSearch
                        className="w-100"
                        value={saveBookingCode?.companyCode ? saveBookingCode?.companyCode : saveBookingCode}
                        onSelect={(e, key) => {
                          setSaveBookingCode(key);
                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        {mapBookingData?.length > 0 &&
                          mapBookingData.map((el) => {
                            return (
                              <Option value={el.companyCode} key={el._id}>{el.companyCode}</Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2" hidden={formType === "add"}>
                    <FloatLabel label="Product" value={saveProduct}>
                      <Select
                        showSearch
                        // maxTagCount="4"
                        className="w-100"
                        style={{ width: '100%' }}
                        value={saveProduct}
                        onChange={handleChange}
                        disabled={formType === "view" ? true : false}
                      >
                        {mapProductData?.length > 0 &&
                          mapProductData.map((el) => {
                            return (
                              <Option value={el._id} key={el._id}>{el.productName}</Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div
                    className="col-12 mb-2"
                    hidden={formType === "edit" || formType === "view"}
                  >
                    <FloatLabel label="Product" value={saveProduct}>
                      <Select
                        mode="multiple"
                        maxTagCount="responsive"
                        className="w-100"
                        onChange={handleChange}
                        disabled={formType === "view" ? true : false}
                      >
                        {mapProductData?.length > 0 &&
                          mapProductData.map((el) => {
                            return (
                              <Select.Option value={el._id} key={el._id}>{el.productName}</Select.Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2" hidden={formType === "add"}>
                    <FloatLabel label="Policy Type" value={savePolicy}>
                      <Select
                        showSearch
                        className="w-100"
                        value={savePolicy?.name ? savePolicy?.name : savePolicy}
                        onChange={handleChangepolicyType}
                        disabled={formType === "view" ? true : false}
                      >
                        {mapPolicyType?.length > 0 &&
                          mapPolicyType.map((el) => {
                            return <Option value={el._id} key={el._id}>{el.name}</Option>;
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2"  hidden={formType === "edit" || formType === "view"}>
                    <FloatLabel label="Policy Type" value={savePolicy}>
                      <Select
                        showSearch
                        mode="multiple"
                        maxTagCount="responsive"
                        className="w-100"
                        value={savePolicy?.name ? savePolicy?.name : savePolicy}
                        onChange={handleChangepolicyType}
                        disabled={formType === "view" ? true : false}
                      >
                        {mapPolicyType?.length > 0 &&
                          mapPolicyType.map((el) => {
                            return <Option value={el._id} key={el._id}>{el.name}</Option>;
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="PA Cover (YES/NO)" value={data?.pacover}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        // placeholder="YES/NO"
                        disabled={formType === "view" ? true : false}
                        value={data?.pacover}
                        onChange={(e) =>
                          setData({ ...data, pacover: e.target.value })
                        }
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label={role == "admin" ? "Manager OD" : "Agent OD"} value={data?.ODPercent}>
                      <Field
                        type="text"
                        pattern="[0-9]*"
                        id="1"
                        className="w-100 form-control"
                        disabled={formType === "view" ? true : false}
                        value={data?.ODPercent}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)  && !event.key.includes(".")) {
                            event.preventDefault();
                          }
                        }}                    
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setData({ ...data, ODPercent: e.target.value })
                          }
                        }
                        }
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label={role == "admin" ? "Manager TP" : "Agent TP"} value={data?.TPPercent}>
                      <Field
                        type="text"
                        pattern="[0-9]*"
                        id="2"
                        className="w-100 form-control"
                        disabled={formType === "view" ? true : false}
                        value={data?.TPPercent}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)  && !event.key.includes(".")) {
                            event.preventDefault();
                          }
                        }}                    
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setData({ ...data, TPPercent: e.target.value })
                          } 
                        }
                        }
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label={role == "admin" ? "Manager NET" : "Agent NET"} value={data?.NETPercent}>
                      <Field
                        type="text"
                        pattern="[0-9]*"
                        id="3"
                        className="w-100 form-control"
                        disabled={formType === "view" ? true : false}
                        value={data?.NETPercent}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)  && !event.key.includes(".")) {
                            event.preventDefault();
                          }
                        }}                    
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setData({ ...data, NETPercent: e.target.value })
                          }
                        }
                        }
                      />
                    </FloatLabel>
                  </div>
                  {role == "admin" &&
                    <>
                      <div className="col-12 mb-2">
                        <FloatLabel label="Admin OD" value={adminAmount?.ODPercent}>
                        <Field
                          type="text"
                          pattern="[0-9]*"
                          id="4"
                          className="w-100 form-control"
                          disabled={formType === "view" ? true : false}
                          value={adminAmount?.ODPercent}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)  && !event.key.includes(".")) {
                              event.preventDefault();
                            }
                          }}                    
                          onChange={(e) => {
                            if (!isNaN(e.target.value))  {
                            setAdminAmount({
                              ...adminAmount,
                              ODPercent: e.target.value,
                            })
                          }
                          }
                            }
                          />
                        </FloatLabel>
                      </div>
                      <div className="col-12 mb-2">
                        <FloatLabel label="Admin TP" value={adminAmount?.TPPercent}>
                          <Field
                          type="text"
                          id="5"
                          pattern="[0-9]*"
                            className="w-100 form-control"
                            disabled={formType === "view" ? true : false}
                          value={adminAmount?.TPPercent}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)  && !event.key.includes(".")) {
                              event.preventDefault();
                            }
                          }}                    
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                setAdminAmount({
                                  ...adminAmount,
                                  TPPercent: e.target.value,
                                })
                              }
                              }
                              
                            }
                          />
                        </FloatLabel>
                      </div>
                      <div className="col-12 mb-2">
                        <FloatLabel
                          label="Admin NET"
                          value={adminAmount?.NETPercent}
                        >
                          <Field
                          type="text"
                          pattern="[0-9]*"
                          id="6"
                            className="w-100 form-control"
                            disabled={formType === "view" ? true : false}
                          value={adminAmount?.NETPercent}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                              event.preventDefault();
                            }
                          }}                    
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setAdminAmount({
                                ...adminAmount,
                                NETPercent: e.target.value,
                              })
                            }
                            }
                              
                            }
                          />
                        </FloatLabel>
                      </div>
                    </>
                  }
                  <div className="col-12 mb-2">
                    <FloatLabel label="Payout" value={saveDate}>
                      <Select
                        showSearch
                        className="w-100"
                        value={saveDate}
                        onSelect={(e) => {
                          setSaveDate(e);
                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        <Option value="daily">daily</Option>
                        <Option value="weekly">Weekly</Option>
                        <Option value="monthly">Monthly</Option>
                      </Select>
                    </FloatLabel>
                  </div>
                  {/* {
                    formType === "edit" &&
                    <div className="col-12 mb-2">
                      <FloatLabel label="Remarks" value={data?.remarks}>
                        <Field
                          as="textarea"
                          className="w-100 form-control"
                          // placeholder="YES/NO"
                          disabled={formType === "view" ? true : false}
                          value={data?.remarks}
                          onChange={(e) =>
                            setData({ ...data, remarks: e.target.value })
                          }
                        />
                      </FloatLabel>
                    </div>
                  } */}
                  
                </div>
              </Form>
            )}
          </Formik>
        </OverlayScrollbarsComponent>
      </div>
      <footer>
        <Button className="button transButton" onClick={() => onCancel()}>
          Cancel
        </Button>
        {!loader &&
          <Button
            className="button"
            onClick={handleSubmit}
            hidden={formType === "view" ? true : false}
          >
            {formType === "edit" ? "Update" : "Save"}
          </Button>}
      </footer>
      {loader ? (
        <Stack
          style={{
            position: "fixed",
            right: "50%",
            bottom: "50%",
          }}
        >
          <div class="loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Stack>
      ) : null}

      <ToastContainer />
    </>
  );
};

export default AddUserConfig;
