import React, { useState ,useEffect} from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button ,Select} from "antd";
import { rtoapipost } from "../../../../utils/servies/common";
import { rtoupdate,companyCodeIdGet } from "../../../../utils/servies/common";
import { FloatLabel } from '../../../../UIComponent/_index';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from "../../../../Resources/Loader/Loader";
const { Option } = Select;

const branch = {
  rtoName: "",
};

const AddRto = (props) => {
  const { formType, selectedBranch, dataGet, backToDashboard } = props;
  let s = formType === "view" ? selectedBranch : "edit" ? selectedBranch : branch;
  const [data, setData] = useState(s);
  const [open, setOpen] = useState(false)
  const [company, setCompany] = useState();
  const [companyName, setCompanyName] = useState(s?.companyId?._id);
  const handleSave = () => {
    
    if (companyName && data?.rtoName) {
      setOpen(true);
      let sendData = {
        companyId: companyName,
        rtoName: data.rtoName,
      }
      rtoapipost(sendData)
        .then((res) => {
          backToDashboard()
          dataGet()
          setOpen(false)
          console.log(res)
          if (res.message === null) {
            toast.success("Successfully Saved")
          }
          else {
            toast.error(res.message)
          }
        })
        .catch((err) => {
          toast.error("Something went wrong")
          setOpen(false)
          // backToDashboard()
        });
    }
    else {
      toast.warning(" Please Enter Branch ")
      backToDashboard()
    }
  }

  const handleUpdate = () => {
    if ( companyName && data?.rtoName) {
      setOpen(true);
      let sendData = {
        companyId: companyName,
        rtoName: data.rtoName,
      }
      rtoupdate(sendData, data?._id)
        .then(() => {
          toast("Successfully Updated")
          dataGet()
          setOpen(false)
          backToDashboard()
        }).catch(() => {
          toast.error("Something Went Wrong")
          backToDashboard()
          setOpen(false)
        })
    }
    else {
      toast.warning("Please Enter Branch")
    }
  }

  const handleSubmit = () => {
    formType === "edit" ? handleUpdate() : handleSave();
  };

  const cancel = () => {
    backToDashboard();
  };
  const getCompanyName = () => {
    companyCodeIdGet()
      .then((res) => {
        setCompany(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCompanyName();
  }, []);

  return (
    <>
      <div className="formContainer h-100">
        <OverlayScrollbarsComponent className="formBody">
          <Formik innerRef={(ref) => { }}>
            {({ values }) => (
              <Form noValidate>
                <div className="innerContainer row">
                <div className="dropDownContainer col-12 mb-2">
                    <FloatLabel label="Company Name" value={companyName}>
                      <Select
                        labelid="Company ID"
                        className="w-100"
                        value={companyName}
                        onSelect={(e) => {
                          setCompanyName(e);
                        }}
                      >
                        {company?.length > 0 &&
                          company.map((ele) => {
                            return (
                              <Option value={ele._id} key={ele._id}>
                                {ele.companyName}
                              </Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="RTO" value={data?.rtoName}>
                      <Field
                        as="textarea"
                        className="w-100 form-control"
                        name="name"
                        value={data?.rtoName}
                        onChange={(e) => {
                          setData({ ...data, rtoName: e.target.value });
                        }}
                        disabled={formType === 'view' ? true : false}
                      />
                    </FloatLabel>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OverlayScrollbarsComponent>
        <footer>
          <Button className="button transButton" onClick={cancel}>
            Cancel
          </Button>
          <Button className="button" onClick={handleSubmit}
            hidden={formType === 'view' ? true : false}>
            {formType === 'edit' ? 'Update' : 'Save'}
          </Button>
        </footer>
        <ToastContainer />
      </div>
      <Loader open={open} />
    </>
  );
};
export default AddRto;
