import React from "react";
import { useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button } from "antd";
import { FloatLabel } from "../../../../UIComponent/_index";
import { Select } from "antd";
import { companyLoginIdGet } from "../../../../utils/servies/common";
import { useEffect } from "react";
import {
  companyLoginBranchGet,
  companyLoginCompanyCodeGet,
  companyLoginUserGet,
  userConfigBookingCode,
  getAllUsersDropDown,
  companyLoginPost,
  companyLoginUpdate,
} from "../../../../utils/servies/common";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../../../../Resources/Loader/Loader";
import { getAllManager } from "../../../../utils/servies/common";
const { Option } = Select;

const user = {
  companyName: "",
  branch: "",
  companyCodeId: "",
  userId: "",
  password: "",
  url: "",
  remarks: "",
  userType: "",
};

const AddCompanyLogin = (props) => {
  const { formType, selectedCompanyLogin, backtoDashBoard, getValue } = props;
  let status =
    formType === "view"
      ? selectedCompanyLogin
      : formType === "edit"
        ? selectedCompanyLogin
        : user;

  const [company, setCompany] = useState();
  const [branch, setBranch] = useState();
  const [companyCde, setCompanyCode] = useState();
  const [saveCompany, setSaveCompany] = useState(status?.companyId?._id);
  const [saveBranch, setSaveBranch] = useState(status?.branch?._id);
  const [saveCompanyCode, setSaveCompanyCode] = useState(
    status?.companyCodeId?._id
  );
  const [saveData, setSaveData] = useState(status);
  const [saveUserType, setSaveUserType] = useState(status?.userType);
  const [mapUser, setMapUser] = useState()
  const [saveAgent, setSaveAgent] = useState()
  const [saveAgentkey, setSaveAgentkey] = useState()
  const [open, setOpen] = useState(false)
  const [saveManager, setSaveManager] = useState(status?.managerId)
  const [findManagerRole,setFindManagerRole] = useState()
  const [findAgentRole, setFindAgentRole] = useState()
  const [agentList, setAgentList] = useState([])

  useEffect(() => {
    getAllmangerData()
    nameCompany();
    nameBranch();
    codeCompany();
    getUser();
    if (status?.userId) {
      let dat = status?.userId.map((el) => { return el._id })
      setSaveAgent(dat)
    }
    // if (status?.userId?.length > 0) {
    //   let data = status?.userId?.map(ele => {
    //     return ele._id
    //   })
    //   if (saveUserType == 'agent') {
    //     setSaveAgent(data)
    //   } else if (saveUserType == 'manager') {
    //     setSaveManager(data)
    //   }
    // } else {
    //   setSaveAgent([])
    //   setSaveManager([])
    // }

  }, [])
  const getAllmangerData = () => {
    getAllManager().then(res => {
      setFindManagerRole(res)
    })
  }
  const nameCompany = () => {
    companyLoginIdGet()
      .then((res) => {
        setCompany(res.data);
      })
      .catch((err) => console.log(err));
  };
  const nameBranch = () => {
    companyLoginBranchGet()
      .then((res) => {
        setBranch(res.data);
      })
      .catch((err) => console.log(err));
  };
  const codeCompany = () => {
    companyLoginCompanyCodeGet()
      .then((res) => {
        setCompanyCode(res.data);
      })
      .catch((err) => console.log(err));
  };

  const codeCompanyById = (compId) => {
    console.log("calling")
    userConfigBookingCode(compId)
      .then((res) => {
        setCompanyCode(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getUser = () => {
    getAllUsersDropDown()
      .then((res) => {
        setMapUser(res.data);
        const data = res.data.filter(obj => {
          return obj.role === "agent"
        })
        setFindAgentRole(data)
        setAgentList(data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const getManager = () => {
  //   getAllManager().then((res) => {
  //     setMapManager(res.data);
  //   })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }

  const handleSubmit = () => {
    if (saveUserType === 'all' || saveUserType === 'admin') {
      setSaveManager()
      setSaveAgent()
    }
    formType === "edit" ? handleUpdate() : handleSave();
  };

  const handleSave = () => {
    if (
      saveCompany  &&
      saveCompanyCode &&
      saveData?.loginId &&
      saveData?.password &&
      saveData?.url &&
      saveData?.remarks &&
      saveUserType
    ) {
      let value = {
        companyId: saveCompany,

        companyCodeId: saveCompanyCode,
        loginId: saveData?.loginId,
        password: saveData?.password,
        url: saveData?.url,
        remarks: saveData?.remarks,
        userType: saveUserType,
        userId: (saveUserType=='agent' || saveUserType=='manager') ? saveAgentkey : [],
        managerId :saveUserType=='manager' ? saveManager : []
      };
      if (saveBranch === "all") {
      }
      else { value.branch = saveBranch }
      setOpen(true);
      companyLoginPost(value)
        .then(() => {
          toast.success("Saved Successfully");
          getValue();
          backtoDashBoard();
          setOpen(false);
        })
        .catch(() => {
          toast.error("Something Went Wrong");
          backtoDashBoard();
          setOpen(false);
        });
    } else {
      toast.warning("Please Fill All Details");
    }
  };

  const handleUpdate = () => {
    if (
      saveCompany  &&
      saveCompanyCode &&
      saveData?.loginId &&
      saveData?.password &&
      saveData?.url &&
      saveData?.remarks &&
      saveUserType
    ) {
      let value = {
        _id: saveData._id,
        companyId: saveCompany,
        companyCodeId: saveCompanyCode,
        loginId: saveData?.loginId,
        password: saveData?.password,
        url: saveData?.url,
        remarks: saveData?.remarks,
        userType: saveUserType,
        userId: (saveUserType=='agent' || saveUserType=='manager') ? saveAgentkey : [],
        managerId :saveUserType=='manager' ? saveManager : []
      };
      if (saveBranch === "all") {
        value.branch = null
      }
      else { value.branch = saveBranch }
      setOpen(true);
      companyLoginUpdate(value, value._id)
        .then(() => {
          toast("Successfully Updated");
          getValue();
          backtoDashBoard();
          setOpen(false);
        })
        .catch(() => {
          toast.error("Something Went Wrong");
          backtoDashBoard();
          setOpen(false);
        });
    } else {
      toast.warning("Please Fill All Details");
    }
  };

  const handleCancel = () => {
    backtoDashBoard();
  };

  const filterAgentList = async (e) => {
    let val = e.map((el) => {
      return el.key
    })
    console.log(e,val)

    let dat=[]
    findAgentRole.filter(ele => {
      console.log(val.includes(ele.branch._id),val, ele)
      if (val.includes(ele.branch._id)) {
        dat.push(ele)
      }
    })
    setAgentList(dat)
  }

  return (
    <>
      <div className="formContainer h-100">
        <OverlayScrollbarsComponent className="formBody">
          <Formik innerRef={(ref) => { }}>
            {({ values }) => (
              <Form noValidate>
                <div className="innerContainer row">
                  <div className="dropDownContainer col-12 mb-2 ">
                    <FloatLabel label="Company Name" value={saveCompany}>
                      <Select
                        className="w-100"
                        value={saveCompany}
                        onSelect={(e) => {
                          console.log(e)
                          codeCompanyById(e)
                          setSaveCompany(e);
                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        {company?.length > 0 &&
                          company.map((ele) => {
                            return (
                              <Option value={ele._id} key={ele._id}>
                                {ele.companyName}
                              </Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Company Code" value={saveCompanyCode}>
                      <Select
                        className="w-100 "
                        value={saveCompanyCode}
                        onSelect={(e) => {
                          setSaveCompanyCode(e);
                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        {companyCde?.length > 0 &&
                          companyCde.map((ele) => {
                            return (
                              <Option value={ele._id} key={ele._id}>
                                {ele.companyCode}
                              </Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Login Id" value={saveData?.loginId}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={saveData?.loginId}
                        onChange={(e) =>
                          setSaveData({ ...saveData, loginId: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  {formType === "edit" && (
                    <div className="col-12 mb-2">
                      <FloatLabel label="Password" value={saveData.password}>
                        <Field
                          type="text"
                          className="w-100 form-control"
                          value={saveData?.password}
                          onChange={(e) =>
                            setSaveData({
                              ...saveData,
                              password: e.target.value,
                            })
                          }
                        />
                      </FloatLabel>
                    </div>
                  )}
                  {formType === "add" && (
                    <div className="col-12 mb-2">
                      <FloatLabel label="Password" value={saveData.password}>
                        <Field
                          type="text"
                          className="w-100 form-control"
                          value={saveData?.password}
                          onChange={(e) =>
                            setSaveData({
                              ...saveData,
                              password: e.target.value,
                            })
                          }
                        />
                      </FloatLabel>
                    </div>
                  )}
                  <div className="col-12 mb-2">
                    <FloatLabel label="URL" value={saveData.url}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={saveData?.url}
                        onChange={(e) =>
                          setSaveData({ ...saveData, url: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Remarks" value={saveData.remarks}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={saveData?.remarks}
                        onChange={(e) =>
                          setSaveData({ ...saveData, remarks: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="User Type" value={saveUserType}>
                      <Select
                        className="w-100 "
                        value={saveUserType}
                        onSelect={(e) => {
                          setSaveUserType(e);
                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        <Option value="all">All</Option>
                        <Option value="admin">Admin</Option>
                        <Option value="manager">Manager</Option>
                        {/* <Option value="agent">Agent</Option> */}
                      </Select>
                    </FloatLabel>
                  </div>
                  {
                    saveUserType === ('all') && 
                    <div className="col-12 mb-2">
                      <FloatLabel label="Branch" value={saveBranch}>
                        <Select
                          className="w-100 "
                          value={saveBranch}
                          onSelect={(e) => {
                            setSaveBranch(e);
                          }}
                          disabled={formType === "view" ? true : false}
                        >
                          <Select.Option value="all">ALL</Select.Option>
                          {branch?.length > 0 &&
                            branch.map((ele) => {
                              return (
                                <Select.Option value={ele._id} key={ele._id}>
                                  {ele.branchName}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </FloatLabel>
                    </div>
                   }
                  <div className="col-12 mb-2">
                    {saveUserType === "manager" && (
                      <FloatLabel label="Manager" value={saveManager}>
                        <Select
                          showSearch
                          mode="multiple"
                          maxTagCount="responsive"
                          allowClear
                          className="w-100"
                          value={saveManager}
                          onChange={(e,key) => {
                            console.log("e ma",e,key)
                            setSaveManager(e);
                            filterAgentList(key)
                          }}
                          disabled={formType === "view" ? true : false}
                        >
                          {findManagerRole?.length > 0 &&
                            findManagerRole.map((el) => {
                              return (
                                <Option value={el._id}  key={el.branch._id}>
                                  {el.userName}
                                </Option>
                              );
                            })}
                        </Select>
                      </FloatLabel>
                    )}
                  </div>
                  <div className="col-12 mb-2">
                    {(saveUserType === "agent" || saveUserType=="manager") && (
                      <FloatLabel label="agent" value={saveAgent}>
                        <Select
                          showSearch
                          mode="multiple"
                          maxTagCount="responsive"
                          allowClear
                          className="w-100"
                          value={saveAgent}
                          onChange={async(e,key) => {
                            setSaveAgent(e);
                            let v = await key.map(ele => {
                              return ele.key
                            })
                            setSaveAgentkey(v)
                          }}
                          disabled={formType === "view" ? true : false}
                        >
                          {agentList?.length > 0 &&
                            agentList.map((el) => {
                              return (
                                <Option value={el.userName} key={el._id}>
                                  {el.userName} ({el?.branch?.branchName})
                                </Option>
                              );
                            })}
                        </Select>
                      </FloatLabel>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OverlayScrollbarsComponent>
      </div>
      <footer>
        <Button className="cancelButton" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="button"
          onClick={handleSubmit}
          hidden={formType === "view" ? true : false}
        >
          {formType === "edit" ? "Update" : "Save"}
        </Button>
      </footer>
      <ToastContainer />
      <Loader open={open} />
    </>
  );
};
export default AddCompanyLogin;
