import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {
  accountMenuItems,
  menuItems,
  navMenuItems,
} from "../../Constants/LayoutCommon";
import AddPolicyEntryPending from "./AddPolicyEntryPending";
import { Button, Drawer, Table, Tooltip, Input, Select } from "antd";
import { EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { pendingPolicy, specialSearch } from "../../utils/servies/common";
import moment from "moment/moment";
import { Space, DatePicker } from "antd";
import axios from "axios";
import ConfirmBox from "../../UIComponent/ConfirmBox";
import { FileDownloadRounded } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllUsersDropDown, getAllManager } from "../../utils/servies/common";
import { Typography } from "@mui/material";
import { policyCreateCompanyGet } from "../../utils/servies/common.js";
import { CSVLink } from "react-csv";
import {baseUrl} from '../../Constants/constants'

const PolicyEntryPending = () => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [policyPending, setPolicyPending] = useState({
    pagination: {
      pageSize: 10,
    },
  });
  const [selectedPolicyPending, setSelectedPolicyPending] = useState({});
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState();
  const [date, setDate] = useState();
  const [filterPolicyPending, setFilterPolicyPending] = useState([]);
  const [special, setSpecial] = useState();
  const [userr, setUserr] = useState();
  const [userName, setUserName] = useState();
  const [dataCompany, setDataCompany] = useState();
  const [getCompany, setGetCompany] = useState();
  const [isManagerSelected, setIsManagerSelected] = useState(false);
  const [managerList, setManagerList] = useState([]);
  const [selectedManager, setSelectedManager] = useState();
  const [showdownload, setshowdownload] = useState(false);
  const [submitType, setSubmitType] = useState()
  const [backtoValue, setBacktoValue] = useState({})

  const getDataCompany = () => {
    policyCreateCompanyGet().then((res) => {
      setDataCompany(res.data);
    });
  };
  const headers = [
    { label: "Company Name", key: "companyId.companyName" },
    { label: "Created At", key: "createdAt" },
    { label: "policyType", key: "policyType.name" },
    { label: "product ", key: "product.productName" },
    { label: "Policy Number", key: "policyNumber" },
    { label: "Policy Date", key: "policyDate" },
    { label: "Customer Name", key: "customerName" },
    { label: "Customer Mobile", key: "customerMobile" },
    { label: "Registeration Number", key: "RegistrationNumber" },
    { label: "Od Premium", key: "odPremium" },
    { label: "TP Premium", key: "tpPremium" },
    { label: "Net Premium", key: "netPremium" },
    { label: "Total Premium", key: "totalPremium" },
    { label: "Agent Share", key: "agentShare" },
    { label: "User Email", key: "customerEmail" },
    { label: "policy Status", key: "status" },
  ];
  const csvFile = {
    filename: "PolicyList.csv",
    headers: headers,
    data: user ? user : "",
  };
  const handlemangerSelect = (event, key) => {
    var senddata = {};
    if (localStorage.getItem("role") == "admin") {
      senddata.role = "agent";
    }
    if (key.key == "1001") {
      setSelectedManager("All");
      getUser(senddata);
    } else {
      managerList.map((ele) => {
        if (ele.branch._id === key.key) {
          setSelectedManager(ele._id);
        }
      });
      senddata.branch = key.key;
      getUser(senddata);
    }
  };

  const getAllmangerData = () => {
    getAllManager().then((res) => {
      setManagerList(res);
    });
  };
  const getvalue = () => {
    {
      let sendData = {
        searchField: {},
      };
      pendingPolicy(sendData).then((res) => {
        setFilterPolicyPending(res.data);
        setUser(res.data);
      });
    }
  };

  const getUser = (data) => {
    // console.log("callimg");
    if (localStorage.getItem("role") == "admin") {
      data.role = "agent";
    }
    getAllUsersDropDown(data).then((res) => {
      setUserr(res.data);
    });
  };
  const getUserName = (event, key) => {
    setUserName(key);
  };
  const getCompanyName = (event, key) => {
    setGetCompany(key);
  };
  const handleSubmit = async () => {
    setshowdownload(true);
    await setBacktoValue({
      userName: userName,
      getCompany: getCompany,
      selectedManager: selectedManager,
      submitType: undefined,
      date:date
    })
    if (userName && getCompany) {
      if (date) {
        var sendData = {
          startDate: date[0],
          endDate: date[1],
        };
        if (userName.key != "1001") {
          sendData.userId = userName.key;
        }
        if (getCompany.key != "1001") {
          sendData.companyId = getCompany.key;
        }
        if (selectedManager != "All") {
          sendData.managerId = selectedManager;
        }
        await pendingPolicy(sendData).then((res) => {
          setFilterPolicyPending(res.data);
          setUser(res.data);
        });
      } else {
        toast.error("Please enter the Date");
      }
    } else {
      toast.error("Please Select the User Name and Company");
    }
  };
  const fullValue = async (e) => {
    setshowdownload(false);
    await setBacktoValue({
      userName: userName,
      getCompany: getCompany,
      selectedManager: selectedManager,
      submitType: e,
      date:date
    })
    if (userName && getCompany) {
      if (date) {
        var sendData = {
          startDate: date[0],
          endDate: date[1],
          status: e,
        };
        if (userName.key != "1001") {
          sendData.userId = userName.key;
        }
        if (getCompany.key != "1001") {
          sendData.companyId = getCompany.key;
        }
        if (selectedManager != "All") {
          sendData.managerId = selectedManager;
        }
        await pendingPolicy(sendData).then((res) => {
          setFilterPolicyPending(res.data);
          setUser(res.data);
        });
      } else {
        toast.error("Please enter the Date");
      }
    } else {
      toast.error("Please Select the User Name and Company");
    }
  };
  // console.log(user?.userId[0]?.userName)
  const onChanges = (value) => {
    setDate(value);
  };

  const backToDashBoard = async(data) => {
    setVisible(false);
    await setUserName(data.userName)
    await setGetCompany(data.getCompany)
    await setSelectedManager(data.selectedManager)
    await setDate(date)
    if (data.submitType) {
      fullValue(data.submitType)
    } else {
      handleSubmit()
    }
    // setSelectedPolicyPending({});
    // setUser()
  };

  const viewPolicyEntryPending = (element) => {
    setVisible(true);
    const el = { ...element };
    el.isViewAttr = true;
    setSelectedPolicyPending(el);
  };

  const searchSpecial = () => {
    let data = {
      searchField: special,
    };
    specialSearch(data)
      .then((res) => {
        setUser(res);
        if (user?.length > 0) {
          toast("Data Retrieved");
        } else {
          toast("No Data Found");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editPolicyEntryPending = (element) => {
    setVisible(true);
    setSelectedPolicyPending(element);
  };

  useEffect(() => {
    getAllmangerData();
    // getvalue();
    getUser({});
    getDataCompany();
  }, []);

  const onSearch = (searchText) => {
    filterPolicyPending.length
      ? setUser(
          filterPolicyPending.filter(
            (el) =>
              `${el.policyNumber},${el?.userId?.name},${el?.userId?.email},${el.customerEmail},${el.customerName},${el.RegisterationNumber},${el.odPremium},${el.netPremium},${el.tax},${el.totalPremium},${el.policyDate}`
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) !== -1
          )
        )
      : setUser(filterPolicyPending);
  };
  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setSpecial(event.target.value);
    setPolicyPending((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };

  const columns = [
    {
      title: "User ",
      dataIndex: "userId",
      key: "userId",
      width: 100,
      render: (userId) => {
        return userId?.userName
          ? userId?.userName
          : userId
          ? userId[0]?.userName
          : "";
      },
    },
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      width: 100,
      render: (companyId) => {
        return companyId?.companyName
          ? companyId?.companyName
          : companyId
          ? companyId[0]?.companyName
          : "";
        // ? companyId[0]?.companyName : ""
      },
    },
    // {
    //   title: "RTO Name",
    //   dataIndex: "rtoLocation",
    //   key: "rtoLocation",
    //   width: 100,
    //   render: (rtoLocation) => {
    //     return rtoLocation?.rtoName
    //       ? rtoLocation?.rtoName
    //       : rtoLocation
    //       ? rtoLocation[0]?.rtoName
    //       : "";
    //     // ? companyId[0]?.companyName : ""
    //   },
    // },
    {
      title: "Policy Date",
      dataIndex: "policyDate",
      key: "policyDate",
      width: 100,
      render: (_, element) => {
        return moment(element["policyDate"]).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Policy Number",
      dataIndex: "policyNumber",
      key: "policyNumber",
      width: 100,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      width: 100,
    },
    {
      title: "Registeration Number",
      dataIndex: "RegistrationNumber",
      key: "RegistrationNumber",
      width: 100,
    },
    {
      title: "OD Premium",
      dataIndex: "odPremium",
      key: "odPremium",
      width: 100,
    },
    {
      title: "TP Premium",
      dataIndex: "tpPremium",
      key: "tpPremium",
      width: 100,
    },
    {
      title: "Net Premium",
      dataIndex: "netPremium",
      key: "netPremium",
      width: 100,
    },
    // {
    //   title: "Tax Amount",
    //   dataIndex: "tax",
    //   key: "tax",
    //   width: 100,
    // },
    {
      title: "Total Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
      width: 100,
    },

    {
      title: "User Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      width: 100,
    },
    {
      title: "Customer Number ",
      dataIndex: "customerMobile",
      key: "customerMobile",
      width: 100,
    },
    // {
    //   title: "Engine Number",
    //   dataIndex: "engineNumber",
    //   key: "engineNumber",
    //   width: 100
    // },
    // {
    //   title: "Fuel Type",
    //   dataIndex: "fuelType",
    //   key: "fuelType",
    //   width: 100
    // },
    // {
    //   title: "chassis Number",
    //   dataIndex: "chassisNumber",
    //   key: "chassisNumber",
    //   width: 100
    // },
    // {
    //   title: "vehicle Subclass",
    //   dataIndex: "vehicleSubclass",
    //   key: "vehicleSubclass",
    //   width: 100
    // },
    {
      title: "Payment Mode ",
      dataIndex: "paymentMode",
      key: "paymentMode",
      width: 100,
    },
    {
      title: "Policy Status",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, element, index) => (
        <div className="actionContainer">
          <Tooltip placement="bottom" title="view">
            <EyeOutlined onClick={() => viewPolicyEntryPending(element)} />
          </Tooltip>
          {element["status"] != "approved" &&
            element["status"] != "rejected" && (
              <Tooltip placement="bottom" title="edit">
                <EditOutlined onClick={() => editPolicyEntryPending(element)} />
              </Tooltip>
            )}
        </div>
      ),
      key: "action",
      width: 100,
    },
  ];
  const DownloadFunction = (policyList) => {
    axios
      .post(
        `${baseUrl}/api/bulkPolicyDownload`,policyList,
        {
          headers: {
            "x-access-token ": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        window.open(res.data?.data?.URL);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log("error", err)
      })
  };

  return (
    /*<Sidebar
      navTitle={""}
      navMenuItems={navMenuItems}
      menuItems={menuItems}
      accountMenuItems={accountMenuItems}
    >*/
    <>
      <div className="mainContainer">
        <div className="headerContainer row">
          <h4 className="col-lg-6 col-md-6 col-xl6 col-sm-12 col-xs-12 headerTitle">
            Policy List
          </h4>
          <div className="col-lg-6 col-md-6 col-xl- 6col-sm-12 col-xs-12 d-flex justify-content-end">
            <Input
              placeholder="Policy/Registration Number"
              style={{
                height: "40px",
                width: "280px",
              }}
              onInput={(event) => searchOnChange(event)}
            ></Input>
            <Button
              style={{ height: "40px", borderRadius: "0px" }}
              onClick={searchSpecial}
            >
              Search
            </Button>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-8 col-xs-8 d-flex">
            <Space direction="vertical" size={12}>
              <RangePicker
                format="DD-MM-YYYY"
                style={{ height: "40px" }}
                onChange={onChanges}
              />
            </Space>
          </div>
          {localStorage.getItem("role") == "admin" && (
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-8 col-xs-8 d-flex">
              <Select
                showSearch
                className="w-100"
                // value={selectedManager}
                onSelect={handlemangerSelect}
                placeholder="Manager (Branch)"
              >
                <Select.Option value="all" key="1001">
                  All
                </Select.Option>
                {managerList?.length > 0 &&
                  managerList.map((ele) => {
                    return (
                      <Select.Option
                        value={ele.branch.branchName}
                        key={ele.branch._id}
                      >
                        {ele.userName}({ele.branch.branchName})
                      </Select.Option>
                    );
                  })}
              </Select>
            </div>
          )}
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-8 col-xs-8 d-flex">
            <Select
              showSearch
              className="w-100"
              value={userName}
              onSelect={getUserName}
              placeholder="User"
            >
              <Select.Option value="all" key="1001">
                All
              </Select.Option>
              {userr?.length > 0 &&
                userr.map((ele) => {
                  return (
                    <Select.Option value={ele.userName} key={ele._id}>
                      {ele.userName} ({ele.branch.branchName})
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-8 col-xs-8 d-flex">
            <Select
              showSearch
              className="w-100"
              value={getCompany}
              onSelect={getCompanyName}
              placeholder="Company Name"
            >
              <Select.Option value="all" key="1001">
                All
              </Select.Option>
              {dataCompany?.length > 0 &&
                dataCompany.map((ele) => {
                  return (
                    <Select.Option value={ele.companyName} key={ele._id}>
                      {ele.companyName}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
        </div>
        <br></br>
        {/* <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12 col-xs-12 d-flex left">
        <Button
            className="reportAllbutton"
            onClick={() => {
              fullValue("entry pending");
            }}
          >
            Entry Pending
          </Button>
          <br></br>
          <Button
            className="reportAllbutton"
            onClick={() => {
              fullValue("pending");
            }}
          >
            Pending
          </Button>
          
          
          <Button className="reportAllbutton" onClick={handleSubmit}>
            Submit
          </Button>
        </div> */}
        <div className="row">
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
            <Button className="w-100 reportAllbutton" onClick={async() => {
              await setSubmitType()
              handleSubmit()
            }
            }>
              All
            </Button>
          </div>
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
            <Button
              className="w-100 reportAllbutton"
              onClick={async () => {
                await setSubmitType("entry pending")
                fullValue("entry pending");
              }}
            >
              Entry Pending
            </Button>
          </div>
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
            <Button
              className="w-100 reportAllbutton"
              onClick={async() => {
                await setSubmitType("pending")
                fullValue("pending");
              }}
            >
              Pending
            </Button>
          </div>
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
            <Button
              className="w-100 reportAllbutton"
              onClick={async() => {
                await setSubmitType("approved")
                fullValue("approved");
              }}
            >
              Approved
            </Button>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
            <Button
              className="w-100 reportAllbutton"
              onClick={() => {
                setSubmitType("rejected")
                fullValue("rejected");
              }}
            >
              Rejected
            </Button>
          </div>
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
            <Button className="w-100 csvButton">
              <CSVLink className="csv" {...csvFile}>
                Export All File
              </CSVLink>
            </Button>
          </div>
          {/* {
            submitType=='approved' && 
            (
              <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
                <Button className="w-100 csvButton" onClick={() => { DownloadFunction(user) }}>
                    Download All File
                </Button>
              </div>
            )
          } */}
        </div>
        <div className="tableContainer mt-4">
          <Table
            columns={columns}
            dataSource={user}
            // scroll={{
            //   x: "90vw",
            // }}
            pagination={{
              pageSize: policyPending.pagination.pageSize || 10,
              showQuickJumper: true,
              responsive: true,
              total: policyPending.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />

          <Drawer
            title={"UserPolicy"}
            className="drawer-dropDown"
            onClose={() => setVisible(false)}
            visible={visible}
            bodyStyle={{ padding: 0 }}
            size={"large"}
          >
            {visible ? (
              <AddPolicyEntryPending
                backToDashBoard={backToDashBoard}
                selectedPolicyPending={selectedPolicyPending}
                getvalue={getvalue}
                backtoValue={backtoValue}
                formType={
                  selectedPolicyPending.isViewAttr
                    ? "view"
                    : selectedPolicyPending._id
                    ? "edit"
                    : "add"
                }
              />
            ) : null}
          </Drawer>
        </div>
        <ToastContainer />
      </div>
    </>
    //</Sidebar>
  );
};

export default PolicyEntryPending;
