import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

export default function CollapsibleMenu({
  menuItem = {
    icon: null,
    children: [],
    defaultCollapsed: true,
  },
  expanded,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [collapsed, setCollapsed] = React.useState(menuItem.defaultCollapsed);
  return (
    <div>
      <Accordion
        expanded={collapsed}
        sx={{ border: "none", outline: "none", boxShadow: "none" }}
      >
        <AccordionSummary
          expandIcon={expanded && <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ px: 2.5 }}
          onClick={() => setCollapsed(!collapsed)}
        >
          <Stack
            spacing={1}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            {...(!expanded ? { px: 2.5 } : {})}
          >
            {menuItem.icon}
            {expanded && (
              <Typography sx={{ pl: 2 }}> {menuItem.name}</Typography>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={open ? { padding: 0 } : {}}>
          {(menuItem.children || []).map((menu, index) => (
            <ListItem key={menu.name} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px:{xs:!expanded ? 2 : 4, md: !expanded ? 2.5 : 4}
                }}
                onClick={()=>navigate(menu.path)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {menu.icon}
                </ListItemIcon>
                <ListItemText
                  primary={menu.name}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
