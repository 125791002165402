import React, { forwardRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />;
});

const ConfirmBox = ({ open, closeDialog, Function, tittle , Content , heading , confirmButton }) => {

    return (
        <div>
            <Dialog
                open={open}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
            >
                <IconButton
                    aria-label="close"
                    onClick={closeDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[800],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle id="alert-dialog-title">
                    {heading}
                </DialogTitle>
                <DialogContent sx={{ marginTop: "2px" }}
                >
                    <DialogContentText id="alert-dialog-description">
                        <span>{Content}</span> <span style={{ fontWeight: "400", color: "red", textTransform: "capitalize" }}></span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={Function} autoFocus varient="outlined" color="warning">
                        {confirmButton}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default ConfirmBox;