import React, { useState, useEffect } from "react";
import { Table, Input, DatePicker, Space, Button, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import { walletDateFilter } from '../../utils/servies/common'
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { profileGet } from "../../utils/servies/common";
import { walletSearchPolicy as policyViewPost } from "../../utils/servies/common";
import { CSVLink } from "react-csv";
const ManageWallet = () => {
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });
  const [date, setDate] = useState()
  const [data, setData] = useState()
  const [userData, setUserData] = useState()
  const { RangePicker } = DatePicker;
  const [filterWallet, setFilterwallet] = useState([]);
  const [csvData,setCsvData] = useState()

  const onSearch = async (searchText) => {
    await filterWallet.length
      ? setUserData(
        filterWallet.filter(
          (el) =>
            `${el.customerName},${el.customerEmail},${el.policyNumber}`
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
        )
      )
      : setUserData(filterWallet);
    var pushdata=[]
    await userData.map(ele => {
      ele.policyNumber = `'` + ele.policyNumber
      ele.policyDate=moment(ele["policyDate"]).format("DD-MMM-YYYY")
      pushdata.push(ele)
    })
    setCsvData(pushdata)
  };

  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setConfiguration((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };
  const onChange = (value, dateString) => {
    setDate(value)
  }

  const rangePicker = async() => {
    if (date) {
      let sendData = {
        startDate: date[0],
        endDate: date[1],
        searchField: {
          status: "approved"
        }
      }
      // console.log(sendData, date)
      policyViewPost(sendData)
        .then(async(res) => {
          setUserData(res.data)
          setFilterwallet(res.data)
          if (res.data.length) {
            toast("Successfully Retrieved");
            var pushdata=[]
            await res.data.map(ele => {
              ele.policyNumber = `'` + ele.policyNumber
              ele.policyDate=moment(ele["policyDate"]).format("DD-MMM-YYYY")
              pushdata.push(ele)
            })
            setCsvData(pushdata)
          } else {
            setCsvData([])
            toast("No data Retrieved");
          }
        })
        .catch((error) => {
          toast.error("Something Went Wrong")
        })
    }
    else {
      toast.warning("Please Select Date")
    }
  }
  useEffect(() => {
    profileGet().then((res) => {
      setData(res.data.walletBalance)
    }).catch((err) => {
      console.log(err)
    })
  }, [])
  const columns = [
    {
      title: "Approved Date",
      dataIndex: "approvedDate",
      key: "approvedDate",
      render: (_, element) => {
        return moment(element["approvedDate"]).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Policy Date",
      dataIndex: "policyDate",
      key: "policyDate",
      render: (_, element) => {
        return moment(element["policyDate"]).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Policy Number",
      dataIndex: "policyNumber",
      key: "policyNumber",
    },
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      width: 100,
      render: (companyId) => {
        return (companyId?.companyName ? companyId?.companyName : companyId ? companyId[0]?.companyName : ""
          // ? companyId[0]?.companyName : ""
        );
      },
    },
    {
      title: "Registration Number",
      dataIndex: "RegistrationNumber",
      key: "RegistrationNumber",
    },
    {
      title: "Total Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
    },
    {
      title: "Cash Mode Amount",
      dataIndex: "CashModeAmount",
      key: "CashModeAmount",
    },
    {
      title: "Agent Share",
      dataIndex: "agentShare",
      key: "agentShare",
      render: (agentShare => {
        return (agentShare ? Math.round(agentShare) : 0);
      })
    },
    {
      title: "Remarks",
      dataIndex: "paidAmountRemarks",
      key: "paidAmountRemarks",
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
    },
    {
      title: "Available Balance",
      dataIndex: "walltetBalance",
      key: "walltetBalance",
    },
  ];
  const headers = [
    { label: 'Approved Date', key: "approvedDate" },
    { label: 'Policy Date', key: "policyDate" },
    { label: 'Policy Number', key: "policyNumber" },
    { label: 'Company Name', key: "companyId.companyName" },
    { label: "Agent Name", key: "userId.name" },
    { label: 'Registration Number', key: "RegistrationNumber" },
    { label: 'Od Premium', key: "odPremium" },
    { label: 'TP Premium', key: "tpPremium" },
    { label: 'Net Premium', key: "netPremium" },
    { label: 'Total Premium', key: "totalPremium" },
    { label: "Payment Mode", key: "paymentMode" },
    { label: "Cash Mode Amount", key: "CashModeAmount" },
    { label: "Agent Share", key: "agentShare" },
    { label: "Paid Amount", key: "paidAmount" },
    { label: "Remarks", key: "paidAmountRemarks"},
    { label: "Available Balance", key: "walltetBalance" }
  ]
  const csvFile = {
    filename: "Wallet.csv",
    headers: headers,
    data: (csvData) ? csvData : ""
  }
  return (
    <>
      <div className="mainContainer">
        <div className="headerContainer row">
          <h4 className="col-lg-1 col-md-1 col-xl-1 col-sm-12 col-xs-12 headerTitle">
            Wallet
          </h4>

          <div className="col-lg-11 col-md-11 col-xl-11 col-sm-12 col-xs-12">
            <Space
              direction="vertical"
              style={{ marginLeft: "18px" }}
              size={12}
            >
              <RangePicker
                format="DD-MM-YYYY"
                style={{ padding: "8px" }}
                onChange={onChange}
              />
            </Space>
            <Button size="large" className="button" style={{ marginLeft: "4px" }} onClick={rangePicker}>
              Wallet
            </Button>
            &nbsp;&nbsp;

            <Input
              placeholder="Search"
              style={{
                height: "40px",
                width: "280px",
              }}
              onInput={(event) => searchOnChange(event)}
            ></Input>
            <SearchOutlined className="searchSymbolWallet" />
            &nbsp;&nbsp;
            <Button className="csvButton">
              <CSVLink className="csv" {...csvFile}>Export All File</CSVLink>
            </Button>
          </div>
        </div>

        <Typography style={{
          marginTop: "40px", marginBottom: "20px", fontWeight: "400", fontSize: "20px"
        }}>Wallet Balance : {data}</Typography>
        <br></br>
        <div className="tableContainer">
          <Table
            columns={columns}
            dataSource={userData}
            scroll={{
              x: "5vw",
            }}
            pagination={{
              pageSize: configuration.pagination.pageSize || 10,
              showQuickJumper: true,
              responsive: true,
              total: configuration.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}
          />
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default ManageWallet;
