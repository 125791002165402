import React, { useState, useEffect } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button } from "antd";
import { FloatLabel } from "../../UIComponent/_index";
import { Select } from "antd";
import {
  companyContactPost,
  companyLoginIdGet,
  companyLoginCompanyCodeGet,
  companyLoginBranchGet,
  companyContactUpdate,
  userConfigBookingCode
} from "../../utils/servies/common";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from "../../Resources/Loader/Loader";
const { Option } = Select;
let value = {
  companyId: "",
  companyCodeId: "",
  name: "",
  email: "",
  mobile: "",
  branch: "",
  designation: "",
};
const AddCompanyContact = (props) => {
  const { formType, getData, selectedcompanyContact, backToDashboard } = props;

  let status = formType === "edit" ? selectedcompanyContact : value;

  const [data, setData] = useState(status);
  const [saveCompany, setSaveCompany] = useState(status?.companyId?._id);
  const [company, setCompany] = useState();
  const [mapcompanyCode, setMapCompanyCode] = useState();
  const [saveCompanyCode, setSaveCompanyCode] = useState(
    status?.companyCodeId?._id
  );
  const [mapBranch, setMapBranch] = useState();
  const [saveBranch, setSaveBranch] = useState(status?.branch?._id);
  const [open,setOpen] = useState(false)
  const nameCompany = () => {
    companyLoginIdGet()
      .then((res) => {
        setCompany(res.data);
      })
      .catch((err) => console.log(err));
  };

  const codeCompany = () => {
    companyLoginCompanyCodeGet()
      .then((res) => {
        setMapCompanyCode(res.data);
      })
      .catch((err) => console.log(err));
  };
  const codeCompanyById = (value) => {
    userConfigBookingCode(value).then((res) => {
      setMapCompanyCode(res.data)
    })
      .catch((err) => console.log(err));
  };

  const nameBranch = () => {
    companyLoginBranchGet()
      .then((res) => {
        setMapBranch(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    nameCompany();
    codeCompany();
    nameBranch();
  }, []);

  const handleSave = () => {
    formType === "edit" ? handleUpdate() : handleSubmit();
  };

  const handleSubmit = () => {
    if (saveCompany && saveCompanyCode && data.name && data.email && data.mobile && saveBranch && data.designation) {

      var postValue = {
        companyId: saveCompany,
        companyCodeId: saveCompanyCode,
        name: data?.name,
        email: data?.email,
        mobile: data?.mobile,
        designation: data?.designation,
      };
      if (saveBranch!="All") {
        postValue.branch= saveBranch
      }
      setOpen(true)
      companyContactPost(postValue).then(() => {
        toast.success("Successfully Saved")
        getData()
        backToDashboard()
        setOpen(false)
      }).catch(() => {
        toast.error("Something Went Wrong")
        backToDashboard()
        setOpen(false)
      })
    }
    else {
      toast.warning("Please Enter All Details")
    }
  };

  const handleUpdate = async () => {
    if (saveCompany && saveCompanyCode && data.name && data.email && data.mobile && saveBranch && data.designation) {
      let postValue = {
        _id: data?._id,
        companyId: saveCompany,
        companyCodeId: saveCompanyCode,
        name: data?.name,
        email: data?.email,
        mobile: data?.mobile,
        branch: saveBranch,
        designation: data?.designation,
      };
      setOpen(true)
      await companyContactUpdate(postValue, data?._id).then(() => {
        toast("Successfully Updated")
        getData()
        backToDashboard()
        setOpen(false)
      }).catch(() => {
        toast.error("Something Went Wrong")
        backToDashboard()
        setOpen(false)
      })
    }
    else {
      toast.warning("Please Enter All Details")
    }
  };

  const handleCancel = () => {
    backToDashboard();
  }
  return (
    <>
      <div className="formContainer h-100">
        <OverlayScrollbarsComponent className="formBody">
          <Formik>
            {({ values }) => (
              <Form noValidate>
                <div className="innerContainer row">
                  <div className="dropDownContainer col-12 mb-2 ">
                    <FloatLabel label="Company Name" value={saveCompany}>
                      <Select
                        className="w-100"
                        value={saveCompany}
                        onSelect={(e) => {
                          setSaveCompany(e);
                          codeCompanyById(e)
                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        {company?.length > 0 &&
                          company.map((ele) => {
                            return (
                              <Option value={ele._id} key={ele._id}>{ele.companyName}</Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="Company Code" value={saveCompanyCode}>
                      <Select
                        className="w-100 "
                        value={saveCompanyCode}
                        onSelect={(e) => {
                          setSaveCompanyCode(e);
                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        {mapcompanyCode?.length > 0 &&
                          mapcompanyCode.map((ele) => {
                            return (
                              <Option value={ele._id} key={ele._id}>{ele.companyCode}</Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="Name" value={data?.name}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.name}
                        onChange={(e) => {
                          setData({ ...data, name: e.target.value });
                        }}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="Email" value={data.email}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.email}
                        onChange={(e) => {
                          setData({ ...data, email: e.target.value });
                        }}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="Mobile" value={data?.mobile}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.mobile}
                        onChange={(e) => {
                          setData({ ...data, mobile: e.target.value });
                        }}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="Branch" value={saveBranch}>
                      <Select
                        className="w-100 "
                        value={saveBranch}
                        onSelect={(e) => {
                          setSaveBranch(e);
                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        <Option value="All" key="All">All</Option>
                        {mapBranch?.length > 0 &&
                          mapBranch.map((ele) => {
                            return (
                              <Option value={ele._id} key={ele._id}>{ele.branchName}</Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="Designation" value={data?.designation}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.designation}
                        onChange={(e) => {
                          setData({ ...data, designation: e.target.value });
                        }}
                      />
                    </FloatLabel>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OverlayScrollbarsComponent>
        <ToastContainer zIndex={1000000} />
      </div>
      <footer>
        <Button className="button transButton" onClick={handleCancel} >Cancel</Button>
        <Button className="button" onClick={handleSave}>
          {formType === "edit" ? "Update" : "Save"}
        </Button>
      </footer>
      <Loader open={open} />
    </>
  );
};
export default AddCompanyContact;
