import React, { useEffect, useRef } from "react";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {
  accountMenuItems,
  menuItems,
  navMenuItems,
} from "../../Constants/LayoutCommon";
import { Input, Select, DatePicker } from "antd";
import { Typography } from "@mui/material";
import { policyCreateCompanyGet } from "../../utils/servies/common";
import { policyCreateUserGet, getAllUsersDropDown } from "../../utils/servies/common";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Stack } from "@mui/material";
import { branchapi } from "../../utils/servies/common";
import {baseUrl} from "../../Constants/constants"



const url = `${baseUrl}/api/admin/createpolicy`

const AddPolicCreate = (formType) => {
  const inputRef = useRef(null);
  const [data, setData] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [visible, setVisible] = useState();
  const [size, setSize] = useState();
  const [company, setCompany] = useState();
  const [user, setUser] = useState();
  const [userName, setUserName] = useState();
  const [companyName, setCompanyName] = useState();
  const [savePayment, setSavePayment] = useState();
  const [chequeDate, setChequeDate] = useState();
  const [loader, setLoader] = useState(false);
  const [branch, setbranch] = useState()
  const [saveBranch, setSaveBranch] = useState()

  const paymentChange = (e) => {
    setVisible(e);
    setSavePayment(e);
  };
  const Role = localStorage.getItem('role')
  const getUser = () => {
    let value = {
      branch: saveBranch,
      role:"agent"
    }
    getAllUsersDropDown(value).then((res) => {
      setUser(res.data);
    });
  };
  const getbranch = () => {
    branchapi().then((res) => {
      setbranch(res.data)
    })
  }
  const getUserName = (event, key) => {
    setUserName(key);
  };
  const getBranchName = (e) => {
    setSaveBranch(e)
    console.log(e)
  }

  const getCompanyName = () => {
    policyCreateCompanyGet()
      .then((res) => {
        setCompany(res.data);
      })
      .catch((err) => console.log(err));
  };

  const postCompanyName = (event, key) => {
    setCompanyName(key);
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onDateChange = (e) => {
    setSize(e.target.value);
  };

  const dateCheque = (date, dateString) => {
    setChequeDate(dateString);
  };
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "x-access-token ": localStorage.getItem("token"),
    },
  };
  const handleSubmit = async () => {
    console.log(new Date(size))
    setLoader(true);
    let formData = new FormData();
    if (
      data?.poilcyNumber &&
      companyName &&
      selectedFile &&
      size &&
      userName &&
      data?.totalPremium &&
      savePayment
    ) {
      formData.append("policyNumber", data?.poilcyNumber);
      formData.append("companyId", companyName.key);
      formData.append("policyFile", selectedFile);
      formData.append("policyDate", new Date(size));
      formData.append("userId", userName.key);
      formData.append("totalPremium", data?.totalPremium);
      formData.append("paymentMode", savePayment);

      if (savePayment === "cash") {
        if (data.amount) {
          formData.append("CashModeAmount", data?.amount);
          axios
            .post(url, formData, config)
            .then((res) => {
              setLoader(false);
              if (res.data.message) {
                toast.warning(res?.data?.message);
              } else {
                toast.success("Successfully Submitted");
              }
              setUserName();
              setData();
              setSelectedFile();
              setSize('');
              setCompanyName();
              setSavePayment();
              setChequeDate();
              inputRef.current.value = null;
            })
            .catch(() => {
              toast.error("Something Went Wrong");
            });
        } else {
          setLoader(false);
          toast.warning("Please Enter amount");
        }
      }
      // if (savePayment === "cheque") {
      //   if (data?.chequeNumber && chequeDate && data?.bankName) {
      //     formData.append("chequeNumber", data?.chequeNumber);
      //     formData.append("chequeDate", chequeDate);
      //     formData.append("chequeBank", data?.bankName);

      //     axios
      //       .post(url, formData, config)
      //       .then((res) => {

      //         toast(res?.data?.message)
      //         setUserName()
      //         setData()
      //         setSelectedFile()
      //         setSize()
      //         setCompanyName()
      //         setSavePayment()
      //         setChequeDate()
      //         inputRef.current.value = null
      //       })
      //       .catch(() => {
      //         toast.error("Something Went Wrong")
      //       });

      //   }
      //   else {
      //     toast.error("Please Enter Payment Details")
      //   }

      // }
      else {
        axios
          .post(url, formData, config)
          .then((res) => {
            setLoader(false);
            if (res.data.message) {
              toast.warning(res?.data?.message);
            } else {
              toast.success("Successfully Submitted");
            }
            setData();
            setSelectedFile();
            setSize('');
            setUserName();
            setCompanyName();
            setSavePayment();
            inputRef.current.value = null;
          })
          .catch(() => {
            setLoader(false);
            toast.error("Something Went Wrong");
          });
      }
    } else {
      setLoader(false);
      toast.warning("Please Enter All Details");
    }
  };

  useEffect(() => {
    getCompanyName();
    getUser();
    getbranch()
  }, [saveBranch]);
  const { Option } = Select;

  return (
    /*<Sidebar
            navTitle={""}
            navMenuItems={navMenuItems}
            menuItems={menuItems}
            accountMenuItems={accountMenuItems}
        >*/

    <>
      <div className="row ">
        <h4>Policy Create</h4>
        <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2" hidden={Role === "manager"}>
          <div className="d-flex" >
            <Typography >Branch </Typography>&nbsp;
            <span style={{ color: "red", fontSize: "22px" }}>*</span>
          </div>
          <Select

            showSearch
            className="w-100"
            value={saveBranch}
            onSelect={getBranchName}
          >
            <Select.Option>
              All
            </Select.Option>
            {branch?.length > 0 &&
              branch.map((ele) => {
                return <Select.Option value={ele._id}>{ele.branchName}</Select.Option>
              })}
          </Select>
        </div>
        <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2">
          <div className="d-flex">
            <Typography>User </Typography>&nbsp;
            <span style={{ color: "red", fontSize: "22px" }}>*</span>
          </div>
          <Select
            showSearch
            className="w-100"
            value={userName}
            onSelect={getUserName}
          >
            {user?.length > 0 &&
              user.map((ele) => {
                return <Select.Option value={ele.userName} key={ele._id}>{ele.userName}</Select.Option>;
              })}
          </Select>
        </div>
      </div>
      <hr></hr>
      <div className="row mt-4">
        <h4>Policy Details</h4>
        <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2">
          <div className="d-flex">
            <Typography>Policy Number</Typography>&nbsp;
            <span style={{ color: "red", fontSize: "22px" }}>*</span>
          </div>
          <Input
            style={{ height: 40 }}
            className="w-100"
            value={data?.poilcyNumber}
            onKeyDown={(event) => {
              if (event.key === ' ') {
                event.preventDefault()
              }
            }}
            onChange={(e) => {
              let regex = /[^\w\s/-]/g;
              const matches = e.target.value.match(regex);
              if (matches) {
                toast.warning("Special Characters are not valid")
              } else {
                setData({ ...data, poilcyNumber: e.target.value })
              }
            }
            }
          />
        </div>
        <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2">
          <div className="d-flex">
            <Typography>Policy Date </Typography>&nbsp;
            <span style={{ color: "red", fontSize: "22px" }}>*</span>
          </div>
          {/* <DatePicker
            name="paymentDate"
            className="w-100"
            onChange={onChange}
            style={{ height: 40 }}
            dateRender={(current) => {
              const style = {};
              // if (current.date() === 1) {
              //   style.border = "1px solid #1890ff";
              //   style.borderRadius = "50%";
              // }
              return (
                <div className="ant-picker-cell-inner" style={style}>
                  {current.date()}
                </div>
              );
            }}
          /> */}
          <Input
            type='date'
            name="paymentDate"
            className="w-100"
            placeholder=""
            onChange={onDateChange}
            style={{ height: 40 }}
            value={size}
          />
        </div>
        <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2">
          <div className="d-flex">
            <Typography>Company Name</Typography>&nbsp;
            <span style={{ color: "red", fontSize: "22px" }}>*</span>
          </div>
          <Select
            showSearch
            className="w-100"
            value={companyName}
            onSelect={postCompanyName}
          >
            {company?.length > 0 &&
              company.map((ele) => {
                return <Option value={ele.companyName} key={ele._id}>{ele.companyName}</Option>;
              })}
          </Select>
        </div>
      </div>
      <hr></hr>
      <div className="row mt-4">
        <h4>Premium details</h4>
        <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2">
          <div className="d-flex">
            <Typography>Total Premium </Typography>&nbsp;
            <span style={{ color: "red", fontSize: "22px" }}>*</span>
          </div>
          <Input
            style={{ height: 40 }}
            className="w-100"
            value={data?.totalPremium}
            onChange={(e) => setData({ ...data, totalPremium: e.target.value })}
          />
        </div>
        {/* <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2">
          <div className="d-flex">
            <Typography>Agent Note </Typography>&nbsp;
            {/* <span style={{ color: "red", fontSize: "22px" }}>*</span> */}
          {/* </div>
          <Input
            style={{ height: 40 }}
            className="w-100"
            value={data?.agentNote}
            onChange={(e) => setData({ ...data, agentNote: e.target.value })}
          />
        </div>  */}
      </div>
      <hr></hr>
      <div className="row mt-4">
        <h4>Payment Details</h4>
        <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2">
          <div className="d-flex">
            <Typography>Payment Mode</Typography>&nbsp;
            <span style={{ color: "red", fontSize: "22px" }}>*</span>
          </div>
          <Select
            className="w-100"
            onSelect={paymentChange}
            value={savePayment}
          >
            <option value="cash">Cash</option>
            <option value="online">Online</option>
            {/* <option value="cheque">Cheque</option> */}
          </Select>
        </div>
        {visible === "cash" && (
          <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2">
            <div className="d-flex">
              <Typography>Amount</Typography>&nbsp;
              <span style={{ color: "red", fontSize: "22px" }}>*</span>
            </div>
            <Input
              style={{ height: 40 }}
              className="w-100"
              placeholder=" Cash"
              value={data?.amount}
              onChange={(e) => setData({ ...data, amount: e.target.value })}
            />
          </div>
        )}
        {visible === "cheque" ? (
          <>
            <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2">
              <div className="d-flex">
                <Typography>Cheque Number</Typography>&nbsp;
                <span style={{ color: "red", fontSize: "22px" }}>*</span>
              </div>
              <Input
                style={{ height: 40 }}
                className="w-100"
                value={data?.chequeNumber}
                onChange={(e) =>
                  setData({ ...data, chequeNumber: e.target.value })
                }
              />
            </div>
            <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2">
              <div className="d-flex">
                <Typography>Cheque Date</Typography>&nbsp;
                <span style={{ color: "red", fontSize: "22px" }}>*</span>
              </div>
              <DatePicker
                name="paymentDate"
                className="w-100"
                onChange={dateCheque}
                style={{ height: 40 }}
                dateRender={(current) => {
                  const style = {};
                  if (current.date() === 1) {
                    style.border = "1px solid #1890ff";
                    style.borderRadius = "50%";
                  }
                  return (
                    <div className="ant-picker-cell-inner" style={style}>
                      {current.date()}
                    </div>
                  );
                }}
              />
            </div>
            <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2">
              <div className="d-flex">
                <Typography>Bank Name</Typography>&nbsp;
                <span style={{ color: "red", fontSize: "22px" }}>*</span>
              </div>
              <Input
                style={{ height: 40 }}
                className="w-100"
                value={data?.bankName}
                onChange={(e) => setData({ ...data, bankName: e.target.value })}
              />
            </div>
          </>
        ) : null}
      </div>
      <hr></hr>
      <div className="file-container">
        <div className="file-upload">
          <input
            type="file"
            name="file"
            ref={inputRef}
            onChange={changeHandler}
          />{" "}
          &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
          <button
            style={{
              padding: "6px 36px",
              outline: "none",
              border: "none",
              backgroundColor: "#1976d2",
              color: "white",
              fontWeight: "600",
            }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
        <ToastContainer />
      </div>
      {loader ? (
        <Stack
          style={{
            position: "fixed",
            right: "50%",
            bottom: "50%",
          }}
        >
          <div class="loader">Loading
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Stack>
      ) : null}
    </>
    //</Sidebar>
  );
};

export default AddPolicCreate;
