import React, { useEffect, useState } from "react";
import { Table, Tooltip, Input, DatePicker, Button, Select } from "antd";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Buttons from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { policyCreateUserGet } from "../../utils/servies/common";
import { createPayment } from "../../utils/servies/common";
import Transaction from "../Transaction/Transaction";
import { Stack } from "@mui/material";

const Payout = () => {

  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });
  const { RangePicker } = DatePicker;
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState()
  const [value, setvalue] = useState({})
  const [saveUserName, setSaveUserName] = useState()
  const [amount, setAmount] = useState()
  const [showTable, setShowtable] = useState()
  const [loader, setLoader] = useState(false);

  const role = localStorage.getItem('role')
  const { Option } = Select
  const ShowFunction = (element) => {
    setShowtable(element)
    setOpen(true)
  };
  const getData = () => {
    policyCreateUserGet().then((res) => {
      setData(res.data)
    }).catch((error) => {
      console.log(error)
    })
  }
  const handlePayment = async () => {
    // let amountPaid = parseFloat(amount?.paidAmount) ;
    setLoader(true);
    // if (amount?.paidAmount >= 0) {
      let dataa = {
        paidAmount: amount?.paidAmount,
        userId: showTable?._id,
        paidAmountRemarks: amount?.paidAmountRemarks
      }
      await createPayment(dataa).then((res) => {
        toast(res.message)
        getData()
      }).catch((error) => {
        toast(error.message)
      })
      setLoader(false);
      setOpen(false)
    // }
    // else {
    //   setLoader(false);
    //   toast.warning("Please Enter Positive Number")
    // }
  }

  useEffect(() => {
    getData()
  }, [])

  const handleUser = (e) => {
    setvalue(e)
    setSaveUserName(
      data?.find((ele) => {
        return ele?.userName === e
      }))
  }

  const chnagePath = (element) => {
    console.log(element)
    if (role == "admin") {
      if (element) {
        if (element['role'] == "manager") {
          Navigate('/manager/transaction', { state: element._id })
        } else {
          Navigate("/transaction", { state: element._id })
        }
      }
      else {
        toast.warning("Please Select User Name ")
      }
    } else {
      if (element) {
        if (role == "manager") {
          Navigate('/manager/transaction', { state: element._id })
        } else {
          Navigate("/transaction", { state: element._id })
        }
      }
      else {
        toast.warning("Please Select User Name ")
      }
    }
  }


  const columns = [
    {
      title: " Name",
      dataIndex: "userName",
      key: "userName",
      width:200
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      width: 200
    },
    {
      title: "E-Mail",
      dataIndex: "email",
      key: "email",
      width: 200
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      width: 200,
      render: (branch) => {
        return branch?.branchName

      }
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role) => {
        return role.toUpperCase()
      }
    },
    {
      title: "Available Balance",
      dataIndex: "walletBalance",
      key: "walletBalance",
      render: (walletBalance) => {
        return walletBalance.toFixed(2)

      }
    },
    {
      title: 'Active',
      dataIndex: 'isUserActive',
      key: 'isUserActive',
      width: 200,
      render: (isUserActive, element) => {
        return (
          isUserActive ? <Button style={{ backgroundColor: "#23f75b", borderRadius: "4px", textAlign: "center", padding: "4px 4px", marginTop: "16px", color: "white" }}
          // onClick={() => activeUser(element, "deactive")}
          >Active</Button> :
            <Button style={{ backgroundColor: "#ff3030", borderRadius: "4px", textAlign: "center", padding: "4px 4px", marginTop: "16px", color: "white" }}
            // onClick={() => activeUser(element, "active")}
            >DeActive</Button>
        )
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (_, element) => (
        <div className="actionContainer">
          <Tooltip placement="bottom" title="view transaction">
            <EyeOutlined onClick={() => chnagePath(element)} />
          </Tooltip>
          {(localStorage.getItem('role') == 'manager' && element['role'] == 'agent') &&
            <Button onClick={() => ShowFunction(element)} style={{ borderRadius: "0px" }}>Pay</Button>
          }
          {(localStorage.getItem('role') == 'admin' && element['role'] == 'manager') &&
            <Button onClick={() => ShowFunction(element)} style={{ borderRadius: "0px" }}>Pay</Button>
          }
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="mainContainer">
        <div className="headerContainer row">
          <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
            Payout
          </h4>
          <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
            <Select
              name="role"
              allowClear
              placeholder="User Name"
              showSearch
              style={{ width: "350px" }}
              onSelect={(e) => { handleUser(e) }}
            >{data?.map((ele) => {
              return (
                <Option value={ele?.userName} key={ele?.userName} >{ele?.userName}({ele.role})</Option>
              )
            })}
            </Select>
          </div>
        </div>

        <div className="tableContainer" style={{ marginTop: "40px" }}>
          <Table
            columns={columns}
            dataSource={(saveUserName) ? [saveUserName] : data}
         
            pagination={{
              pageSize: configuration.pagination.pageSize || 10,
              showQuickJumper: true,
              responsive: true,
              total: configuration.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}
          />
        </div>

        {/* confirm box */}

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[800],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle id="alert-dialog-title">Show</DialogTitle>
          <DialogContent sx={{ marginTop: "2px" }}>
            <DialogContentText id="alert-dialog-description">
              <Input
                value={showTable?.name}

                disabled
                style={{ marginBottom: "10px" }}

              />
              <Input value={showTable?.walletBalance.toFixed(2)} disabled style={{ marginBottom: "10px" }} />
              <Input
                placeholder="Enter The Remarks"
                onChange={(e) => setAmount({ ...amount, paidAmountRemarks: e.target.value })} style={{ marginBottom: "10px" }} />
              <Input
                placeholder="Enter The Amount"
                type="text"
                min="0"
                step="1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key) && !event.key.includes(".") && !event.key.includes("-")) {
                    event.preventDefault();
                  }
                }}

                onChange={(e) => {
                  if (!isNaN(e.target.value)){
                    setAmount({ ...amount, paidAmount: e.target.value })
                  }
                }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons onClick={() => setOpen(false)}>Cancel</Buttons>
            {!loader &&
              <Buttons autoFocus varient="outlined" color="warning" onClick={handlePayment}>
                Confirm
              </Buttons>
            }

          </DialogActions>

        </Dialog>
        <ToastContainer />
        {loader ? (
          <Stack
            style={{
              position: "fixed",
              right: "50%",
              bottom: "50%",
            }}
          >
            <div class="loader">Loading
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Stack>
        ) : null}
      </div>
    </>
  );
};

export default Payout;
