import React, { useState, useEffect } from "react";
import Sidebar from "../../../../Common/Sidebar/Sidebar";
import {
  accountMenuItems,
  menuItems,
  navMenuItems,
} from "../../../../Constants/LayoutCommon";
import AddCompany from "./AddCompany";
import { Button, Drawer, Table, Tooltip, Input } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { policyCreateCompanyGet } from "../../../../utils/servies/common";
import ConfirmBox from "../../../../UIComponent/ConfirmBox";
import { CompanyDelete } from "../../../../utils/servies/common";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManageCompany = () => {
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });
  const [selectedUserCompany, setSelectedUserCompany] = useState({});
  const [visible, setVisible] = useState(false);
  const [data, setdata] = useState();
  const [filterCompany, setFilterCompany] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteCompany, setDeleteCompany] = useState({});

  const getData = () => {
    policyCreateCompanyGet()
      .then((res) => {
        setFilterCompany(res.data);
        setdata(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getData();
  }, []);

  const addUserCompany = () => {
    setVisible(true);
    setSelectedUserCompany({});
  };

  const backToDashBoard = () => {
    setVisible(false);
    setSelectedUserCompany({});
  };

  const editUserCompany = (element) => {
    setVisible(true);
    setSelectedUserCompany(element);
  };

  const deleteUserCompany = (element) => {
    setOpen(true);
    setDeleteCompany(element);
  };
  const deleteFunction = async () => {
    await CompanyDelete(deleteCompany._id)
      .then((res) => {
        toast.success("deleted Successfully");
        getData();
        setOpen(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });
  };

  const onSerach = (serachText) => {
    filterCompany.length
      ? setdata(
        filterCompany.filter(
          (el) =>
            `${el.companyName}${el.description}`
              .toLowerCase()
              .indexOf(serachText.toLowerCase()) !== -1
        )
      )
      : setdata(filterCompany);
  };

  const serachOnChange = (event) => {
    onSerach(event.target.value);
    setConfiguration((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      width: 100,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, element) => {
        return moment(element["createdAt"]).format("DD-MMM-YYYY");
      },
      width: 100,
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 100,
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, element, index) => (
        <div className="actionContainer">
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined onClick={() => editUserCompany(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="Edit">
            <DeleteOutlined onClick={() => deleteUserCompany(element)} />
          </Tooltip>
        </div>
      ),
      key: "action",
      width: 100,
    },
  ];

  return (
    /*<Sidebar
      navTitle={""}
      navMenuItems={navMenuItems}
      menuItems={menuItems}
      accountMenuItems={accountMenuItems}
    >*/
    <div className="mainContainer">
      <div className="headerContainer row">
        <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
          Company
        </h4>
        <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px",
            }}
            onInput={(event) => serachOnChange(event)}
          ></Input>
          <SearchOutlined className="searchSymbol" />
          &nbsp;&nbsp;
          <Button
            size="large"
            className="button"
            onClick={() => addUserCompany()}
          >
            Add Company
          </Button>
        </div>
      </div>
      <br></br>
      <div className="tableContainer">
        <Table
          columns={columns}
          dataSource={data}
          // scroll={{
          //   x: "90vw",
          // }}
          pagination={{
            pageSize: configuration.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            total: configuration.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        />

        <Drawer
          title={`${selectedUserCompany._id ? "Edit" : "Add"} UserCompany`}
          className="drawer-dropDown"
          onClose={() => setVisible(false)}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          zIndex={200}
        >
          {visible ? (
            <AddCompany
              selectedUserCompany={selectedUserCompany}
              formType={selectedUserCompany._id ? "edit" : "add"}
              backToDashBoard={backToDashBoard}
              getData={getData}
            />
          ) : null}
        </Drawer>
        <ConfirmBox
          open={open}
          heading={"Delete"}
          Content={"Are you Sure want to Delete"}
          closeDialog={() => setOpen(false)}
          Function={deleteFunction}
          tittle={deleteCompany.companyName}
          confirmButton={"Delete"}
        />
      </div>
      <ToastContainer />
    </div>
    //</Sidebar>
  );
};
export default ManageCompany;
