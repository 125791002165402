import React, { useEffect, useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button, DatePicker, Dropdown } from "antd";
import { FloatLabel } from "../../UIComponent/_index";
import moment from "moment";

const AddPolicyView = (props) => {
  const { formType, backToDashBoard, selectedUserPolicy, selectedPolicy } =
    props;

  let viewDate = selectedPolicy;

  const [data, setData] = useState(viewDate);

  const handleCancel = () => {
    backToDashBoard();
  };
  return (
    <>
      <div className="formContainer h-100">
        <OverlayScrollbarsComponent className="formBody">
          <Formik innerRef={(ref) => { }}>
            {({ values }) => (
              <Form noValidate>
                <div className="innerContainer row">
                  <div className="dropDownContainer col-12 mb-2 ">
                    <FloatLabel label="Company Name" value={(data?.companyId?.companyName) ? data?.companyId?.companyName : ("") ? "" : (!data?.companyId) ? "" : data?.companyId[0]?.companyName}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={(data?.companyId?.companyName) ? data?.companyId?.companyName : ("") ? "" : (!data?.companyId) ? "" : data?.companyId[0]?.companyName}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Policy Date" value={data.policyDate}>
                      <DatePicker
                        className="w-100 form-control"
                        defaultValue={moment(data?.policyDate, "YYYY-MM-DD")}
                        // disabled={Dropdown}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Policy Number" value={data.policyNumber}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.policyNumber}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Customer Name" value={data?.customerName}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.customerName}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Registration Number" value={data?.RegistrationNumber}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.RegistrationNumber}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Email" value={data?.customerEmail}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.customerEmail}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Customer Mobile" value={data?.customerMobile}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.customerMobile}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="OD Premium" value={data?.odPremium}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.odPremium}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="TP Premium" value={data?.tpPremium}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.tpPremium}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Net Premium" value={data?.netPremium}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.netPremium}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Tax Amount " value={data?.tax}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.tax}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Total Premium" value={data?.totalPremium}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.totalPremium}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Agent Mail" value={(data?.userId?.name) ? (data?.userId?.name) : ("") ? "" : (!data?.userId) ? "" : data?.userId[0]?.name}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={(data?.userId?.name) ? (data?.userId?.name) : ("") ? "" : (!data?.userId) ? "" : data?.userId[0]?.name}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Agent Share" value={data?.agentShare}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.agentShare}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div style={{ paddingBottom: "28px" }} className="col-12 mb-2">
                    <FloatLabel label="Policy Status" value={data?.status}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.status}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <div></div>
                </div>
              </Form>
            )}
          </Formik>
        </OverlayScrollbarsComponent>
      </div>
      <footer>
        <Button className="button transButton" onClick={handleCancel}>
          Cancel
        </Button>
      </footer>
    </>
  );
};

export default AddPolicyView;
