import React, { useEffect, useState } from "react";
import Sidebar from "../../../../Common/Sidebar/Sidebar";
import {
  accountMenuItems,
  menuItems,
  navMenuItems,
} from "../../../../Constants/LayoutCommon";
import { Button, Drawer, Table, Tooltip, Input } from "antd";
import AddCompanyCode from "./AddCompanyCode";
import { EditOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { companyCodeGet } from "../../../../utils/servies/common";
import moment from "moment";
import { CompanyCodeDelete } from '../../../../utils/servies/common';
import ConfirmBox from "../../../../UIComponent/ConfirmBox";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { element } from "prop-types";

const ManageCompanyCode = () => {
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });


  const [selectedCompanyCode, setSelectedCompanyCode] = useState({});
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState();
  const [filterCompanyCode, setFilterCompanyCode] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({})
  const getValue = () => {
    companyCodeGet()
      .then((res) => {
        setFilterCompanyCode(res.data)
        setData(res.data)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getValue();
  }, []);

  const addUser = () => {
    setVisible(true);
    setSelectedCompanyCode({});
  };
  const backToDashBoard = () => {
    setVisible(false);
    setSelectedCompanyCode({});
  };
  const editUser = (element) => {
    setSelectedCompanyCode(element);
    setVisible(true);
  };
  const deleteUser = (element) => {
    setOpen(true)
    setDeleteData(element)
  }
  const deleteFunction = async () => {
    await CompanyCodeDelete(deleteData._id).then((res) => {
      toast.success("Successfully Deleted")
      getValue()
    }).catch(() => {
      toast.error("Something Went Wrong")
      getValue()
    })

    setOpen(false)
  }
  const onSearch = (searchText) => {
    filterCompanyCode.length ?
      setData(filterCompanyCode.filter(el =>
        `${el.companyCode},${el.description},${el?.companyId?.companyName}`.toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1)
      ) : setData(filterCompanyCode);
  }
  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setConfiguration(config => {
      return {
        ...config,
        filter: event.target.value
      }
    })
  }

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      render: (_, element) => {
        return (
          element?.companyId?.companyName
        )
      }
    },
    {
      title: "Company Code",
      dataIndex: "companyCode",
      key: "companyCode",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, element) => {
        return moment(element["createdAt"]).format("DD-MMM-YYYY");
      },
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description"
    // },

    {
      title: "Action",
      dataIndex: "action",
      render: (_, element, index) => (
        <div className="actionContainer">
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined onClick={() => editUser(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="Edit">
            <DeleteOutlined onClick={() => deleteUser(element)} />
          </Tooltip>
        </div>
      ),
      key: "action",
    },
  ];
  return (
    /*<Sidebar
      navTitle={""}
      navMenuItems={navMenuItems}
      menuItems={menuItems}
      accountMenuItems={accountMenuItems}
    >*/
    <div className="mainContainer">
      <div className="headerContainer row">
        <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
          Company Code
        </h4>
        <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px",
            }}
            onInput={(event) => searchOnChange(event)}
          ></Input><SearchOutlined className='searchSymbol' />&nbsp;&nbsp;

          <Button size="large" className="button" onClick={() => addUser()}>
            Add CompanyCode
          </Button>
        </div>
      </div>
      <br></br>
      <div className="tableContainer">
        <Table
          columns={columns}
          dataSource={data}
          // scroll={
          //   {
          //     x: '90vw'
          //   }
          // }
          pagination={{
            pageSize: configuration.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            total: configuration.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        />

        <Drawer
          title={`${selectedCompanyCode._id ? "Edit" : "Add"} CompanyCode`}
          className="drawer-dropDown"
          onClose={() => setVisible(false)}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          zIndex={200}
        >
          {visible ? (
            <AddCompanyCode
              formType={selectedCompanyCode?.companyCode ? "edit" : "add"}
              selectedCompanyCode={selectedCompanyCode}
              getValue={getValue}
              backToDashBoard={backToDashBoard}
            />
          ) : null}
        </Drawer>
        <ConfirmBox
          open={open}
          closeDialog={() => setOpen(false)}
          Function={deleteFunction}
          tittle={deleteData.companyCode}
          confirmButton={"Delete"}
          heading={"Delete"}
          Content={"Are you Sure want to Delete"}
        />
      </div>
      <ToastContainer />
    </div>
    //</Sidebar>
  );
};

export default ManageCompanyCode;
