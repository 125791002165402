import React, { useState, useEffect } from "react";
import {
  accountMenuItems,
  menuItems,
  navMenuItems,
} from "../../../../Constants/LayoutCommon";
import { Button, Drawer, Table, Tooltip, Input } from "antd";
import Sidebar from "../../../../Common/Sidebar/Sidebar";
import AddNotification from "./AddNotification";
import { EditOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { notificationget, notificationdelete } from "../../../../utils/servies/common";
import moment from "moment/moment";
import ConfirmBox from "../../../../UIComponent/ConfirmBox";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Notification = () => {
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 5,
    },
  });

  const [dataGet, setGetdata] = useState();
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [visible, setVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState();
  const [filterNotification, setFilterNotification] = useState([])
  const getData = () => {
    notificationget()
      .then((res) => {
        setFilterNotification(res.data)
        setGetdata(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getData();
  }, [visible]);


  const addNotification = () => {
    setVisible(true);
    setSelectedNotification({});
  };
  const backToDashBoard = () => {
    setVisible(false);
    setSelectedNotification({});
  };
  const editNotification = (element) => {
    setSelectedNotification(element);
    setVisible(true);
  };

  const DeleteNotification = (element) => {
    setOpen(true);
    setDeleteData(element);
  }

  const deleteFunction = async () => {
    await notificationdelete(deleteData._id).then(() => {
      toast.warning("Deleted Successfully ")
    }).catch(() => {
      toast.error("something Went Wrong")
    })
    setOpen(false);
    getData();
  }

  const onSearch = (searchText) => {
    filterNotification.length ?
      setGetdata(filterNotification.filter(el =>
        `${el.description}`.toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1)
      ) : setGetdata(filterNotification);
  }
  const searchOnChange = (event) => {
    onSearch(event.target.value)
    setConfiguration(config => {
      return {
        ...config,
        filter: event.target.value
      }
    })
  }

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      sorter: (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0),
      sortDirections: ["ascend", "descend", ""],
      defaultSortOrder: "",
    },
    {

      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, element) => {
        return moment(element["createdAt"]).format('DD-MMM-YYYY')
      },

    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, element, index) => (
        <div className="actionContainer">

          <Tooltip placement="bottom" title="Edit">
            <EditOutlined onClick={() => editNotification(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="Delete">
            <DeleteOutlined onClick={() => DeleteNotification(element)} />
          </Tooltip>
        </div>
      ),
      key: "action",
    },
  ];
  return (

    /*<Sidebar
      navTitle={""}
      navMenuItems={navMenuItems}
      menuItems={menuItems}
      accountMenuItems={accountMenuItems}
    >*/
    <div className="mainContainer">
      <div className="headerContainer row">
        <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
          Notification
        </h4>
        <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px"
            }}
            onInput={(event) => searchOnChange(event)}
          >
          </Input> <SearchOutlined className='searchSymbol' />&nbsp;&nbsp;
          <Button
            size="large"
            className="button"
            onClick={() => addNotification()}
          >
            Add Notification
          </Button>
        </div>
      </div>
      <br></br>
      <div className="tableContainer">
        <Table
          columns={columns}
          dataSource={dataGet}
          // scroll={
          //   {
          //     x: '90vw'
          //   }
          // }
          pagination={{
            pageSize: configuration.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            total: configuration.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        />

        <Drawer
          title={`${selectedNotification?._id ? "Edit" : "Add"
            } Notification`}
          className="drawer-dropDown"
          onClose={() => setVisible(false)}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          zIndex={200}
        >
          {visible ? (
            <AddNotification
              formType={selectedNotification?._id ? "edit" : "add"}
              selectedNotification={selectedNotification}
              getData={getData}
              backToDashBoard={backToDashBoard}

            />
          ) : null}
        </Drawer>
        <ConfirmBox
          open={open}
          closeDialog={() => setOpen(false)}
          Function={deleteFunction}
          tittle={deleteData.description}
          confirmButton={"Delete"}
          heading={"Delete"}
          Content={"Are you Sure want to Delete"}
        />
      </div>
      <ToastContainer />
    </div>
    //</Sidebar>

  );
};
export default Notification;
