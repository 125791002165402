import { ConsoleSqlOutlined } from "@ant-design/icons";
import axios from "axios";
import {baseUrl} from "../../Constants/constants"

const url = `${baseUrl}/api`;

//login
export const loginapi = async (resData) => {
  let data = await axios.post(`${url}/login`, resData);
  return data.data;
};
//register
export const registerapi = async (reciveData) => {
  let data = await axios.post(`${url}/register`, reciveData);
  return data.data;
};
//notification
export const notificationapi = async (recData) => {
  let data = await axios.post(`${url}/notifications`, recData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const notificationget = async () => {
  let data = await axios.get(`${url}/notifications`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
export const notifiUpdate = async (rcData, _id) => {
  let data = await axios.put(`${url}/notifications/${_id}`, rcData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const notificationdelete = async (_id) => {
  let data = await axios.delete(`${url}/notifications/${_id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
// branch
export const branchapipost = async (branchData) => {
  let data = await axios.post(`${url}/branch`, branchData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const branchapi = async (branchData) => {
  let data = await axios.get(`${url}/branch`, branchData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const branchdelete = async (id) => {
  let data = await axios.delete(`${url}/branch/${id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const branchupdate = async (reData, id) => {
  let data = await axios.put(`${url}/branch/${id}`, reData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

// RTO
export const rtoapipost = async (branchData) => {
  let data = await axios.post(`${url}/rto`, branchData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const rtoapi = async (branchData) => {
  let data = await axios.get(`${url}/rto`, branchData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const rtodelete = async (id) => {
  let data = await axios.delete(`${url}/rto/${id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const rtoupdate = async (reData, id) => {
  let data = await axios.put(`${url}/rto/${id}`, reData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

// year
export const yearapipost = async (branchData) => {
  let data = await axios.post(`${url}/year`, branchData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const yearapi = async (branchData) => {
  let data = await axios.get(`${url}/year`, branchData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const yeardelete = async (id) => {
  let data = await axios.delete(`${url}/year/${id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const yearupdate = async (reData, id) => {
  let data = await axios.put(`${url}/year/${id}`, reData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

// policyType

export const policyTypeGet = async () => {
  let data = await axios.get(`${url}/policyType`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
export const policyTypePost = async (postData) => {
  let data = await axios.post(`${url}/policyType`, postData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
export const policyTypeDelete = async (_id) => {
  let data = await axios.delete(`${url}/policyType/${_id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const policyTypeUpdate = async (received, _id) => {
  let data = await axios.put(`${url}/policyType/${_id}`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};


//product

export const productPost = async (recdata) => {
  let data = await axios.post(`${url}/product`, recdata, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const productUpdate = async (redata, _id) => {
  let data = await axios.put(`${url}/product/${_id}`, redata, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const ProductGet = async () => {
  let data = await axios.get(`${url}/product`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const ProductGetByCompanyId = async (companyId) => {
  let sendData = {
    companyId:companyId
  }
  let data = await axios.post(`${url}/productByCompanyId`, sendData,{
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const RtoGetByCompanyId = async (companyId) => {
  let sendData = {
    companyId:companyId
  }
  let data = await axios.post(`${url}/rtoByCompanyId`, sendData,{
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const yearGetByCompanyId = async (companyId) => {
  let sendData = {
    companyId:companyId
  }
  let data = await axios.post(`${url}/yearByCompanyId`, sendData,{
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const ProductDelete = async (_id) => {
  let data = await axios.delete(`${url}/product/${_id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

//company

export const policyCreateCompanyGet = async () => {
  let data = await axios.get(`${url}/company`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const policyCreateCompanyPost = async (companyData) => {
  let data = await axios.post(`${url}/company`, companyData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

//company
export const CompanyDelete = async (_id) => {
  let data = await axios.delete(`${url}/company/${_id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
export const policyCreateCompanyUpdate = async (companyData, _id) => {
  let data = await axios.put(`${url}/company/${_id}`, companyData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const companyCodeIdGet = async () => {
  let data = await axios.get(`${url}/company`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const companyLoginIdGet = async () => {
  let data = await axios.get(`${url}/company`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const companyCodeUpdate = async (addData, _id) => {
  let data = await axios.put(`${url}/companyCode/${_id}`, addData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};



export const policyCreateUserGet = async () => {
  let data = await axios.get(`${url}/admin/getAllUsers`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
export const getAllUsersDropDown = async (received) => {
  let data = await axios.post(`${url}/admin/getAllUsersDropDown`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
}

export const policyViewPost = async (received) => {
  let data = await axios.post(`${url}/searchPolicy`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
export const walletSearchPolicy = async (received) => {
  let data = await axios.post(`${url}/walletSearchPolicy`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
export const agentReport = async (received) => {
  let data = await axios.post(`${url}/agentReport`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const policyPendingPost = async (received) => {
  let data = await axios.post(`${url}/searchPolicy`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const policyPendingGet = async () => {
  let data = await axios.post(`${url}/admin/createpolicy`, {}, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

// Company Code
export const companyCodeGet = async () => {
  let data = await axios.get(`${url}/getAllcompanyCode`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const companyCodePost = async (recData) => {
  let data = await axios.post(`${url}/companyCode`, recData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};



export const CompanyCodeDelete = async (_id) => {
  let data = await axios.delete(`${url}/companyCode/${_id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
}
//company Login

export const CompanyPost = async (datas) => {
  let data = await axios.post(`${url}/companyLogin`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const companyLoginDelete = async (_id) => {
  let data = await axios.delete(`${url}/companyLogin/${_id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data
}
export const companyLoginPost = async (recData) => {
  let data = await axios.post(`${url}/companyLogin`, recData, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const companyLoginGet = async () => {
  let data = await axios.post(`${url}/getCompanyLogin`, {}, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const companyLoginUpdate = async (received, _id) => {
  let data = await axios.put(`${url}/companyLogin/${_id}`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};


export const companyLoginBranchGet = async () => {
  let data = await axios.get(`${url}/branch`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const companyLoginCompanyCodeGet = async () => {
  let data = await axios.get(`${url}/getAllcompanyCode`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const companyLoginUserGet = async () => {
  let data = await axios.get(`${url}/admin/getAllUsers`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const companyLoginUser = async () => {
  let data = await axios.post(`${url}/admin/getActiveAndInactive`, {}, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const allactiveUserslist = async (received) => {
  let data = await axios.post(`${url}/admin/getActiveAndInactive`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const getAllManager = async () => {
  let data = await axios.get(`${url}/admin/getAllManager`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const addUserPost = async (received) => {
  let data = await axios.post(`${url}/register`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const addUserUpdate = async (received) => {
  let data = await axios.post(`${url}/updateUser`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

// User Config 

export const userConfigPost = async (received) => {
  let data = await axios.post(`${url}/payout`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const multipleUserConfigPost = async (received) => {
  let data = await axios.post(`${url}/createmultipepayout`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const userConfigGet = async (received) => {
  let data = await axios.post(`${url}/getAllpayout`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const mypayoutPoints = async (received) => {
  let data = await axios.post(`${url}/mypayoutPoints`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const userConfigUpdate = async (receive, _id) => {
  let data = await axios.put(`${url}/payout/${_id}`, receive, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const userConfigDelete = async (_id) => {
  let data = await axios.delete(`${url}/payout/${_id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const deletemultiplepayout = async (received) => {
  let data = await axios.post(`${url}/deletemultipepayout`,received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
export const deletePolicy = async (id) => {
  let data = await axios.delete(`${url}/deletePolicy/${id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
}

export const userConfigBookingCode = async (_id) => {
  let data = await axios.get(`${url}/companyCode/${_id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

//user 
export const deleteUser = async (received) => {
  let data = await axios.post(`${url}/inActivateUser`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
export const actUser = async (received) => {
  let data = await axios.post(`${url}/isUserActive`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
// Token Verification 

export const tokenVerify = async () => {
  let data = await axios.get(`${url}/verifyToken`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
//dashboard

export const pendingPolicy = async (datas) => {
  let data = await axios.post(`${url}/pendingPolicy`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  })
  return data.data;
}
export const renewalPolicy = async (datas) => {
  let data = await axios.post(`${url}/renewalPolicyForMonthAndDay`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
}
export const myPremium = async (datas) => {
  let data = await axios.post(`${url}/myPremium`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
}
export const policyStatus = async (datas) => {
  let data = await axios.post(`${url}/myPolicyStatus`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
}

export const renewalPolicyForMonth = async (datas) => {
  let data = await axios.post(`${url}/renewalPolicyForMonthAndDay`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
}

export const renewalPolicyForMonthAndDayByproduct = async (datas) => {
  let data = await axios.post(`${url}/renewalPolicyForMonthAndDayByproduct`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
}
export const premiumByusers = async (datas) => {
  let data = await axios.post(`${url}/premiumByUser`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
}

export const myPremiumType = async (datas) => {
  let data = await axios.post(`${url}/myPremiumBypolicyType`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
}
export const totalPolicyYear = async (datas) => {
  let data = await axios.post(`${url}/totalPolicyYear`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
}
export const totalPolicyMonth = async (datas) => {
  let data = await axios.post(`${url}/totalPolicyMonth`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
}
// company Contact

export const companyContactGet = async () => {
  let data = await axios.post(`${url}/getAllCompanyContact`, {}, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const companyContactPost = async (received) => {
  let data = await axios.post(`${url}/companyContact`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const companyContactUpdate = async (received, _id) => {
  let data = await axios.put(`${url}/companyContact/${_id}`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};


export const companyContactDelete = async (_id) => {
  let data = await axios.delete(`${url}/companyContact/${_id}`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};


// Policy Entry Pending 

export const policyEntryPendingUpdate = async (receive) => {
  let data = await axios.post(`${url}/updatePolicy`, receive, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
export const policyEntryAdmin = async (receive) => {
  let data = await axios.post(`${url}/admin/calculatePolicyShare`, receive, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};
//renewal

export const renewalPolicyDateFilter = async (datas) => {
  let data = await axios.post(`${url}/renewalPolicySelectedDate`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data
}

export const walletDateFilter = async (datas) => {
  let data = await axios.post(`${url}/renewalPolicySelectedDate`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data
}

// report 

export const reportPost = async (datas) => {
  let data = await axios.post(`${url}/masterReport`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data
}

// Company Login Main 

export const companyLoginMainPost = async (datas) => {
  let data = await axios.post(`${url}/getcompanyLoginId`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data
}
export const getOtherscompanyLoginId = async (datas) => {
  let data = await axios.post(`${url}/getOtherscompanyLoginId`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data
}
export const companyLoginMainCompanyPost = async (datas) => {
  let data = await axios.post(`${url}/getAllCompanyContact`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data
}

/// profile 

export const profileGet = async () => {
  let data = await axios.get(`${url}/getuserInfo`, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

export const profileUpdate = async (receive, _id) => {
  let received = receive
  received._id = _id
  let data = await axios.post(`${url}/updateUser`, received, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data;
};

// PAYOUT 
export const createPayment = async (datas) => {
  let data = await axios.post(`${url}/createPayment`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data
}

// Transaction 
export const transactionPost = async (datas) => {
  let data = await axios.post(`${url}/userPolicyTransation`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data
}

export const transactionPostmanager = async (datas) => {
  let data = await axios.post(`${url}/manager/userPolicyTransation`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data
}
// view mapping request

export const specialSearch = async (datas) => {
  let data = await axios.post(`${url}/specialSearch`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data
}

// Search policy

export const searchPolicyApi = async (datas) => {
  let data = await axios.post(`${url}/findPolicy`, datas, {
    headers: { "x-access-token ": localStorage.getItem("token") }
  });
  return data.data
}