import React, { useEffect, useState } from "react";
import { Select, Input, Button, Table } from "antd";
import { searchPolicyApi, policyCreateCompanyGet } from "../../utils/servies/common";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import { Drawer, Tooltip } from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import DownloadIcon from '@mui/icons-material/Download';
import axios from "axios";
import {baseUrl} from "../../Constants/constants"


const SearchPolicy = () => {
    const [policySearch, setpolicySearch] = useState({
        pagination: {
            pageSize: 10,
        },
    });

    const [data, setData] = useState()
    const [value, setValue] = useState()
    const [saveField, setSaveField] = useState()
    // console.log(data?.RegistrationNumber)
    const handleSubmit = () => {
        if (data?.RegistrationNumber) {
            if (saveField === "policyNumber") {
                let sendData = {
                    policyNumber: data?.RegistrationNumber
                }
                searchPolicyApi(sendData).then((res) => {
                    setValue(res.data)
                }).catch((error) => {
                    console.log(error)
                })
            }
            else if (saveField === "RegistrationNumber") {
                let sendData = {
                    RegistrationNumber: data?.RegistrationNumber
                }
                searchPolicyApi(sendData).then((res) => {
                    setValue(res.data)
                }).catch((error) => {
                    console.log(error)
                })
            }
            else if (saveField === "customerName") {
                let sendData = {
                    customerName: data?.RegistrationNumber
                }
                searchPolicyApi(sendData).then((res) => {
                    setValue(res.data)
                }).catch((error) => {
                    console.log(error)
                })
            }
            else if (saveField === "customerMobile") {
                let sendData = {
                    customerMobile: data?.RegistrationNumber
                }
                searchPolicyApi(sendData).then((res) => {
                    setValue(res.data)
                }).catch((error) => {
                    console.log(error)
                })
            }
            else {
                let sendData = {
                    customerEmail: data?.RegistrationNumber
                }
                searchPolicyApi(sendData).then((res) => {
                    setValue(res.data)
                }).catch((error) => {
                    console.log(error)
                })
            }
        } else {
            toast.warning("Please Enter Value")
        }
            

    }

    const DownloadFunction = (ele) => {
        axios
          .get(
            `${baseUrl}/api/getpolicyFile?filename=${ele?.policyFile}`,
            {
              headers: {
                "x-access-token ": localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            window.open(res.data?.data?.URL);
            toast.success(res?.data?.message);
          })
          .catch((err) => {
            console.log("error", err)
          })
      };

    const columns = [
        // {
        //     title: "User ",
        //     dataIndex: "userId",
        //     key: "userId",
        //     width: 100,
        //     render: (userId) => {
        //         return userId?.userName
        //             ? userId?.userName
        //             : userId
        //                 ? userId[0]?.userName
        //                 : "";
        //     },
        // },
        {
            title: "Company Name",
            dataIndex: "companyId",
            key: "companyId",
            width: 100,
            render: (companyId) => {
                return companyId?.companyName
                    ? companyId?.companyName
                    : companyId
                        ? companyId[0]?.companyName
                        : "";
                // ? companyId[0]?.companyName : ""
            },
        },
        {
            title: "Policy Date",
            dataIndex: "policyDate",
            key: "policyDate",
            width: 100,
            render: (_, element) => {
                return moment(element["policyDate"]).format("DD-MMM-YYYY");
            },
        },
        {
            title: "Policy Number",
            dataIndex: "policyNumber",
            key: "policyNumber",
            width: 100,
        },
        {
            title: "Customer Name",
            dataIndex: "customerName",
            key: "customerName",
            width: 100,
        },
        {
            title: "Registeration Number",
            dataIndex: "RegistrationNumber",
            key: "RegistrationNumber",
            width: 100,
        },
        {
            title: "OD Premium",
            dataIndex: "odPremium",
            key: "odPremium",
            width: 100,
        },
        {
            title: "TP Premium",
            dataIndex: "tpPremium",
            key: "tpPremium",
            width: 100,
        },
        {
            title: "Net Premium",
            dataIndex: "netPremium",
            key: "netPremium",
            width: 100,
        },
        // {
        //     title: "Tax Amount",
        //     dataIndex: "tax",
        //     key: "tax",
        //     width: 100,
        // },
        {
            title: "Total Premium",
            dataIndex: "totalPremium",
            key: "totalPremium",
            width: 100,
        },

        {
            title: "Customer Email",
            dataIndex: "customerEmail",
            key: "customerEmail",
            width: 100,
        },
        {
            title: "Customer Number ",
            dataIndex: "customerMobile",
            key: "customerMobile",
            width: 100,
        },
        // {
        //     title: "Engine Number",
        //     dataIndex: "engineNumber",
        //     key: "engineNumber",
        //     width: 100
        // },
        // {
        //     title: "Fuel Type",
        //     dataIndex: "fuelType",
        //     key: "fuelType",
        //     width: 100
        // },
        // {
        //     title: "chassis Number",
        //     dataIndex: "chassisNumber",
        //     key: "chassisNumber",
        //     width: 100
        // },
        // {
        //     title: "vehicle Subclass",
        //     dataIndex: "vehicleSubclass",
        //     key: "vehicleSubclass",
        //     width: 100
        // },
        {
            title: "Payment Mode ",
            dataIndex: "paymentMode",
            key: "paymentMode",
            width: 100,
        },
        {
            title: "Policy Status",
            dataIndex: "status",
            key: "status",
            width: 100,
        },
         {
            title: "Action",
            dataIndex: "action",
            render: (_, element, index) => (
                <div className="actionContainer">
                {/* <Tooltip placement="bottom" title="Edit">
                    <EyeOutlined onClick={() => viewCustomerPolicy(element)} />
                </Tooltip> */}
                <Tooltip placement="bottom" title="Download">
                    <DownloadIcon onClick={() => DownloadFunction(element)} />
                </Tooltip>
                </div>
            ),
            key: "action",
        },
    ];
    return <>
        <div className="mainContainer">
            <div className="headerContainer row">
                <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
                    Search Policy
                </h4>
                <span></span>
                <div
                    className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex"
                    style={{
                        height: "40px",
                        width: "280px",
                    }}
                >
                    <Select
                        showSearch
                        className="w-100"
                        // value={saveCompany}
                        onSelect={(e) => { setSaveField(e) }}
                        placeholder="Please Select"
                    >
                        <Select.Option value="policyNumber"> POLICY NUMBER </Select.Option>
                        <Select.Option value="RegistrationNumber" > REGISTRATION NUMBER </Select.Option>
                        <Select.Option value="customerName"> CUSTOMER NAME </Select.Option>
                        <Select.Option value="customerMobile"> MOBILE</Select.Option>
                        <Select.Option value="customerEmail"> EMAIL </Select.Option>
                    </Select>
                </div>
                <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 mb-2">
                    <Input
                        style={{ height: 40 }}
                        placeholder=""
                        className="w-100"
                        value={data?.RegistrationNumber}
                        onChange={(e) => setData({ ...data, RegistrationNumber: e.target.value })}
                    />
                </div>
                <div className="col-lg-12 col-md-12 col-xl-4 col-sm-12 col-xs-12 d-flex">
                    <Button
                        className="reportAllbutton"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>
            {/* <br></br> */}
            
        </div>
        <br></br>
        <div className="tableContainer">
            <Table
                columns={columns}
                dataSource={value}
                scroll={{
                    x: "90vw",
                }}
                pagination={{
                    pageSize: policySearch.pagination.pageSize || 10,
                    showQuickJumper: true,
                    responsive: true,
                    total: policySearch.length,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                }}
            />
            <ToastContainer />
        </div>

    </>
}

export default SearchPolicy