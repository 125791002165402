import React, { useState, useEffect } from "react";
import { Button, Drawer, Table, Tooltip, Input } from "antd";
import AddBranch from "./AddBranch";
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { branchapi } from "../../../../utils/servies/common";
import { branchdelete } from "../../../../utils/servies/common";
import { branchupdate } from "../../../../utils/servies/common";
import moment from "moment/moment";
import ConfirmBox from "../../../../UIComponent/ConfirmBox";
import { width } from "@mui/system";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const Master = () => {
  const [selectedBranch, setSelectedBranch] = useState({});
  const [visible, setVisible] = useState(false);
  const [getData, setData] = useState();
  //deletepopup.....
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({})
  //.........
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });

  const [filterBranch, setFilterBranch] = useState([]);


  const addBranch = () => {
    setVisible(true);
    setSelectedBranch({});
  };
  const backToDashboard = () => {
    setVisible(false);
    setSelectedBranch({});
  };
  const editBranch = (element) => {
    setSelectedBranch(element);
    setVisible(true);
  };
  const viewBranch = (element) => {
    const el = { ...element }
    el.isView = true
    setVisible(true);
    setSelectedBranch(el);
  }

  //delete Popup
  const deleteBranch = (element) => {
    setOpen(true);
    setDeleteData(element)
  };
  const deleteFunction = async () => {
    await branchdelete(deleteData._id).then(() => {
      toast.success("Deleted Successfully")
      setOpen(false)
      dataGet()
    }).catch(() => {
      toast.error("something Went Wrong ")
      setOpen(false)
    })
    dataGet();

  }
  const dataGet = () => {
    branchapi()
      .then((res) => {
        setFilterBranch(res.data)
        let mapdata = res.data.map((item, req) => {
          return {
            branchName: item.branchName,
            createdAt: item.createdAt,
            _id: item._id,
          };
        });
        setData(mapdata);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    dataGet();
  }, []);


  //filter    
  const onSearch = (searchText) => {
    filterBranch.length ?
      setData(filterBranch.filter(el =>
        `${el.branchName}`.toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1)
      ) : setData(filterBranch);
  }

  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setConfiguration(config => {
      return {
        ...config,
        filter: event.target.value
      }
    })
  }

  const columns = [
    {
      title: "Branch",
      dataIndex: "branchName",
      key: "Branch",
      width: 100
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "review",
      render: (_, element) => {
        return moment(element["createdAt"]).format("DD-MMM-YYYY");
      },
      width: 100
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, element, index) => (
        <div className="actionContainer">
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined onClick={() => editBranch(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="Delete">
            <DeleteOutlined onClick={() => deleteBranch(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="view">
            <EyeOutlined onClick={() => viewBranch(element)} />
          </Tooltip>
        </div>
      ),
      key: "action",
      width: 100
    },
  ];
  return (

    /* <Sidebar
       navTitle={""}
       navMenuItems={navMenuItems}
       menuItems={menuItems}
       accountMenuItems={accountMenuItems}
     >*/
    <div className="mainContainer">
      <div className="headerContainer row">
        <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
          Branch
        </h4>

        <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px"
            }}
            onInput={(event) => searchOnChange(event)}
          >
          </Input><SearchOutlined className='searchSymbol' />&nbsp;&nbsp;
          <Button
            size="large"
            className="button"
            onClick={() => addBranch()}
          >
            Add Branch
          </Button>
        </div>
      </div>
      <br></br>
      <div className="tableContainer">
        <Table
          columns={columns}
          dataSource={getData}
          // scroll={
          //   {
          //     x: '90vw'
          //   }
          // }
          pagination={{
            pageSize: configuration.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            total: configuration.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}

        />

        <Drawer
          title={`${selectedBranch?._id ? "Edit" : "Add"} Branch`}
          className="drawer-dropDown"
          onClose={() => setVisible(false)}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          zIndex={200}
        >
          {visible ? (
            <AddBranch
              formType={selectedBranch.isView ? "view" : selectedBranch?._id ? "edit" : "add"}
              selectedBranch={selectedBranch}
              dataGet={dataGet}
              backToDashboard={backToDashboard}
            />
          ) : null}
        </Drawer>
        <ConfirmBox
          open={open}
          heading={"Delete"}
          closeDialog={() => setOpen(false)}
          Function={deleteFunction}
          Content={"Are you Sure Want Delete"}
          tittle={deleteData.branchName}
          confirmButton={"Delete"}
        />
      </div>
      <ToastContainer />
    </div>
    //</Sidebar>

  );
};

export default Master;
