import React, { useEffect, useState } from "react";
import { Button, Table, Space, DatePicker,Select  } from "antd";
import {
  getAllUsersDropDown,notificationget,
  myPremium,
  myPremiumType,
  policyStatus,
  renewalPolicyForMonth,
  renewalPolicyForMonthAndDayByproduct,
  tokenVerify,premiumByusers,
  profileGet,branchapi,getAllManager
} from "../../../utils/servies/common";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

const Newdashboard = () => {
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const current = new Date();
  const endDatee = `${current.getFullYear()}/${current.getMonth() + 1
    }/${current.getDate()}`;
  const startDatee = `${current.getFullYear()}/${current.getMonth() + 1}/${1}`;
  const [saveDate, setSaveDate] = useState([startDatee, endDatee]);
  const [premium, setPremium] = useState();
  const [premiumData, setPremiumData] = useState();
  const [premiumUsers, setPremiumUsers] = useState();
  const [savePolicyStatus, setSavePolicyStatus] = useState()
  const [saveRenewalPolicy, setSaveRenewalPolicy] = useState()
  const [saveRenewalProduct, setSaveRenewalProduct] = useState()
  const [dateValue, setDateValue] = useState([startDatee, endDatee])
  const [countApprove, setCountApprove] = useState()
  const [countRejected, setCountRejected] = useState()
  const [countPending, setCountPending] = useState()
  const [countvalue, setCountvalue] = useState()
  const [notification, setNotification] = useState();
  const [statusArray, setStatusArray] = useState([])
  const [submitType, setSubmitType] = useState('premium')
  const [saveBranch, setSaveBranch] = useState(undefined)
  const [branch, setbranch] = useState()
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const role=localStorage.getItem('role')
  const Notifications = async () => {
    notificationget()
      .then(async (res) => {
        let rowdata = [];
        await res.data.map((ele) => {
          rowdata.push(ele.description);
          rowdata.push("‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ");
        });
        setNotification(rowdata);
      })
      .catch((err) => console.log(err));
  };
  const premiumType = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
        managerId:saveBranch
      };
      myPremiumType(value)
        .then((res) => {
          setPremium(res?.data)
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("done11");
    }
  };
  const myPremiumData = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
        managerId:saveBranch
      };
      myPremium(value)
        .then((res) => {
          setPremiumData(res?.data[0])
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Done");
    }
  };
  const myPolicyStatus = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
        managerId:saveBranch
      };
      policyStatus(value)
        .then((res) => {
          setSavePolicyStatus(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Done");
    }
  };
  const myrenewalPolicyForMonth = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
        managerId:saveBranch
      };
      renewalPolicyForMonth(value)
        .then((res) => {
          setSaveRenewalPolicy(res?.data)
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Done");
    }
  };
  const myrenewalPolicyForMonthAndDayByproduct = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
        managerId:saveBranch
      };
      renewalPolicyForMonthAndDayByproduct(value)
        .then((res) => {
         
          console.log("res.data", res?.data)
          if (res.data.day.length > 0 && res.data.day[0]._id) {
            let dtaform=[...res.data.day , ...res.data.month]
            setSaveRenewalProduct(dtaform)
          } else {
            setSaveRenewalProduct(res?.data.month)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Done11");
    }
  };
  const premiumByUserss = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
        managerId:saveBranch
      };
      premiumByusers(value)
        .then((res) => {
          console.log("res",res)
          setPremiumUsers(res)
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Done11");
    }
  };
  const setDateSelect = (value, dateString) => {
    setSaveDate(value)
    setDateValue(dateString)
  };

  const setbranchselect = (value) => {
    setSaveBranch(value)
  };

  const getbranch = () => {
    getAllManager().then((res) => {
      console.log(res)
      setbranch(res)
    })
  }

  const profileGets =()=>{
    profileGet().then(res=>{
      localStorage.setItem("userId",res.data._id)
    })
  }

  useEffect(() => {
    tokenVerify().then(res => {
      if (res.status === 401) {
        toast.warning('Session Expired  ')
        localStorage.clear()
        navigate('/login')
      }
    }).catch(e => {
      toast.error('Something Went Wrong')
      navigate('/login')
    })
    profileGets()
    Notifications();
    premiumType();
    myPremiumData();
    myPolicyStatus();
    myrenewalPolicyForMonth();
    myrenewalPolicyForMonthAndDayByproduct()
    premiumByUserss()
    getbranch()
  }, [])

  const [policyPending, setPolicyPending] = useState({
    pagination: {
      pageSize: 10,
    },
  });
  const [userr, setUserr] = useState();
  const [date, setDate] = useState();


  const getUser = (data) => {
    console.log("callimg");
    if (localStorage.getItem("role") == "admin") {
      data.role = "agent";
    }
    getAllUsersDropDown(data).then((res) => {
      setUserr(res.data);
    });
  };
  const onChanges = (value) => {
    setDate(value);
  };

  useEffect(() => {
    if (localStorage.getItem("role") == 'agent') {
      navigate("/agent/dashboard");
    }
    const countva =
      savePolicyStatus?.find
        ((ele) => {
          return ele._id === "entry pending"
        })
    const appr =
      savePolicyStatus?.find
        ((ele) => {
          return ele._id === "approved"
        })
    const countReject =
      savePolicyStatus?.find
        ((ele) => {
          return ele._id === "rejected"
        })

    const countPend =
      savePolicyStatus?.find
        ((ele) => {
          return ele._id === "pending"
        })
    setCountApprove(appr)
    setCountPending(countPend)
    setCountvalue(countva)
    setCountRejected(countReject)
    const stsArr = [
      {
        _id : "Entry pending",
        count:  countva?.count ? countva.count : 0
      },
      {
        _id : "Pending",
        count:  countPend?.count ? countPend.count : 0
      },
      {
        _id : "Approved",
        count: appr?.count ? appr.count : 0
      },{
        _id : "Rejected",
        count:  countReject?.count ? countReject.count : 0
      }
    ]
    setStatusArray(stsArr)
  }, [savePolicyStatus])

  const PremiumColumn = [
    {
      title: "Product Name",
      dataIndex: "_id",
      key: "_id",
      width: 100,
      render: (_id) => {
        if (_id) {
          return _id?.productName
          ? _id?.productName
          : _id[0]?.productName
          ? _id[0]?.productName
          : "";
        } else {
          return "--"
        }
      },
    },
    {
      title: "Total Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
      width: 100,
      render: (totalPremium) => {
        if (totalPremium) {
          return totalPremium
        } else {
          return "--"
        }
      }
    },
    {
      title: "Policy Count",
      dataIndex: "count",
      key: "count",
      width: 100,
    },
  ];
  
  const policyStatusColumn = [
    {
      title: "Status",
      dataIndex: "_id",
      key: "_id",
      width: 100,
    },
    {
      title: "count ",
      dataIndex: "count",
      key: "count",
      width: 100,
    }
  ];
  const renewalColumn = [
    {
      title: "Product Name",
      dataIndex: "_id",
      key: "_id",
      width: 100,
      render: (_id) => {
        if (_id) {
          return _id?.productName
          ? _id?.productName
          : _id[0]?.productName
          ? _id[0]?.productName
          : "";
        } else {
          return "--"
        }
      },
    },
    {
      title: "Total Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
      width: 100,
      render: (totalPremium) => {
        if (totalPremium) {
          return totalPremium
        } else {
          return "--"
        }
      }
    },
    {
      title: "Policy Count",
      dataIndex: "count",
      key: "count",
      width: 100,
    },
  ]
  const userColumn = [
    {
      title: "Agent Name",
      dataIndex: "_id",
      key: "_id",
      width: 100,
      render:(_id)=>{
        return _id[0].name
      }
    },
    {
      title: "Policy Count",
      dataIndex: "count",
      key: "count",
      width: 100,
    },
    {
      title: "Total Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
      width: 100,
    },
  ];

  return (
    <>
    <div>
        <marquee
          className="marquee"
          style={{ fontSize: "34px", color: "#0d45a5" }}
        >
          {notification}
        </marquee>
      </div>
      <div className="mainContainer">
        <div className="headerContainer row">
          <h4 className="col-lg-6 col-md-6 col-xl6 col-sm-12 col-xs-12 headerTitle">
            Dashboard
          </h4>
          <div className="col-lg-6 col-md-6 col-xl- 6col-sm-12 col-xs-12 d-flex justify-content-end">
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-8 col-xs-8 d-flex">
            <Space direction="vertical" size={12}>
              <RangePicker
                format="DD-MM-YYYY"
                style={{ height: "40px" }}
                onChange={setDateSelect}
              />
            </Space>
          </div>
          {role == 'admin' &&
            <>
              <div className="col-lg-3 col-md-3 col-xl-3 col-sm-4 col-xs-4" >
                <Select
                  // showSearch
                  className="w-100"
                  value={saveBranch}
                  defaultValue="Select Branch"
                  onSelect={setbranchselect}
                >
                  <Select.Option value={undefined}>
                    All
                  </Select.Option>
                  {branch?.length > 0 &&
                    branch.map((ele) => {
                      return <Select.Option value={ele._id}>{ ele.branch.branchName} ( {ele.name})</Select.Option>
                    })}
                </Select>
              </div>
            </>
          }
        </div>
        <br></br>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
            <Button className="w-100 reportAllbutton" onClick={async() => {
              await setSubmitType('premium')
              await myPremiumData()
              await premiumType()
            }
            }>
              Premium
            </Button>
          </div>
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
            <Button
              className="w-100 reportAllbutton"
              onClick={async () => {
                await setSubmitType("renewal")
                await myrenewalPolicyForMonth()
                await myrenewalPolicyForMonthAndDayByproduct()
              }}
            >
              Renewal
            </Button>
          </div>
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
            <Button
              className="w-100 reportAllbutton"
              onClick={async() => {
                await setSubmitType("policystatus")
                await myPolicyStatus()
              }}
            >
              Policy Status
            </Button>
          </div>
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
            <Button
              className="w-100 reportAllbutton"
              onClick={async() => {
                await setSubmitType("userstatus")
                await premiumByUserss()
              }}
            >
              Users
            </Button>
          </div>
        </div>
        { submitType=='premium' && 
          <div className="row mt-3">
            <h3 className="col-lg-6 col-md-6 col-xl-6">
              Premium : { premiumData?.totalPremium ? premiumData?.totalPremium : 0}
            </h3>
            <h3 className="col-lg-3 col-md-3 col-xl-3">
                count : {premiumData?.count ? premiumData?.count : 0}
            </h3>
          </div>
        }
        { submitType=='renewal' && 
          <div className="row mt-3">
            <h4 className="col-lg-6 col-md-6 col-xl-6">
              Month(Until {`${dateValue[1]}`})  : { saveRenewalPolicy?.month ? saveRenewalPolicy?.month?.map((ele) => {
                        return ele.count
                      }) : 0}
            </h4>
            <h4 className="col-lg-6 col-md-6 col-xl-6">
              Today({`${dateValue[1]}`}) : {saveRenewalPolicy?.day ? saveRenewalPolicy?.day?.map((ele) => {
                        return ele.count
                      }) : 0}
            </h4>
          </div>
        }
        { submitType=='policystatus' && 
          <div className="row mt-3">
            <h4 className="col-lg-4 col-md-4 col-xl-4">
              Entry Pending :   { (countvalue?._id === "entry pending") ?
                        (countvalue?.count) : "0"
                      }
            </h4>
            <h4 className="col-lg-4 col-md-4 col-xl-4">
              Pending :   { (countPending?._id === "pending") ?
                        (countPending?.count) : "0"
                      }
            </h4>
          </div>
        }
        <div className="tableContainer mt-3">
          <Table
            columns={submitType=='premium' ? PremiumColumn : submitType=='policystatus' ? policyStatusColumn : submitType=='userstatus' ? userColumn : submitType=="renewal" ? renewalColumn : [] }
            dataSource={submitType=='premium' ? premium : submitType=='renewal' ? saveRenewalProduct : submitType=='policystatus' ? statusArray : submitType=='userstatus' ?  premiumUsers : []}
            pagination={{
              pageSize: 10,
              showQuickJumper: true,
              responsive: true,
              total: policyPending.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </div>
        <ToastContainer />
      </div>
    </>
    //</Sidebar>
  );
};

export default Newdashboard;
