import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {
  accountMenuItems,
  menuItems,
  navMenuItems,
} from "../../Constants/LayoutCommon";
import AddUserConfig from "./AddUserConfig";
import {
  Button,
  Drawer,
  Table,
  Tooltip,
  Input,
  Typography,
  Select,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import ConfirmBox from "../../UIComponent/ConfirmBox";
import { userConfigGet,ProductGetByCompanyId } from "../../utils/servies/common";
import {
  userConfigDelete,deletemultiplepayout,
  getAllUsersDropDown,
  policyCreateCompanyGet,
  companyCodeGet,
  policyTypeGet,
  ProductGet,
  userConfigBookingCode
} from "../../utils/servies/common";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FloatLabel from "../../UIComponent/FloatLabel";

const UserConfig = () => {
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });
  const [selectedUserConfig, setSelectedUserConfig] = useState({});
  const [visible, setVisible] = useState(false);
  const [valueData, setGetdata] = useState();
  const [open, setOpen] = useState(false);
  const [openAll, setOpenAll] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [filterUserconfig, setFilterUserConfig] = useState([]);
  const [userr, setUserr] = useState()
  const [userName, setUserName] = useState()
  const [dataCompany, setDataCompany] = useState()
  const [dataCompanyCode, setDataCompanyCode] = useState()
  const [dataProduct, setDataProduct] = useState()
  const [dataPolicyType, setDataPolicyType] = useState()
  const [getCompany, setGetCompany] = useState()
  const [bookingCode, setBookingCode] = useState()
  const [product, setProduct] = useState(['all'])
  const [policy, setPolicy] = useState(['all'])
  const [dataFromNew,setDataFromNew]=useState()
  const role=localStorage.getItem("role")
  const getDataCompany = () => {
    policyCreateCompanyGet().then((res) => {
      setDataCompany(res.data);
    });
  };
  const getUser = () => {
    getAllUsersDropDown().then((res) => {
      setUserr(res.data);
    });
  };
  const { Option } = Select;

  const getdata = (data) => {
    userConfigGet(data)
      .then((res) => {
        setFilterUserConfig(res?.data);
        setGetdata(res?.data);
        // console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(bookingCode?.key, product?.key, policy?.key, "kkkkk")

  const handleSubmit = () => {
    setDataFromNew()
    var sendData = {};
    if (userName?.key != "1001") {
      sendData.userId = userName?.key;
    }
    if (getCompany?.key != "1001") {
      sendData.companyId = getCompany?.key;
    }
    if (!product?.includes('all')) {
      sendData.productId = product;
    }
    if (bookingCode?.key != "1001") {
      sendData.companyCodeId = bookingCode?.key;
    }
    if (!policy?.includes('all')) {
      sendData.policyType = policy;
    }
    userConfigGet(sendData)
      .then((res) => {
        setFilterUserConfig(res?.data);
        setGetdata(res?.data);
        // console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addUserConfig = () => {
    setVisible(true);
    setSelectedUserConfig({});
  };
  const editConfig = (element) => {
    setVisible(true);
    setSelectedUserConfig(element);
  };

  const viewConfig = (element) => {
    const el = { ...element };
    el.isView = true;
    setVisible(true);
    setSelectedUserConfig(el);
  };
  const backToDashboard =async (data) => {
    setVisible(false);
    if (data?.userId) {
      await getdata(data)
      setDataFromNew(data)
    } else {
      if (dataFromNew) {
        await getdata(dataFromNew)
      } else {
        handleSubmit()
      }
    }
  };

  const deleteConfig = (element) => {
    setOpen(true);
    setDeleteData(element);
  };

  const handledeleteAllConfig = () => {
    setOpenAll(true)
  }

  const deleteAllConfig = async () => {
    setDataFromNew()
    var sendData = {};
    if (userName?.key != "1001") {
      sendData.userId = userName?.key;
    }
    if (getCompany?.key != "1001") {
      sendData.companyId = getCompany?.key;
    }
    if (!product?.includes('all')) {
      sendData.productId = product;
    }
    if (bookingCode?.key != "1001") {
      sendData.companyCodeId = bookingCode?.key;
    }
    if (!policy?.includes('all')) {
      sendData.policyType = policy;
    }
    await deletemultiplepayout(sendData)
      .then(async() => {
        toast.success("Successfully Deleted");
        // getdata({});
        if (dataFromNew) {
          await getdata(dataFromNew)
        } else {
          handleSubmit()
        }
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
      setOpenAll(false);
  };

  const deleteFunction = async () => {
    await userConfigDelete(deleteData._id)
      .then(async() => {
        toast.success("Successfully Deleted");
        // getdata({});
        if (dataFromNew) {
          await getdata(dataFromNew)
        } else {
          handleSubmit()
        }
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });

    setOpen(false);
  };

  const getAllBookingCode = async () => {
    await companyCodeGet()
      .then((res) => {
        setDataCompanyCode(res.data);
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
  };
  const filtercompcode = (id) => {
    userConfigBookingCode(id).then((res) => {
      setDataCompanyCode(res.data);
    }).catch(e => {
      toast.warning("Something Went Wrong")
    })
  }

  const getpolicyTypeGet = async () => {
    await policyTypeGet()
      .then((res) => {
        setDataPolicyType(res.data);
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
  };

  const getdataproduct = async () => {
    await ProductGet()
      .then((res) => {
        setDataProduct(res.data);
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
  };
  useEffect(() => {
    // getdata({});
    getDataCompany();
    getUser();
    getAllBookingCode();
    getpolicyTypeGet();
    getdataproduct();
  }, []);

  const onSearch = (searchtext) => {
    filterUserconfig.length
      ? setGetdata(
          filterUserconfig.filter(
            (el) =>
              `${el?.pacover},${el?.payOutType},${el?.companyCodeId?.companyCode},${el?.NETPercent},${el?.ODPercent},${el?.TPPercent},${el?.companyId?.companyName},${el?.userId?.userName},${el?.policyType?.name}`
                .toLowerCase()
                .indexOf(searchtext.toLowerCase()) !== -1
          )
        )
      : setGetdata(filterUserconfig);
  };
  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setConfiguration((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };
  const setUserdetail = (event, key) => {
    setUserName(key);
  };
  const setCompanyDetail = (event, key) => {
    setGetCompany(key);
    if (key.key !== "1001") {
      filtercompcode(key.key)
      getProductBycomp(key.key)
    } else {
      getAllBookingCode()
      getdataproduct()
    }
  };

  const getProductBycomp = (id) => {
    ProductGetByCompanyId(id)
      .then((res) => {
        setDataProduct(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const productDetail = async(value) => {
    setProduct(value)
    if (value.includes('all')) {
      setProduct(['all'])
    }
  };
  const policyDetail = (value) => {
    setPolicy(value);
    if (value.includes('all')) {
      setPolicy(['all'])
    }
  };
  const bookingCodedetail = (event, key) => {
    setBookingCode(key)

  }
  const columns = role == "admin" ?
    [
    {
      title: "User",
      dataIndex: "userId",
      key: "userId",
      render: (userId) => {
        return userId?.userName;
      },
      // ellipsis: true,
      // sorter: (a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0),
      // sortDirections: ['ascend', 'descend', ''],
      // defaultSortOrder: ''
    },
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      render: (companyId) => {
        return companyId?.companyName;
      },
      // ellipsis: true,
      // sorter: (a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0),
      // sortDirections: ['ascend', 'descend', ''],
      // defaultSortOrder: ''
    },
    {
      title: "Booking Code",
      dataIndex: "companyCodeId",
      key: "companyCodeId",
      render: (companyCodeId) => {
        return companyCodeId?.companyCode;
      },
      },
    {
      title: "RTO Name",
      dataIndex: "rto",
      key: "rto",
      render: (rto) => {
        return rto?.rtoName;
      }
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      render: (year) => {
        return year?.year;
      }
    },
    {
      title: "Product",
      dataIndex: "productId",
      key: "productId",
      render: (productId) => {
        return productId.map((ele) => {
          return ele?.productName;
        });
      },
    },
    {
      title: "Policy Type",
      dataIndex: "policyType",
      key: "policyType",
      render: (policyType) => {
        return policyType?.name;
      },
    },
    {
      title: "PA Cover",
      dataIndex: "pacover",
      key: "pacover",
    },
    {
      title: "OD %",
      dataIndex: "ODPercent",
      key: "ODPercent",
    },
    {
      title: "TP %",
      dataIndex: "TPPercent",
      key: "TPPercent",
    },
    {
      title: "NET %",
      dataIndex: "NETPercent",
      key: "NETPercent",
    },
    // {
    //   title: "Admin OD %",
    //   dataIndex: "adminpayout",
    //   key: "adminpayout",
    //   render: (adminpayout) => {
    //     return adminpayout?.ODPercent;
    //   },
    // },
    // {
    //   title: "Admin TP %",
    //   dataIndex: "adminpayout",
    //   key: "adminpayout",
    //   render: (adminpayout) => {
    //     return adminpayout?.TPPercent;
    //   },
    // },
    {
      title: "Admin NET %",
      dataIndex: "adminpayout",
      key: "adminpayout",
      render: (adminpayout) => {
        return adminpayout?.NETPercent;
      },
    },
    {
      title: "Pay Out Type ",
      dataIndex: "payOutType",
      key: "payOutType",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, element, index) => (
        <div className="actionContainer">
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined onClick={() => editConfig(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="View">
            <EyeOutlined onClick={() => viewConfig(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="Delete">
            <DeleteOutlined onClick={() => deleteConfig(element)} />
          </Tooltip>
        </div>
      ),
      key: "action",
    },
    ] :
    [
      {
        title: "User",
        dataIndex: "userId",
        key: "userId",
        render: (userId) => {
          return userId?.userName;
        },
        // ellipsis: true,
        // sorter: (a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0),
        // sortDirections: ['ascend', 'descend', ''],
        // defaultSortOrder: ''
      },
      {
        title: "Company Name",
        dataIndex: "companyId",
        key: "companyId",
        render: (companyId) => {
          return companyId?.companyName;
        },
        // ellipsis: true,
        // sorter: (a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0),
        // sortDirections: ['ascend', 'descend', ''],
        // defaultSortOrder: ''
      },
      {
        title: "Booking Code",
        dataIndex: "companyCodeId",
        key: "companyCodeId",
        render: (companyCodeId) => {
          return companyCodeId?.companyCode;
        },
      },
      {
        title: "RTO Name",
        dataIndex: "rto",
        key: "rto",
        render: (rto) => {
          return rto?.rtoName;
        }
      },
      {
        title: "Year",
        dataIndex: "year",
        key: "year",
        render: (year) => {
          return year?.year;
        }
      },
      {
        title: "Product",
        dataIndex: "productId",
        key: "productId",
        render: (productId) => {
          return productId.map((ele) => {
            return ele?.productName;
          });
        },
      },
      {
        title: "Policy Type",
        dataIndex: "policyType",
        key: "policyType",
        render: (policyType) => {
          return policyType?.name;
        },
      },
      {
        title: "PA Cover",
        dataIndex: "pacover",
        key: "pacover",
      },
      {
        title: "OD %",
        dataIndex: "ODPercent",
        key: "ODPercent",
      },
      {
        title: "TP %",
        dataIndex: "TPPercent",
        key: "TPPercent",
      },
      {
        title: "NET %",
        dataIndex: "NETPercent",
        key: "NETPercent",
      },
      
      {
        title: "Pay Out Type ",
        dataIndex: "payOutType",
        key: "payOutType",
      },
      {
        title: "Action",
        dataIndex: "action",
        render: (_, element, index) => (
          <div className="actionContainer">
            <Tooltip placement="bottom" title="Edit">
              <EditOutlined onClick={() => editConfig(element)} />
            </Tooltip>
            <Tooltip placement="bottom" title="View">
              <EyeOutlined onClick={() => viewConfig(element)} />
            </Tooltip>
            <Tooltip placement="bottom" title="Delete">
              <DeleteOutlined onClick={() => deleteConfig(element)} />
            </Tooltip>
          </div>
        ),
        key: "action",
      },
    ];

  return (
    /*<Sidebar
      navTitle={""}
      navMenuItems={navMenuItems}
      menuItems={menuItems}
      accountMenuItems={accountMenuItems}
    >*/

    <div className="mainContainer">
      <div className="headerContainer row">
        <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
          UserConfig
        </h4>
        <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px",
            }}
            onInput={(event) => searchOnChange(event)}
          ></Input>
          <SearchOutlined className="searchSymbol" />
          &nbsp;&nbsp;
          <Button
            size="large"
            className="button"
            onClick={() => addUserConfig()}
          >
            Add UserConfig
          </Button>
        </div>
      </div>
      <div className="headerContainer row mt-4">
        <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12">
          <FloatLabel label="User" value="value">
          <Select
            showSearch
            className="w-100"
            value={userName ? userName : "all"}
            onSelect={setUserdetail}
          >
            <Select.Option value="all" key="1001">
              All
            </Select.Option>
            {userr?.map((ele) => {
              return (
                <Select.Option value={ele.userName} key={ele._id}>
                  {ele.userName}({ele.role.toUpperCase()})(
                  {ele.branch.branchName})
                </Select.Option>
              );
            })}
            </Select>
            </FloatLabel>
        </div>
        <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12">
          <FloatLabel label="Company Name" value="value">
          <Select
            showSearch
            className="w-100"
            value={getCompany ? getCompany : "all"}
            onSelect={setCompanyDetail}
          >
            <Select.Option value="all" key="1001">
              All
            </Select.Option>
            {dataCompany?.length > 0 &&
              dataCompany?.map((ele) => {
                return (
                  <Select.Option value={ele.companyName} key={ele._id}>
                    {ele.companyName}
                  </Select.Option>
                );
              })}
            </Select>
            </FloatLabel>
        </div>
        <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12">
          <FloatLabel label="Booking Code" value="value">
          <Select
            showSearch
            className="w-100"
            value={bookingCode ? bookingCode : "all"}
            onSelect={bookingCodedetail}
          >
            <Select.Option value="all" key="1001">
              All
            </Select.Option>
            {dataCompanyCode?.length > 0 &&
              dataCompanyCode?.map((ele) => {
                return (
                  <Select.Option value={ele?.companyCode} key={ele?._id}>
                    {ele?.companyCode}
                  </Select.Option>
                );
              })}
            </Select>
            </FloatLabel>
        </div>
        <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12">
          <FloatLabel label="Product" value="value">
          <Select
            showSearch
              mode="multiple"
              maxTagCount= "responsive"
            className="w-100"
            style={{ width: '100%' }}
            value={product}
            onChange={productDetail}
          >
            <Select.Option value="all" key="1001">
              All
            </Select.Option>
            {dataProduct?.length > 0 &&
              dataProduct?.map((ele) => {
                return (
                  <Select.Option value={ele?._id} key={ele?._id}>
                    {ele?.productName}
                  </Select.Option>
                );
              })}
            </Select>
            </FloatLabel>
        </div>
      </div>
      <div className="headerContainer row mt-3">
        <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12">
          <FloatLabel label="Policy Type" value="value">
          <Select
            showSearch
              mode="multiple"
              maxTagCount= "responsive"
            className="w-100"
            style={{ width: '100%' }}
            value={policy ? policy : "all"}
            onChange={policyDetail}
          >
            <Select.Option value="all" key="1001">
              All
            </Select.Option>
            {dataPolicyType?.length > 0 &&
              dataPolicyType?.map((ele) => {
                return (
                  <Select.Option value={ele?._id} key={ele?._id}>
                    {ele?.name}
                  </Select.Option>
                );
              })}
            </Select>
            </FloatLabel>
        </div>
        <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12">
          <Button
            className="reportAllbutton"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
        {
          valueData?.length>0 && 
          <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12">
            <Button
              className="reportAllbutton"
              onClick={handledeleteAllConfig}
            >
              Delete All
            </Button>
          </div>
        }
      </div>
      <br></br>
      <div className="tableContainer">
        <Table
          columns={columns}
          dataSource={valueData}
          // scroll={{
          //   x: "95vw",
          // }}
          pagination={{
            pageSize: configuration.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            total: configuration.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />

        <Drawer
          title={`${
            selectedUserConfig.companyCodeId ? "Edit" : "Add"
          } UserConfig`}
          className="UserConfigModel drawer-dropDown"
          onClose={() => setVisible(false)}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          zIndex={200}
        >
          {visible ? (
            <AddUserConfig
              backToDashboard={backToDashboard}
              selectedUserConfig={selectedUserConfig}
              getdata={getdata}
              formType={
                selectedUserConfig.isView
                  ? "view"
                  : selectedUserConfig._id
                  ? "edit"
                  : "add"
              }
            />
          ) : null}
        </Drawer>
        <ConfirmBox
          open={open}
          heading={"Delete"}
          closeDialog={() => setOpen(false)}
          Function={deleteFunction}
          Content={"Are you Sure Want Delete"}
          confirmButton={"Delete"}
        />

        <ConfirmBox
          open={openAll}
          heading={"Delete"}
          closeDialog={() => setOpenAll(false)}
          Function={deleteAllConfig}
          Content={"Are you Sure Want Delete All"}
          confirmButton={"Delete"}
        />
      </div>
      <ToastContainer />
    </div>

    //</Sidebar>
  );
};
export default UserConfig;
  