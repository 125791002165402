import React, { useState, useEffect } from "react";
import { Button, Drawer, Table, Tooltip,Space,Select, Input } from "antd";
import AddYear from "./AddYear";
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { yearapi , companyCodeIdGet,yearGetByCompanyId} from "../../../../utils/servies/common";
import { yeardelete } from "../../../../utils/servies/common";
import moment from "moment/moment";
import ConfirmBox from "../../../../UIComponent/ConfirmBox";
import { width } from "@mui/system";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const Year = () => {
  const [selectedBranch, setSelectedBranch] = useState({});
  const [visible, setVisible] = useState(false);
  const [getData, setData] = useState();
  //deletepopup.....
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({})
  const [company, setCompany] = useState();
  const [saveCompany, setSaveCompany] = useState({key:"All"});
  //.........
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });

  const [filterBranch, setFilterBranch] = useState([]);


  const addBranch = () => {
    setVisible(true);
    setSelectedBranch({});
  };
  const backToDashboard = () => {
    setVisible(false);
    setSelectedBranch({});
  };
  const editBranch = (element) => {
    setSelectedBranch(element);
    setVisible(true);
  };
  const viewBranch = (element) => {
    const el = { ...element }
    el.isView = true
    setVisible(true);
    setSelectedBranch(el);
  }

  //delete Popup
  const deleteBranch = (element) => {
    setOpen(true);
    setDeleteData(element)
  };
  const deleteFunction = async () => {
    await yeardelete(deleteData._id).then(() => {
      toast.success("Deleted Successfully")
      setOpen(false)
      dataGet()
    }).catch(() => {
      toast.error("something Went Wrong ")
      setOpen(false)
    })
    dataGet();

  }
  const dataGet = () => {
    yearapi()
      .then((res) => {
        setFilterBranch(res.data)
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    dataGet();
    getCompanyName()
  }, []);
  const getCompanyName = () => {
    companyCodeIdGet()
      .then((res) => {
        setCompany(res.data);
      })
      .catch((err) => console.log(err));
  };

  //filter    
  const onSearch = (searchText) => {
    filterBranch.length ?
      setData(filterBranch.filter(el =>
        `${el.year}`.toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1)
      ) : setData(filterBranch);
  }

  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setConfiguration(config => {
      return {
        ...config,
        filter: event.target.value
      }
    })
  }

  const setcompanyval = (e, key) => {
    setSaveCompany(key)
  }

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      width: 120,
      render: (companyId) => {
        return (
          companyId?.companyName
        )
      }
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      width: 100
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "review",
      render: (_, element) => {
        return moment(element["createdAt"]).format("DD-MMM-YYYY");
      },
      width: 100
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, element, index) => (
        <div className="actionContainer">
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined onClick={() => editBranch(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="Delete">
            <DeleteOutlined onClick={() => deleteBranch(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="view">
            <EyeOutlined onClick={() => viewBranch(element)} />
          </Tooltip>
        </div>
      ),
      key: "action",
      width: 100
    },
  ];
  const getYearBycomp = () => {
    if (saveCompany.key!=="All") {
        yearGetByCompanyId(saveCompany?.key)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
      dataGet()
    }
    
  };
  return (

    <div className="mainContainer">
      <div className="headerContainer row">
        <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
          Year
        </h4>

        <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px"
            }}
            onInput={(event) => searchOnChange(event)}
          >
          </Input><SearchOutlined className='searchSymbol' />&nbsp;&nbsp;
          <Button
            size="large"
            className="button"
            onClick={() => addBranch()}
          >
            Add Year
          </Button>
        </div>
      </div>
      <div className="row mt-3">
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-4 col-xs-4">
              <Select
                showSearch
                className="w-100"
                value={saveCompany}
                onSelect={setcompanyval}
                placeholder="Company"
              >
                <Select.Option value="All" key="All">
                  All
                </Select.Option>
                {company?.length > 0 &&
                  company.map((ele) => {
                    return (
                      <Select.Option value={ele.companyName} key={ele._id}>
                        {ele.companyName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </div>
            <div className="col-lg-3 col-md-3 col-xl-2 col-sm-2 col-xs-2">
              <Space>
                <Button size="large" className="button" style={{ marginLeft: "4px" }} onClick={getYearBycomp}>Submit</Button>
              </Space>
            </div>
        </div>
      <br></br>
      <div className="tableContainer">
        <Table
          columns={columns}
          dataSource={getData}
          
          pagination={{
            pageSize: configuration.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            total: configuration.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}

        />

        <Drawer
          title={`${selectedBranch?._id ? "Edit" : "Add"} Branch`}
          className="drawer-dropDown"
          onClose={() => setVisible(false)}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          zIndex={200}
        >
          {visible ? (
            <AddYear
              formType={selectedBranch.isView ? "view" : selectedBranch?._id ? "edit" : "add"}
              selectedBranch={selectedBranch}
              dataGet={dataGet}
              backToDashboard={backToDashboard}
            />
          ) : null}
        </Drawer>
        <ConfirmBox
          open={open}
          heading={"Delete"}
          closeDialog={() => setOpen(false)}
          Function={deleteFunction}
          Content={"Are you Sure Want Delete"}
          tittle={deleteData.year}
          confirmButton={"Delete"}
        />
      </div>
      <ToastContainer />
    </div>

  );
};

export default Year;
