import React, { useState } from 'react';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button, DatePicker, Dropdown } from "antd";
import { FloatLabel } from "../../UIComponent/_index";
import moment from "moment";

const ViewReports = (props) => {

    const { selectedReport, backToDashBoard } = props

    const [data, setData] = useState(selectedReport);
    return <>
        <div className="formContainer h-100">
            <OverlayScrollbarsComponent className="formBody">
                <Formik
                    innerRef={(ref) => {

                    }}
                >
                    {({
                        values
                    }) => <Form noValidate>
                            <div className="innerContainer row">
                                <div className="col-12 mb-2 ">
                                    <FloatLabel label="Company Name" value={data?.companyId?.companyName}>
                                        <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.companyId?.companyName}

                                        />
                                    </FloatLabel>
                                </div>
                                <div className="col-12 mb-2">
                                    <FloatLabel label="Policy Date" value={data.policyDate} >
                                        {/* <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.policyDate}
                                        /> */}
                                        <DatePicker
                                            className="w-100 form-control"
                                            defaultValue={moment(data?.policyDate, "YYYY-MM-DD")}
                                            disabled
                                        />
                                    </FloatLabel>
                                </div>
                                <div className="col-12 mb-2">
                                    <FloatLabel label="Policy Number" value={data.policyNumber}>
                                        <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.policyNumber}
                                        />
                                    </FloatLabel>
                                </div>
                                <div className="col-12 mb-2">
                                    <FloatLabel label="Customer Name" value={data.customerName}>
                                        <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.customerName}
                                        />
                                    </FloatLabel>
                                </div>
                                <div className="col-12 mb-2">
                                    <FloatLabel label="Registration Number" value={data.RegistrationNumber}>
                                        <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.RegistrationNumber}
                                        />
                                    </FloatLabel>
                                </div>
                                <div className="col-12 mb-2">
                                    <FloatLabel label="OD Premium" value={data.odPremium}>
                                        <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.odPremium}
                                        />
                                    </FloatLabel>
                                </div>
                                <div className="col-12 mb-2">
                                    <FloatLabel label="Net Premium" value={data.netPremium}>
                                        <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.netPremium}
                                        />
                                    </FloatLabel>
                                </div>
                                <div className="col-12 mb-2">
                                    <FloatLabel label="Total Premium" value={data.totalPremium}>
                                        <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.totalPremium}
                                        />
                                    </FloatLabel>
                                </div>
                                <div className="col-12 mb-2">
                                    <FloatLabel label="User Email" value={data.customerEmail}>
                                        <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.customerEmail}
                                        />
                                    </FloatLabel>
                                </div>
                                <div className="col-12 mb-2">
                                    <FloatLabel label="Customer Mobile" value={data?.customerMobile}>
                                        <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.customerMobile}
                                        />
                                    </FloatLabel>
                                </div>
                                <div style={{ paddingBottom: "28px" }} className="col-12 mb-2">
                                    <FloatLabel label="Policy Status" value={data.status}>
                                        <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.status}
                                        />
                                    </FloatLabel>
                                </div>
                            </div>
                        </Form>
                    }
                </Formik>
            </OverlayScrollbarsComponent>
        </div>
        <footer>
            <Button className="cancelButton" onClick={() => backToDashBoard()}>Cancel</Button>
            {/* <Button className="button">
                    {formType === 'edit' ? 'Update' : 'Save'}
                </Button> */}
        </footer>

    </>
}
export default ViewReports;