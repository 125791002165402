import React, { useState, useEffect } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button, Select, Input } from "antd";
import { FloatLabel } from "../../UIComponent/_index";
import {
  branchapi,
  addUserPost,
  addUserUpdate,
} from "../../utils/servies/common";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditOutlined } from "@ant-design/icons";
import ConfirmBox from "../../UIComponent/ConfirmBox";
import { Stack } from "@mui/material";
import { profileGet } from "../../utils/servies/common";


const user = {
  name: "",
  mobile: "",
  email: "",
  userName: "",
  password: "",
  branch: "",
  role: "",
  aadharNumber: "",
  panNumber: "",
  accountHolderName: "",
  accountNumber: "",
  bankName: "",
  bankBranch: "",
  ifsc: "",
  micr: ""
};

const { Option } = Select;
const AddUser = (props) => {
  const { formType, backToDashboard, selectedUser, getData } = props;

  let status =
    formType === "view" ? selectedUser : "edit" ? selectedUser : user;

  const [dropdowns, setDropdowns] = useState([]);
  const [data, setData] = useState(status);
  const [selectBranch, setSelectBranch] = useState(status?.branch?._id);
  const [role, setSaveRole] = useState(status?.role);
  const [updatePass, setUpdatePass] = useState();
  const [disabled, setDisabled] = useState(true);
  const Role = localStorage.getItem('role')
  const [loader, setLoader] = useState(false);
  const [branch, setBranch] = useState()
  const [savePassword, setSavePassword] = useState(status?.password)
  const editPassword = () => {
    setUpdatePass(true);
  };
  const updatePassword = () => {
    setSavePassword()
    setDisabled(false);
    setUpdatePass(false);
  };
  const handleSave = () => {
    if (
      data.name &&
      data.userName &&
      data.password &&
      data.mobile &&
      data.email &&
      data.aadharNumber &&
      data.panNumber &&
      (selectBranch || branch._id) &&
      role
    ) {
      setLoader(true);
      let dataValue = {
        name: data?.name,
        userName: data?.userName,
        password: data?.password,
        mobile: data?.mobile,
        email: data?.email,
        aadharNumber: data?.aadharNumber,
        panNumber: data?.panNumber,
        branch: Role === "manager" ? branch._id : selectBranch,
        role: role,
        aadharNumber: data?.aadharNumber,
        panNumber: data?.panNumber,
        accountHolderName: data?.accountHolderName,
        accountNumber: data?.accountNumber,
        bankName: data?.bankName,
        bankBranch: data?.bankBranch,
        ifsc: data?.ifsc,
        micr: data?.micr
      };
      addUserPost(dataValue)
        .then((res) => {
          setLoader(false);
          getData();
          backToDashboard();
          if (res?.message?.length) {
            toast(res.message);
          }
          //   else {
          //     toast.success("Successfully Saved")

          //   }
        })
        .catch(() => {
          setLoader(false);
          toast.error("Something Went Wrong");
          backToDashboard();
        });
    } else {
      toast.warning("Please Enter All Details");
    }
  };
  const getvalue = () => {
    profileGet()
      .then((res) => {
        // console.log(res?.data?.branch?.branchName,"ssss")
        setBranch(res?.data?.branch);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (localStorage.getItem('role') === 'manager') {
      setSaveRole("agent")
    }
    getvalue()
  }, [])
  useEffect(() => {
    Promise.allSettled([branchapi()]).then((resp) => {
      const data = resp[0].value.data;
      setDropdowns(data);
    });
  }, []);
  const handleCancel = () => {
    backToDashboard();
  };
  const handleSubmit = () => {
    formType === "edit" ? handleUpdate() : handleSave();
  };

  const handleUpdate = () => {
    if (
      data.name &&
      data.userName &&
      savePassword &&
      data.mobile &&
      data.email &&
      data.aadharNumber &&
      data.panNumber &&
      data.accountHolderName &&
      data.micr &&
      data.ifsc &&
      data.bankBranch &&
      data.bankName &&
      data.accountNumber &&
      (selectBranch || branch._id) &&
      role
    ) {
      let dataValue = {
        _id: data?._id,
        name: data?.name,
        userName: data?.userName,
        password: savePassword?.password,
        mobile: data?.mobile,
        email: data?.email,
        aadharNumber: data?.aadharNumber,
        panNumber: data?.panNumber,
        accountNumber: data?.accountNumber,
        bankBranch: data?.bankBranch,
        bankName: data?.bankName,
        ifsc: data?.ifsc,
        micr: data?.micr,
        accountHolderName: data?.accountHolderName,
        branch: Role === "manager" ? branch._id : selectBranch,
        role: role,
      };
      addUserUpdate(dataValue)
        .then((res) => {
          console.log("res", res)
          if (res.error) {
            toast.error(res?.message ? res?.message : "Something Went Wrong");
          } else {
            toast("Successfully Updated");
            getData();
            backToDashboard();
          }
          
        })
        .catch(() => {
          toast.error("Something Went Wrong");
          backToDashboard();
        });
    } else {
      toast.warning("Please Enter All Details");
    }
  };
  return (
    <>
      <div className="formContainer h-100">
        <OverlayScrollbarsComponent className="formBody">
          <Formik innerRef={(ref) => { }}>
            {({ values }) => (
              <Form noValidate>
                <div className="innerContainer row">
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="Name" value={data?.name}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.name}
                        onChange={(e) =>
                          setData({ ...data, name: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="User Name" value={data?.userName}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.userName}
                        onChange={(e) =>
                          setData({ ...data, userName: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  {formType === "add" && (
                    <div className="col-12 mb-2 ">
                      <FloatLabel label="Password" value={data?.password}>
                        <Field
                          type="text"
                          className="w-100 form-control"
                          value={data?.password}
                          onChange={(e) =>
                            setData({ ...data, password: e.target.value })
                          }
                        />
                      </FloatLabel>
                    </div>
                  )}
                  <div className="col-12 mb-2 ">
                    {formType === "edit" && (
                      <FloatLabel label="Password" value={savePassword}>
                        <Input
                          type="text"
                          autoFocus="off"
                          className="w-100 suffixInput"
                          onChange={(e) => {
                            setSavePassword({ ...savePassword, password: e.target.value });
                          }}
                          suffix={<EditOutlined onClick={editPassword} />}
                          disabled={disabled ? true : false}
                        />
                      </FloatLabel>
                    )}
                  </div>

                  <div className="col-12 mb-2">
                    <FloatLabel label="Mobile" value={data?.mobile}>
                      <Field
                        type="text"
                        maxlength={10}
                        className="w-100 form-control"
                        value={data?.mobile}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)  && !event.key.includes(".")) {
                            event.preventDefault();
                          }
                        }}  
                        onChange={(e) => {
                          if (e.target.value.length <= 10 && !isNaN(e.target.value)) {
                            setData({ ...data, mobile: e.target.value })
                          }
                        }
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Email" value={data?.email}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.email}
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Aadhaar Number" value={data?.aadharNumber}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.aadharNumber}
                        onChange={(e) =>
                          setData({ ...data, aadharNumber: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Pancard Number" value={data?.panNumber}>
                      <Field
                        type="text"
                        maxlength="10"
                        className="w-100 form-control"
                        value={data?.panNumber}
                        onChange={(e) =>
                          setData({ ...data, panNumber: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2" hidden={Role === "admin"}  >
                    <FloatLabel label="Branch" value={selectBranch ? selectBranch : branch?.branchName}>
                      <Select
                        name="branch"
                        className="w-100"
                        value={selectBranch ? selectBranch?.branchName : branch?.branchName}
                        // onSelect={(e) => {
                        //   setSelectBranch(e);
                        // }}

                        // disabled={(Role === "manager" && formType === "view" ) ? true : (formType === "view") ? true : (Role === "manager" &&formType === "edit")? true : false}

                        disabled="true"

                      >
                        {/* {dropdowns.map((el) => {
                          return (
                            <Option value={el._id} key={el._id}>
                              {el.branchName}{" "}
                            </Option>
                          );
                        })} */}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2" hidden={Role === "manager"} >
                    <FloatLabel label="Branch" value={selectBranch} >
                      <Select
                        name="branch"
                        className="w-100"
                        value={selectBranch}
                        onSelect={(e) => {
                          setSelectBranch(e);
                        }}
                        disabled={(Role === "manager" && formType === "view") ? true : (formType === "view") ? true : (Role === "manager" && formType === "edit") ? true : false}
                      >
                        {dropdowns.map((el) => {
                          return (
                            <Option value={el._id} key={el._id}>
                              {el.branchName}{" "}
                            </Option>
                          );
                        })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Role" value={role}>
                      <Select
                        name="role"
                        className="w-100"
                        value={role}
                        onSelect={(e) => {
                          setSaveRole(e);
                        }}
                        disabled={(formType === "view" ||  Role!= "admin")  ? true : false}
                      >
                        {Role == 'admin' &&
                          <Option value="manager">MANAGER</Option>
                        }
                        <Option value="agent">AGENT</Option>
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Account Holder Name" value={data.accountHolderName}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.accountHolderName}
                        onChange={(e) =>
                          setData({ ...data, accountHolderName: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Account Number" value={data.accountNumber}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.accountNumber}
                        onChange={(e) =>
                          setData({ ...data, accountNumber: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Bank Name" value={data.bankName}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.bankName}
                        onChange={(e) =>
                          setData({ ...data, bankName: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="Bank Branch" value={data.bankBranch}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.bankBranch}
                        onChange={(e) =>
                          setData({ ...data, bankBranch: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="IFSC Code" value={data.ifsc}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.ifsc}
                        onChange={(e) =>
                          setData({ ...data, ifsc: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-12 mb-2">
                    <FloatLabel label="MICR Number" value={data.micr}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.micr}
                        onChange={(e) =>
                          setData({ ...data, micr: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  {/* <div className="col-12 mb-2">
                                <FloatLabel label="Role" value = {saveRole}>
                                    <Select 
                                    name="role"
                                    className="w-100 form-control"
                                    value={saveRole}
                                    onSelect={(e) => {
                                        setSaveRole(e)}
                                        }
                                    disabled={formType === 'view' ? true : false}>
                                        <Option>Agent</Option>
                                        <Option>Manager</Option>
                                    </Select>
                                </FloatLabel>
                                </div> */}
                </div>
              </Form>
            )}
          </Formik>
        </OverlayScrollbarsComponent>
        <ConfirmBox
          open={updatePass}
          heading={"Update"}
          closeDialog={() => setUpdatePass(false)}
          Function={updatePassword}
          Content={"You are Attempting to Edit Password"}
          confirmButton={"Edit"}
        />
      </div>
      <footer>
        <Button className="cancelButton" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="button"
          onClick={handleSubmit}
          hidden={(formType === "view" || loader == true) ? true : false}
        >
          {formType === "edit" ? "Update" : "Save"}
        </Button>
      </footer>
      <ToastContainer />
      {loader ? (
        <Stack
          style={{
            position: "fixed",
            right: "50%",
            bottom: "50%",
          }}
        >
          <div class="loader">Loading
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Stack>
      ) : null}
    </>
  );
};
export default AddUser;
