import React, { useState, useEffect } from "react";
import Sidebar from "../../../../Common/Sidebar/Sidebar";
import {
  accountMenuItems,
  menuItems,
  navMenuItems,
} from "../../../../Constants/LayoutCommon";
import AddPolicyType from "./AddPolicyType";
import { Button, Drawer, Table, Tooltip, Input } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  policyTypeGet,
  policyTypeDelete,
} from "../../../../utils/servies/common";
import ConfirmBox from "../../../../UIComponent/ConfirmBox";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManagePolicyType = () => {
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });

  const [selectedPolicyType, setSelectedPolicyType] = useState({});
  const [visible, setVisible] = useState(false);
  const [getData, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [filterPolicyType, setFilterPolicyType] = useState([]);

  const dataGet = () => {
    policyTypeGet()
      .then((res) => {
        setFilterPolicyType(res.data);
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    dataGet();
  }, []);

  const AddPolicyTypes = () => {
    setVisible(true);
    setSelectedPolicyType({});
  };

  const editPolicyType = (element) => {
    setVisible(true);
    setSelectedPolicyType(element);
  };
  const backToDashBoard = () => {
    setVisible(false);
    setSelectedPolicyType({});
  };

  //delete popup and function
  const deletePolicyType = (element) => {
    setOpen(true);
    setDeleteData(element);
  };
  const deleteFunction = async () => {
    await policyTypeDelete(deleteData._id)
      .then(() => {
        toast.success("Deleted Successfully ");
        dataGet();
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
    setOpen(false);
  };

  const onSearch = (searchText) => {
    filterPolicyType.length
      ? setData(
        filterPolicyType.filter(
          (el) =>
            `${el.name},${el.description}`
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
        )
      )
      : setData(filterPolicyType);
  };

  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setConfiguration((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };
  const columns = [
    {
      title: "Policy Type",
      dataIndex: "name",
      key: "name",
      width: 100,
      fixed: "left",
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, element) => {
        return moment(element["createdAt"]).format("DD-MMM-YYYY");
      },
      width: 100,
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 100,
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_id, element, index) => (
        <div className="actionContainer">
          {/* <Tooltip placement="bottom" title="Delete">
            <DeleteOutlined onClick={() => deletePolicyType(element)} />
          </Tooltip> */}
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined onClick={() => editPolicyType(element)} />
          </Tooltip>
        </div>
      ),
      key: "action",
      width: 100,
    },
  ];

  return (
    /*<Sidebar
            navTitle={""}
            navMenuItems={navMenuItems}
            menuItems={menuItems}
            accountMenuItems={accountMenuItems}
        >*/
    <div className="mainContainer">
      <div className="headerContainer row">
        <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
          Policy Type
        </h4>
        <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px",
            }}
            onInput={(event) => searchOnChange(event)}
          ></Input>
          <SearchOutlined className="searchSymbol" />
          &nbsp;&nbsp;
          <Button
            size="large"
            className="button"
            onClick={() => AddPolicyTypes()}
          >
            Add PolicyType
          </Button>
        </div>
      </div>
      <br></br>
      <div className="tableContainer">
        <Table
          columns={columns}
          dataSource={getData}
          // scroll={{
          //   x: "90vw",
          // }}
          pagination={{
            pageSize: configuration.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            total: configuration.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        />

        <Drawer
          title={`${selectedPolicyType._id ? "Edit" : "Add"} Policy Type`}
          className="drawer-dropDown"
          onClose={() => setVisible(false)}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          zIndex={200}
        >
          {visible ? (
            <AddPolicyType
              formType={
                selectedPolicyType.isViewAttr
                  ? "view"
                  : selectedPolicyType._id
                    ? "edit"
                    : "add"
              }
              backToDashBoard={backToDashBoard}
              dataGet={dataGet}
              selectedPolicyType={selectedPolicyType}
            />
          ) : null}
        </Drawer>
        <ConfirmBox
          open={open}
          closeDialog={() => setOpen(false)}
          Function={deleteFunction}
          tittle={deleteData.name}
          confirmButton={"Delete"}
          heading={"Delete"}
          Content={"Are you Sure want to Delete"}
        />
      </div>
      <ToastContainer />
    </div>
    //</Sidebar>
  );
};
export default ManagePolicyType;
