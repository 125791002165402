import React, { useEffect, useState } from "react";
import { Button, Select, Table, Input, Tooltip } from "antd";
import { EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  companyLoginCompanyCodeGet,
  companyLoginIdGet,
  companyLoginMainPost,
  companyLoginMainCompanyPost,
  getOtherscompanyLoginId,
  userConfigBookingCode
} from "../../utils/servies/common";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CompanyContact = () => {
  const { Option } = Select;
  const [mapCompanyCode, setMapCompanyCode] = useState();
  const [mapCompany, setMapCompany] = useState();
  const [saveCompany, setSaveCompany] = useState();
  const [saveCompanyCode, setSaveCompanyCode] = useState();
  const [data, setData] = useState();
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });
  const [showLoginTable, setShowLoginTable] = useState(true);
  const [showCompanyContact, setShowCompanyConatct] = useState(false);
  const [filterCompanyLoginMain, setFilterCompanyLoginMain] = useState([]);
  const [showUrl, setShowUrl] = useState(false)
  const nameCompany = () => {
    companyLoginIdGet()
      .then((res) => {
        setMapCompany(res.data);
      })
      .catch((err) => console.log(err));
  };

  const codeCompany = () => {
    companyLoginCompanyCodeGet()
      .then((res) => {
        setMapCompanyCode(res.data);
      })
      .catch((err) => console.log(err));
  };
  const filtercompcode = (id) => {
    userConfigBookingCode(id).then((res) => {
      setMapCompanyCode(res.data);
    }).catch(e => {
      toast.warning("Something Went Wrong")
    })
  }

  const handleView = (element) => {
    console.log(element)

  }
  useEffect(() => {
    codeCompany();
    nameCompany();
  }, []);
  const handleSave = () => {
    setShowCompanyConatct(false);
    setShowLoginTable(true);
    if (saveCompany && saveCompanyCode) {
      var value = {};
      if (saveCompany !== "1001") {
        value.companyId= saveCompany
      } if (saveCompanyCode!== "1001") {
        value.companyCodeId=saveCompanyCode
      }
      companyLoginMainPost(value)
        .then((res) => {
          setData(res.data);
          setFilterCompanyLoginMain(res.data)
          if (res.data?.length) {
            toast("Data Retrieved")
          }
          else {
            toast.warning("No data Retrieved")
          }
        })
        .catch(() => {
          toast.error("Something Went Wrong");
        });
    } else {
      toast.warning("Please Select All Details");
    }
  };
  const handleSubmit = () => {
    setShowCompanyConatct(false);
    setShowLoginTable(true);
    var value = {}
    if (saveCompany && saveCompanyCode) {
      if (saveCompany !== "1001") {
        value.companyId= saveCompany
      } if (saveCompanyCode!== "1001") {
        value.companyCodeId=saveCompanyCode
      }
      getOtherscompanyLoginId(value)
      .then((res) => {
        setData(res.data);
        setFilterCompanyLoginMain(res.data)
        if (res.data?.length) {
          toast("Data Retrieved")
        }
        else {
          toast.warning("No data Retrieved")
        }
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
    } else {
      toast.error("Please Select");
    }

    
  };
  const masterLoginId = () => {
    setShowCompanyConatct(false);
    setShowLoginTable(true);
    if (saveCompany && saveCompanyCode) {
      var value = {userType:"admin"};
      if (saveCompany !== "1001") {
        value.companyId= saveCompany
      } if (saveCompanyCode!== "1001") {
        value.companyCodeId=saveCompanyCode
      }
      companyLoginMainPost(value)
        .then((res) => {
          setData(res.data);
          setFilterCompanyLoginMain(res.data)
          if (res.data?.length) {
            toast("Data Retrieved")
          }
          else {
            toast.warning("No data Retrieved")
          }
        })
        .catch(() => {
          toast.error("Something Went Wrong");
        });
    } else {
      toast.warning("Please Select All Details");
    }
  };

  const handle = () => {
    var value = {}
    if (saveCompany && saveCompanyCode) {
      if (saveCompany !== "1001") {
        value.companyId = saveCompany
      } if (saveCompanyCode !== "1001") {
        value.companyCodeId = saveCompanyCode
      }
      setShowCompanyConatct(true);
      setShowLoginTable(false);
      companyLoginMainCompanyPost(value)
        .then((res) => {
          setData(res.data)
          if (res.data?.length) {
            toast("Data Retrieved")
          }
          else {
            toast.warning("No data Retrieved")
          }
        })
        .catch(() => {
          toast.error("Something Went Wrong");
        });
    } else {
      toast.error("Please Select");
    }
  };

  const onSearch = (searchText) => {
    filterCompanyLoginMain.length ?
      setData(filterCompanyLoginMain.filter(el =>
        `${el?.companyCode},${el.companyId?.companyName},${el?.description},${el?.branch.branchName},${el?.remarks},${el.companyCodeId?.companyCode},${el?.url},${el?.userType}`.toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1)
      ) : setData(filterCompanyLoginMain)
  };
  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setConfiguration((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };

  const openUrl = (element) => {
    window.open(element.url)
  }
  const LoginColumn = [
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      render: (companyId) => {
        return companyId?.companyName;
      },
      width: 100

    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 200
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (url, element) => {
        return (
          <div className="actionContainer">
            <Button className="urlLink" onClick={() => openUrl(element)}>Open</Button>

            {/* <Button onClick={() => handleView(element.url)}>{showUrl ? <span className="urlLink">{element.url}</span> : <span onClick={() => setShowUrl(true)}>Show</span>}</Button> */}
          </div>
        )
      }
    },
    {
      title: "Login Id",
      dataIndex: "loginId",
      key: "loginId",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
    },
  ];

  const companyContactColumn = [
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      render: (companyId) => {
        return companyId?.companyName;
      },
      width: 100
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Company Code",
      dataIndex: "companyCodeId",
      key: "companyCodeId",
      render: (companyCodeId) => {
        return companyCodeId?.companyCode;
      },
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "E-Mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
  ];

  return (
    <>
      <div className="mainContainer">
      <div className="headerContainer row">
        <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
          Company Login
        </h4>
        <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px",
            }}
            onInput={(event) => searchOnChange(event)}
          ></Input>
          <SearchOutlined className="searchSymbol" />
        </div>
      </div>
        <div className="headerContainer row mt-4">
          <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12 col-xs-12">
            <Select
              className="w-100"
              defaultValue="Select company"
              value={saveCompany}
              onSelect={(e) => {
                console.log(e)
                setSaveCompany(e);
                if (e !== "1001") {
                  filtercompcode(e)
                } else {
                  codeCompany()
                }
              }}
            >
              <Option value="1001">All</Option>;
              {mapCompany?.length > 0 &&
                mapCompany.map((ele) => {
                  return <Option value={ele._id} key={ele._id}>{ele.companyName}</Option>;
                })}
            </Select>
          </div>
          <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12 col-xs-12">
            <Select
              className="w-100"
              defaultValue="Select Company Code"
              value={saveCompanyCode}
              onSelect={(e) => {
                setSaveCompanyCode(e);
              }}
            >
              <Option value="1001" >All</Option>;
              {mapCompanyCode?.length > 0 &&
                mapCompanyCode.map((el) => {
                  return (
                    <Option value={el._id} key={el._id}>
                      {el.companyCode}
                    </Option>
                  );
                })}
            </Select>
          </div>
          
        </div>
        <div>
        <div className="row mt-3">
            {localStorage.getItem('role') == 'admin' &&
              <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
                <Button className="w-100 reportAllbutton" onClick={masterLoginId}>
                  Admin Login Id's
                </Button>
              </div>
              }
            {/* &nbsp;&nbsp; */}
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
              <Button size="medium" className="w-100 reportAllbutton" onClick={handleSave}>
                  {localStorage.getItem('role') == 'admin' ? "All" : "My"} Login Id's
                </Button>
            </div>
              
            {/* &nbsp;&nbsp; */}
            {localStorage.getItem('role') !== 'admin' &&
                <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
                <Button size="medium" className="w-100 reportAllbutton" onClick={handleSubmit}>
                  Other Login Id's
                </Button>
              </div>
            }
              
            {/* &nbsp;&nbsp; */}
            <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex">
              <Button size="medium" className="w-100 reportAllbutton" onClick={handle}>
                CompanyContact
              </Button>
            </div>
              
            {/* </div> */}
          </div>
        </div>
        
        <div className="headerContainer row">
          
        </div>
        <br></br>
        <div className="tableContainer">
          {showLoginTable ? (
            <Table
              columns={LoginColumn}
              dataSource={data}
              scroll={{
                x: "5vw",
              }}
              pagination={{
                pageSize: configuration.pagination.pageSize || 10,
                showQuickJumper: true,
                responsive: true,
                total: configuration.length,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
              }}
            />
          ) : null}

          {showCompanyContact ? (
            <Table
              columns={companyContactColumn}
              dataSource={data}
              scroll={{
                x: "5vw",
              }}
              pagination={{
                pageSize: configuration.pagination.pageSize || 10,
                showQuickJumper: true,
                responsive: true,
                total: configuration.length,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
              }}
            />
          ) : null}
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default CompanyContact;
