import React, { useState, useEffect } from "react";
import "./Dashboard.scss";
import { Grid } from "@mui/material";
import {
  notificationget,
  pendingPolicy,
  renewalPolicy,
  policyCreateUserGet,
  myPremium,
  myPremiumType,
  totalPolicyYear,
  totalPolicyMonth,
  policyStatus,
  renewalPolicyForMonth,
  renewalPolicyForMonthAndDayByproduct,
  tokenVerify
} from "../../../utils/servies/common";
import { Space, DatePicker } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";


export default function Dashboard() {
  const navigate = useNavigate();
  const current = new Date();
  const endDatee = `${current.getFullYear()}/${current.getMonth() + 1
    }/${current.getDate()}`;
  const startDatee = `${current.getFullYear()}/${current.getMonth() + 1}/${1}`;
  const { RangePicker } = DatePicker;
  const [notification, setNotification] = useState();
  const [saveDate, setSaveDate] = useState([startDatee, endDatee]);
  const [pendingPolicyData, setPendingPolicyData] = useState({});
  const [renewalPolicyData, setRenewalPolicyData] = useState();
  const [policy, setPolicy] = useState();
  const [premium, setPremium] = useState();
  const [premiumData, setPremiumData] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [savePolicyStatus, setSavePolicyStatus] = useState()
  const [saveRenewalPolicy, setSaveRenewalPolicy] = useState()
  const [saveRenewalProduct, setSaveRenewalProduct] = useState()
  const [dateValue, setDateValue] = useState([startDatee, endDatee])
  const [countApprove, setCountApprove] = useState()
  const [countRejected, setCountRejected] = useState()
  const [countPending, setCountPending] = useState()
  const [countvalue, setCountvalue] = useState()
  const [days, setDays] = useState({})
  const Notifications = async () => {
    notificationget()
      .then(async (res) => {
        let rowdata = [];
        await res.data.map((ele) => {
          rowdata.push(ele.description);
          rowdata.push("‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ");
        });
        setNotification(rowdata);
      })
      .catch((err) => console.log(err));
  };
  const PendingPolicy = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
      };
      pendingPolicy(value).then((res) => {
        setPendingPolicyData(res.data);
      });
    } else {
      console.log("error");
    }
  };

  const policyCreate = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
      };
      policyCreateUserGet(value)
        .then((res) => {
          setPolicy(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("error");
    }
  };
  const RenewalPolicy = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
      };
      renewalPolicy(value).then((res) => {
        setRenewalPolicyData(res?.data)
      });
    } else {
      console.log("err");
    }
  };
  const premiumType = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
      };
      myPremiumType(value)
        .then((res) => {
          setPremium(res?.data)
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("done11");
    }
  };
  const myPremiumData = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
      };
      myPremium(value)
        .then((res) => {
          setPremiumData(res?.data[0])
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Done");
    }
  };
  const myPolicyStatus = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
      };
      policyStatus(value)
        .then((res) => {
          setSavePolicyStatus(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Done");
    }
  };
  const myrenewalPolicyForMonth = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
      };
      renewalPolicyForMonth(value)
        .then((res) => {
          setSaveRenewalPolicy(res?.data)
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Done");
    }
  };
  const myrenewalPolicyForMonthAndDayByproduct = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
      };
      renewalPolicyForMonthAndDayByproduct(value)
        .then((res) => {
          setSaveRenewalProduct(res?.data)
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Done11");
    }
  };
  const policyYear = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
      };
      totalPolicyYear(value)
        .then((res) => {
          setYear(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("error");
    }
  };
  const policyMonth = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
      };
      totalPolicyMonth(value)
        .then((res) => {
          setMonth(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const onChange = (value, dateString) => {
    setSaveDate(value)
    setDateValue(dateString)
  };


  useEffect(() => {
    const countva =
      savePolicyStatus?.find
        ((ele) => {
          return ele._id === "entry pending"
        })
    const appr =
      savePolicyStatus?.find
        ((ele) => {
          return ele._id === "approved"
        })
    const countReject =
      savePolicyStatus?.find
        ((ele) => {
          return ele._id === "rejected"
        })

    const countPend =
      savePolicyStatus?.find
        ((ele) => {
          return ele._id === "pending"
        })
    setCountApprove(appr)
    setCountPending(countPend)
    setCountvalue(countva)
    setCountRejected(countReject)
  }, [savePolicyStatus])

  useEffect(() => {
    tokenVerify().then(res => {
      if (res.status === 401) {
        toast.warning('Session Expired  ')
        localStorage.clear()
        navigate('/login')
      }
    }).catch(e => {
      toast.error('Something Went Wrong')
      navigate('/login')
    })

    Notifications();
    // PendingPolicy();
    RenewalPolicy();
    policyCreate();
    premiumType();
    myPremiumData();
    myPolicyStatus();
    myrenewalPolicyForMonth();
    myrenewalPolicyForMonthAndDayByproduct()

  }, [saveDate]);

  return (
    <>
      <div>
        <marquee
          className="marquee"
          style={{ fontSize: "34px", color: "#0d45a5" }}
        >
          {notification}
        </marquee>
      </div>
      <Space direction="vertical" size={12}>
        <RangePicker
          format="DD-MM-YYYY"
          onChange={onChange}
          style={{ height: "38px" }}
        />
      </Space>
      <div className="flip-card">

        <div className="bigbox">
            <div className="card">
              <div className="imgBox">
                <div className="first">
                  <h1>PREMIUM</h1>
                  <div className="center-content">
                    <div className="premium">
                      <h2>Premium</h2>
                      <h2>
                        {
                          premiumData?.totalPremium
                        }
                      </h2>
                    </div>
                    <div className="count">
                      <h2>count</h2>
                      <h2>
                        {premiumData?.count}
                        {/* {premiumData?.count?.length > 0
                          ? premiumData[0]?.count
                          : 0} */}
                      </h2>
                    </div>
                  </div>
                  <h1 className="view">View Count</h1>
                </div>
                <div className="Second">
                  <h1>Policy Premium</h1>
                  <div className="policy-content">
                    {premium?.map((ele, i) => {
                      return <div key={i}>{ele?.totalPremium} &nbsp;&nbsp; {ele?._id[0]?.productName}</div>
                    })}
                    {/* <h2>Policy Count : {"4"}</h2> */}
                  </div>
                </div>
              </div>
              <div className="details">
                <h1>Policy Count </h1>
                <div className="policy-content">
                  {premium?.map((ele, i) => {
                    return <div key={i}>{ele?.count} &nbsp;&nbsp; {ele?._id[0]?.productName}</div>
                  })}
                  {/* <h2>Policy Count : {"4"}</h2> */}
                </div>
              </div>
            </div>

          <ToastContainer />
 
        
            <div className="card cards">
              <div className="imgBox">
                <div className="first">
                  <h1>RENEWAL POLICY</h1>
                  <div className="center-content">
                    <div className="premium">
                      <h2> Month</h2>
                      <h2>{`${dateValue[0]}`}<br></br>{`${dateValue[1]}`}
                      </h2>
                      <h2>{saveRenewalPolicy?.month?.map((ele) => {
                        return ele.count
                      })}</h2>
                    </div>
                    <div className="count">
                      <h2>Today</h2>
                      <h2>{`${dateValue[1]}`}<br></br></h2>
                      <h2>{saveRenewalPolicy?.day?.map((ele) => {
                        return ele.count
                      })}</h2>
                    </div>
                  </div>
                  <h1 className="view">View Count</h1>
                </div>
                <div className="Second">
                  <h1>Policy Premium</h1>
                  <div className="policy-content">
                    {saveRenewalProduct?.month?.map((ele, i) => {
                      return <div key={i}>{ele?.totalPremium} &nbsp;&nbsp; {(ele?._id) ? (ele?._id[0]?.productName) : null} </div> //{ele?._id[0]?.productName}
                    })}
                  </div>
                </div>
              </div>
              <div className="details">
                <h1>Policy Count </h1>
                <div className="policy-content">
                  {saveRenewalProduct?.month?.map((ele, i) => {
                    return <div key={i}>{(ele?.count) ? (ele?.count) : null} &nbsp;&nbsp; {(ele?._id) ? (ele?._id[0]?.productName) : null} </div> //{ele?._id[0]?.productName}
                  })}
                </div>
              </div>
        </div>

        </div>



        <Grid container>    
          <Grid xs={12} md={6} marginTop={4}>
            <div className="card">
              <div className="imgBox">
                <div className="first">
                  <h1>POLICY STATUS</h1>
                  <div className="center-content">
                    <div className="premium">
                      <h2>
                        Entry <br></br>Pending
                      </h2>
                      <h2>{(countvalue?._id === "entry pending") ?
                        (countvalue?.count) : "0"
                      }</h2>
                    </div>
                    <div className="count">
                      <h2>
                        Pending
                      </h2>
                      <h2>{(countPending?._id === "pending") ?
                        (countPending?.count) : "0"
                      }</h2>
                    </div>
                    <div className="count">
                      <h2>
                        Approved
                      </h2>
                      <h2>{(countApprove?._id === "approved") ?
                        (countApprove?.count) : "0"
                      }</h2>
                    </div>
                  </div>
                  
                  
                  <h1 className="view">View Count</h1>
                </div>
                <div className="Second">
                  <h1>Policy</h1>
                  <div className="policy-content">
                    <div>Approved : {(countApprove?._id === "approved") ?
                      (countApprove?.count) : "0"}
                    </div>
                    {/* <h2>Pending : {"4"}</h2> */}
                    <div>Entry Pending : {(countvalue?._id === "entry pending") ?
                      (countvalue?.count) : "0"
                    }
                    </div>
                    <div>Pending : {(countPending?._id === "pending") ?
                      (countPending?.count) : "0"
                    }
                    </div>
                    <div>Rejected : {(countRejected?._id === "rejected") ?
                      (countRejected?.count) : "0"
                    }
                    </div>
                  </div>
                </div>
              </div>
              <div className="details">
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
