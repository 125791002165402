import React, { useEffect, useState } from 'react';
import { Table, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from 'react-router-dom';
import { transactionPostmanager as transactionPost } from '../../utils/servies/common';
import { toast, ToastContainer } from 'react-toastify';
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import { Space, DatePicker } from "antd";

const ManagerTransaction = () => {
  const location = useLocation()
  const { RangePicker } = DatePicker;
  const [data, setData] = useState()
  const [csvData,setCsvData] = useState()

  const [dateValue, setDateValue] = useState()
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });
  const [filterTransfer, setFilterTransfer] = useState([]);
  const Navigate = useNavigate()
  const backbut = () => {
    Navigate('/payout')
  }
  const getData = async() => {
    if (dateValue) {
      let value = {
        userId: location.state,
        startDate: dateValue[0],
        endDate: dateValue[1],
      }
      transactionPost(value).then(async(res) => {
        if (res.data.length) {
          setData(res.data)
          setFilterTransfer(res.data)
          toast("Successfully Retrieved")
          var pushdata=[]
          await res.data.map(ele => {
            ele.policyNumber = `'` + ele.policyNumber
            ele.policyDate=moment(ele["policyDate"]).format("DD-MMM-YYYY")
            pushdata.push(ele)
          })
          setCsvData(pushdata)
        }
        else {
          toast.warning("No data to Retrieved")
        }
      })
        .catch((error) => {
          console.log(error)
        })
    }
    else {
      let value = {
        userId: location.state
      }
      transactionPost(value).then(async(res) => {
        if (res.data.length) {
          setData(res.data)
          setFilterTransfer(res.data)
          var pushdata=[]
          await res.data.map(ele => {
            ele.policyNumber = `'` + ele.policyNumber
            ele.policyDate=moment(ele["policyDate"]).format("DD-MMM-YYYY")
            pushdata.push(ele)
          })
          setCsvData(pushdata)
          toast("Successfully Retrieved")
        }
        else {
          toast.warning("No data to Retrieved")
        }
      })
        .catch((error) => {
          console.log(error)
        })

    }
  }
  const onChange = (value, dateString) => {
    //  setSaveDate(value)
    setDateValue(value)
  };
  const onSearch = (searchText) => {
    filterTransfer.length
      ? setData(
        filterTransfer.filter(
          (el) =>
            `${el.RegistrationNumber},${el?.companyId?.companyName},${el.customerName},${el.policyNumber},${el.odPremium},${el.netPremium},${el.totalPremium},${el.customerEmail},${el.status},${el.policyDate}`
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1)
      )
      : setData(filterTransfer);
  };

  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setConfiguration((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };

  const headers = [
    { label: 'Approved Date', key: "approvedDate" },
    { label: 'Policy Number', key: "policyNumber" },
    { label: 'Policy Date', key: "policyDate" },
    { label: 'Od Premium', key: "odPremium" },
    { label: 'TP Premium', key: "tpPremium" },
    { label: 'Net Premium', key: "netPremium" },
    { label: 'Total Premium', key: "totalPremium" },
    { label: 'Registration Number', key: "RegistrationNumber" },
    { label: 'payment Mode', key: "paymentMode" },
    {label: 'CashMode Amount', key: "CashModeAmount"},
    { label: 'User Email', key: "customerEmail" },
    { label: "Agent Share", key: "agentShare" },
    { label: "Available Balance", key: "walltetBalance" },
    { label: 'Paid Amount', key: "paidAmount" },
  ]
  const csvFile = {
    filename: "file.csv",
    headers: headers,
    data: (csvData) ? csvData : ""
  }
  useEffect(() => {
    getData()
  }, [dateValue])
  const columns = [
    // {
    //   title: "Agent",
    //   dataIndex: "userId",
    //   key: "userId",
    //   render: (_, element) => {
    //     return element?.userId?.userName
    //   },
    // },
    {
      title: "Approved Date",
      dataIndex: "approvedDate",
      key: "approvedDate",
      render: (_, element) => {
        return moment(element["approvedDate"]).format("DD-MMM-YYYY");
      },
    },

    {
      title: "Policy Number",
      dataIndex: "policyNumber",
      key: "policyNumber",
    },
    {
      title: "Policy Date",
      dataIndex: "policyDate",
      key: "policyDate",
      render: (_, element) => {
        return moment(element["policyDate"]).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Company",
      dataIndex: "companyId",
      key: "companyId",
      render: (_, element) => {
        if (element?.companyId?.companyName != (undefined || null || " ")) {
          return element?.companyId?.companyName
        } else {
          return ""
        }

      },
    },
    // {
    //   title: "OD Premium",
    //   dataIndex: "odPremium",
    //   key: "odPremium",
    // },
    // {
    //   title: "TP Premium",
    //   dataIndex: "tpPremium",
    //   key: "tpPremium",
    // },
    // {
    //   title: "NET Premium",
    //   dataIndex: "netPremium",
    //   key: "netPremium",
    // },
    {
      title: "Total Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
    },
    {
      title: "Agent Share ",
      dataIndex: "agentShare",
      key: "agentShare",
    },
    {
      title: "Manager Share",
      dataIndex: "managerShare",
      key: "managerShare",
    },
    {
      title: "Remarks",
      dataIndex: "paidAmountRemarks",
      key: "paidAmountRemarks",
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
    },
    {
      title: "Agent Balance",
      dataIndex: "walltetBalance",
      key: "walltetBalance",
    },
    {
      title: "Manager Balance",
      dataIndex: "managerWalletBalance",
      key: "managerWalletBalance",
    }
  ];
  return <>
    <div className="mainContainer">
      <div className="headerContainer row">
        <h4 className="col-lg-4 col-md-4 col-xl-4 col-sm-12 col-xs-12 headerTitle">
          Transaction
          <div>
            <Button size="medium" className="button" style={{ marginLeft: "1px" }} onClick={backbut}>
              ← Back
            </Button>
          </div>
        </h4>
        <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12 col-xs-12 ">
          <Space direction="vertical" size={12}>
            <RangePicker
              format="DD-MM-YYYY"
              onChange={onChange}
              style={{ height: "38px" }}
            />
          </Space>
        </div>

        <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12 col-xs-12 d-flex justify-content-end">
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px",
            }}
            onInput={(event) => searchOnChange(event)}
          ></Input>
          {/* <SearchOutlined className="searchSymbol" /> */}
          &nbsp;&nbsp;
          <Button className="csvButton">
            <CSVLink className="csv" {...csvFile}>Export All File</CSVLink>
          </Button>
        </div>
      </div>

      <div className="tableContainer" style={{ marginTop: "40px" }}>
        <Table
          columns={columns}
          dataSource={data}
          scroll={{
            x: "95vw",
          }}
          pagination={{
            pageSize: configuration.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            total: configuration.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        />
      </div>
      <ToastContainer />
    </div>
  </>
}

export default ManagerTransaction;