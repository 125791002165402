import React, { useEffect, useState } from "react";
import { Typography, Input, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import IconButton from "@mui/material/IconButton";
import { Avatar } from "@mui/material";
import Logo from "../../Resources/Images/Logo.png";
import { profileGet } from "../../utils/servies/common";
import { profileUpdate } from "../../utils/servies/common";
import ConfirmBox from "../../UIComponent/ConfirmBox";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
  const [data, setData] = useState();
  const [pass, setPass] = useState();
  const [open, setOpen] = useState(false);
  const [updatePass, setUpdatePass] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const getvalue = () => {
    profileGet()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  useEffect(() => {
    getvalue();
  }, []);

  const UpdateUser = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    if (!pass) {
      let dataa = {
        ...data,
        bankBranch: data.bankBranch,
        accountNumber: data.accountNumber,
        bankName: data.bankName,
        ifsc: data.ifsc,
        micr: data.micr,
        aadharNumber: data?.aadharNumber,
        p:data?.panNumber
      };
      delete dataa.password;
      delete dataa.branch;
      delete dataa.role;
      delete dataa.userName;
      delete dataa.walletBalance;
      delete dataa.createdAt;
      delete dataa.isUserActive;
      delete dataa.isAdmin;
      profileUpdate(dataa, dataa?._id)
        .then((res) => {
          setOpen(false);
          profileGet()
            .then((resp) => {
              setData(resp.data);
            })
            .catch((err) => {
              console.log(err);
            });

          toast("Updated Successfully");
        })
        .catch(() => {
          toast.error("something Went Wrong");
        });
    } else {
      let updateData = {
        ...data,
        password: pass.password,
        bankBranch: data.bankBranch,
        accountNumber: data.accountNumber,
        bankName: data.bankName,
        ifsc: data.ifsc,
        micr: data.micr,
        aadharNumber: data?.aadharNumber,
        p:data?.panNumber
      };
      delete updateData.branch;
      delete updateData.role;
      delete updateData.userName;
      delete updateData.walletBalance;
      delete updateData.createdAt;
      delete updateData.isUserActive;
      delete updateData.isAdmin;
      profileUpdate(updateData, updateData?._id)
        .then((res) => {
          setOpen(false);
          profileGet()
            .then((resp) => {
              setData(resp.data);
              localStorage.setItem("userName", resp.data.name);
            })
            .catch((err) => {
              console.log(err);
            });

          toast("Updated Successfully");
        })
        .catch(() => {
          toast.error("something Went Wrong");
        });
    }
  };

  const editPassword = () => {
    setUpdatePass(true);
  };
  const updatePassword = () => {
    setDisabled(false);
    setUpdatePass(false);
  };
  return (
    <>
      <h4>Profile</h4>
      <div className="mainContainer d-flex justify-content-center">
        <div className="ProfileContainer">
          <div className="row">
            <div
              className={
                data?.isAdmin === true
                  ? "col-lg-12 col-md-12 col-xl-12 col-sm-12 col-xs-12"
                  : "col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12"
              }
            >
              <span
                className="profile"
                style={{ paddingTop: "12px", fontWeight: "600" }}
              >
                Personal Details
              </span>
              <div className="d-flex mb-4 mt-4">
                <Typography style={{ width: "220px" }}>Name</Typography>
                <Input
                  value={data?.name}
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                  }}
                />
              </div>
              <div className="d-flex mb-4">
                <Typography style={{ width: "220px" }}>Mobile</Typography>
                <Input
                  value={data?.mobile}
                  onChange={(e) => {
                    if (e.target.value.length > 10) {
                      
                    } else {
                      setData({ ...data, mobile: e.target.value });
                    }
                  }}
                />
              </div>
              <div className="d-flex mb-4">
                <Typography style={{ width: "220px" }}>Aadhaar Number</Typography>
                <Input value={data?.aadharNumber}
                onChange={(e) => {
                  setData({ ...data, aadharNumber: e.target.value });
                  }}
                />
              </div>
              <div className="d-flex mb-4">
                <Typography style={{ width: "220px" }}>Pancard Number</Typography>
                <Input value={data?.panNumber}
                onChange={(e) => {
                  setData({ ...data, panNumber: e.target.value });
                  }}
                />
              </div>
              <div className="d-flex mb-4">
                <Typography style={{ width: "220px" }}>E-mail</Typography>
                <Input value={data?.email} disabled />
              </div>
              <div className="d-flex mb-4">
                <Typography style={{ width: "220px" }}>Branch</Typography>
                <Input value={data?.branch?.branchName} disabled />
              </div>
              <div className="d-flex mb-4">
                <Typography style={{ width: "220px" }}>Role</Typography>
                <Input value={data?.role.toUpperCase()} disabled />
              </div>
              <div className="d-flex mb-4">
                <Typography style={{ width: "220px" }}>Password</Typography>
                <Input
                  onChange={(e) => {
                    setPass({ ...pass, password: e.target.value });
                  }}
                  suffix={<EditOutlined onClick={editPassword} />}
                  disabled={disabled ? true : false}
                />
              </div>
              { data?.isAdmin === true ?
                 <div className="d-flex justify-content-end">
                 <Button onClick={UpdateUser} className="button">
                   Update
                 </Button>
               </div> : null
              }
            </div>

            {data?.isAdmin === false ? (
              <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12">
                <span
                  className="profile"
                  style={{ paddingTop: "12px", fontWeight: "600" }}
                >
                  Bank Details
                </span>
                <div className="d-flex mb-4 mt-4">
                  <Typography style={{ width: "220px" }}>
                    Account Holder Name
                  </Typography>
                  <Input
                    value={data?.accountHolderName}
                    onChange={(e) => {
                      setData({ ...data, accountHolderName: e.target.value });
                    }}
                  />
                </div>
                <div className="d-flex mb-4">
                  <Typography style={{ width: "220px" }}>
                    Account Number
                  </Typography>
                  <Input
                    value={data?.accountNumber}
                    onChange={(e) => {
                      setData({ ...data, accountNumber: e.target.value });
                    }}
                  />
                </div>
                <div className="d-flex mb-4">
                  <Typography style={{ width: "220px" }}>Bank Name</Typography>
                  <Input
                    value={data?.bankName}
                    onChange={(e) =>
                      setData({ ...data, bankName: e.target.value })
                    }
                  />
                </div>
                <div className="d-flex mb-4">
                  <Typography style={{ width: "220px" }}>Branch</Typography>
                  <Input
                    value={data?.bankBranch}
                    onChange={(e) =>
                      setData({ ...data, bankBranch: e.target.value })
                    }
                  />
                </div>
                <div className="d-flex mb-4">
                  <Typography style={{ width: "220px" }}>IFSC Code</Typography>
                  <Input
                    value={data?.ifsc}
                    onChange={(e) => setData({ ...data, ifsc: e.target.value })}
                  />
                </div>
                <div className="d-flex mb-4">
                  <Typography style={{ width: "220px" }}>
                    MICR Number
                  </Typography>
                  <Input
                    value={data?.micr}
                    onChange={(e) => setData({ ...data, micr: e.target.value })}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Button onClick={UpdateUser} className="button">
                    Update
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <ToastContainer />
      <ConfirmBox
        open={open}
        heading={"Update"}
        closeDialog={() => setOpen(false)}
        Function={handleSubmit}
        Content={"Are you Sure You Want Update"}
        confirmButton={"Update"}
      />
      <ConfirmBox
        open={updatePass}
        heading={"Update"}
        closeDialog={() => setUpdatePass(false)}
        Function={updatePassword}
        Content={"You are Attempting to Edit Password"}
        confirmButton={"Edit"}
      />
    </>
  );
};

export default Profile;
