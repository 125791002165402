import React, { useState } from "react";
import "./UIComponent.scss";

const FloatLabel = props => {
    const [focus, setFocus] = useState(false);
    const { children, label, value } = props;
    const labelClass =
        focus || (value != null && value != undefined && value.length !== 0) ? "label label-float" : "label label";

    return (
        <div
            className="float-label"
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
        >
            {children}
            <label className={labelClass}>{label}</label>
        </div>
    );
};

export default FloatLabel;
