import React, { useState, useEffect } from "react";
import Sidebar from "../../../../Common/Sidebar/Sidebar";
import {
  accountMenuItems,
  menuItems,
  navMenuItems,
} from "../../../../Constants/LayoutCommon";
import { Button, Drawer, Table, Tooltip, Input } from "antd";
import AddCompanyLogin from "./AddCompanyLogin";

import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  companyLoginGet,
  companyLoginDelete,
} from "../../../../utils/servies/common";
import ConfirmBox from "../../../../UIComponent/ConfirmBox";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManageCompanyLogin = () => {
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });
  const [selectedCompanyLogin, setSelectedCompanyLogin] = useState({});
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [filterCompanyLogin, setFilterCompanyLogin] = useState([]);

  const getValue = () => {
    companyLoginGet()
      .then((res) => {
        setFilterCompanyLogin(res.data);
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const addUser = () => {
    setVisible(true);
    setSelectedCompanyLogin({});
  };

  const editUser = (element) => {
    setVisible(true);
    setSelectedCompanyLogin(element);
  };

  const viewUser = (element) => {
    setVisible(true);
    const el = { ...element };
    el.isViewAttr = true;
    setSelectedCompanyLogin(el);
  };

  const backtoDashBoard = () => {
    setVisible(false);
    setSelectedCompanyLogin({});
  };

  useEffect(() => {
    getValue();
  }, []);

  const deleteUser = (element) => {
    setOpen(true);
    setDeleteData(element);
  };
  const deleteFunction = async () => {
    await companyLoginDelete(deleteData._id)
      .then(() => {
        toast.warning("Deleted Successfully");
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
    setOpen(false);
    getValue();
  };

  const onSearch = (searchText) => {
    filterCompanyLogin.length
      ? setData(
        filterCompanyLogin.filter(
          (el) =>
            `${el?.branch?.branchName},${el?.companyId?.companyName},${el?.companyCodeId?.companyCode},${el.remarks},${el.url}`
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
        )
      )
      : setData(filterCompanyLogin);
  };

  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setConfiguration((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };

  const openUrl = (element) => {
    window.open(element.url);
  };
  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      render: (companyId) => {
        return companyId?.companyName;
      },
    },
    {
      title: "company Code",
      dataIndex: "companyCodeId",
      key: "companyCodeId",
      render: (companyCodeId) => {
        return companyCodeId?.companyCode;
      },
    },
    // {
    //   title: "Branch",
    //   dataIndex: "branch",
    //   key: "branch",
    //   render: (branch) => {
    //     return branch?.branchName;
    //   },
    // },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: " URL",
      dataIndex: "url",
      key: "url",
      render: (url, element) => {
        return (
          <Button className="urlLink" onClick={() => openUrl(element)}>
            Open
          </Button>
        );
      },
    },
    {
      title: "Login Id",
      dataIndex: "loginId",
      key: "loginId",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Agents",
      dataIndex: "userId",
      key: "userId",
      render: (element) => {
        return (element?.map(ele => {
          return " " + ele['userName']
        }))
      }
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_, element, index) => (
        <div className="actionContainer">
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined onClick={() => editUser(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="view">
            <EyeOutlined onClick={() => viewUser(element)} />
          </Tooltip>
          <Tooltip placement="bottom" title="view">
            <DeleteOutlined onClick={() => deleteUser(element)} />
          </Tooltip>
        </div>
      ),
      key: "action",
    },
  ];
  return (
    /*<Sidebar
      navTitle={""}
      navMenuItems={navMenuItems}
      menuItems={menuItems}
      accountMenuItems={accountMenuItems}
    >*/
    <div className="mainContainer">
      <div className="headerContainer row">
        <h4 className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 headerTitle">
          Company Login
        </h4>
        <div className="col-lg-6 col-md-6 col-xl-6 col-sm-12 col-xs-12 d-flex justify-content-end">
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "280px",
            }}
            onInput={(event) => searchOnChange(event)}
          ></Input>
          <SearchOutlined className="searchSymbol" />
          &nbsp;&nbsp;
          <Button size="large" className="button" onClick={() => addUser()}>
            Add CompanyLogin
          </Button>
        </div>
      </div>
      <br></br>
      <div className="tableContainer">
        <Table
          columns={columns}
          dataSource={data}
          // scroll={{
          //   x: "90vw",
          // }}
          pagination={{
            pageSize: configuration.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            total: configuration.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        />
        <Drawer
          title={`${selectedCompanyLogin.isViewAttr
            ? "View"
            : selectedCompanyLogin.companyName
              ? "Edit"
              : "Add"
            } Company Login`}
          className="drawer-dropDown"
          size={null}
          onClose={() => setVisible(false)}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          zIndex={200}
        >
          {visible ? (
            <AddCompanyLogin
              formType={
                selectedCompanyLogin.isViewAttr
                  ? "view"
                  : selectedCompanyLogin._id
                    ? "edit"
                    : "add"
              }
              backtoDashBoard={backtoDashBoard}
              selectedCompanyLogin={selectedCompanyLogin}
              getValue={getValue}
            />
          ) : null}
        </Drawer>
        <ConfirmBox
          open={open}
          closeDialog={() => setOpen(false)}
          Function={deleteFunction}
          tittle={""}
          confirmButton={"Delete"}
          heading={"Delete"}
          Content={"Are you Sure want to Delete"}
        />
      </div>
      <ToastContainer />
    </div>
    //</Sidebar>
  );
};

export default ManageCompanyLogin;
