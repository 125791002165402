import * as React from "react";
import { Grid } from "@mui/material";
import {
  accountMenuItems,
  menuItemsCommon
} from "../../Constants/LayoutCommon";
import { menuItemsAdmin } from "../../Constants/LayoutAdmin";
import { useLocation } from "react-router-dom";
import login from "../../Pages/Login/Login"

const elements = {
  "/login":login
};

export default function Login() {
  const [currentEl, setCurrentEl] = React.useState();
  const location = useLocation();

  React.useEffect(() => {
    if (
      location.pathname &&
      Object.keys(elements).includes(location.pathname)
    ) {
      setCurrentEl(location.pathname);
    }
  }, [location]);

  React.useEffect(() => {
    if (currentEl) window.history.replaceState(null, currentEl.split('/')[1], currentEl);
  }, [currentEl]);

  React.useEffect(() => {}, [currentEl]);

  const Element = elements[currentEl] && elements[currentEl];

  return (
    <div
      navTitle={"Login"}
      menuItems={menuItemsCommon}
      accountMenuItems={accountMenuItems}
      setCurrentMenu={setCurrentEl}
    >
      <Grid container p={{ xs: 0, md: 0 }}>
        <Grid item xs={12}>
          {Element && <Element />}
        </Grid>
      </Grid>
    </div>
  );
}
