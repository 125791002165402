import React, { useEffect, useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button, Select } from "antd";
import { FloatLabel } from "../../../../UIComponent/_index";
import { companyCodePost } from "../../../../utils/servies/common";
import { companyCodeIdGet } from "../../../../utils/servies/common";
import { companyCodeUpdate } from "../../../../utils/servies/common";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../../../../Resources/Loader/Loader";
const { Option } = Select;
const cmpCode = {
  companyCode: "",
  description: "",
};
const AddCompanyCode = (props) => {
  const { formType, selectedCompanyCode, getValue, backToDashBoard } = props;

  let campCode = formType === "edit" ? selectedCompanyCode : cmpCode;
  const [data, setData] = useState(campCode);
  const [company, setCompany] = useState();
  const [companyName, setCompanyName] = useState(campCode?.companyId?._id);
  const [open, setOpen] = useState(false);
  const handleSave = () => {
    if (companyName && data?.companyCode) {
      let value = {
        companyId: companyName,
        companyCode: data.companyCode,
        description: data.description,
      };
      setOpen(true);
      companyCodePost(value)
        .then((res) => {
          if (!res.message) {
            toast.success("Successfully Saved");
            setOpen(false);
          } else {
            toast.error(res.message);
          }
          backToDashBoard();
          getValue();
        })
        .catch(() => {
          toast.error("Something Went Wrong");
          backToDashBoard();
          setOpen(false);
        });
    } else {
      toast.warning("Please Enter All details");
    }
  };
  const handleUpdate = () => {
    if (companyName && data?.companyCode) {
      let value = {
        _id: data._id,
        companyId: companyName,
        companyCode: data.companyCode,
        description: data.description,
      };
      setOpen(true);
      companyCodeUpdate(value, value._id)
        .then((res) => {
          toast("Successfully Updated");
          getValue();
          backToDashBoard();
          setOpen(false);
        })
        .catch(() => {
          toast.error("Something Went Wrong");
          backToDashBoard();
          setOpen(false);
        });
    } else {
      toast.warning("Please Enter All Details");
    }
  };
  const handleSubmit = async () => {
    formType === "edit" ? handleUpdate() : handleSave();
  };
  const handleCancel = () => {
    backToDashBoard();
  };

  const getCompanyName = () => {
    companyCodeIdGet()
      .then((res) => {
        setCompany(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCompanyName();
  }, []);
  return (
    <>
      <div className="formContainer h-100">
        <OverlayScrollbarsComponent className="formBody">
          <Formik innerRef={(ref) => {}}>
            {({ values }) => (
              <Form noValidate>
                <div className="dropDownContainer col-12 mb-2">
                  <FloatLabel label="Company Name" value={companyName}>
                    <Select
                      labelid="Company ID"
                      className="w-100"
                      value={companyName}
                      onSelect={(e) => {
                        setCompanyName(e);
                      }}
                    >
                      {company?.length > 0 &&
                        company.map((ele) => {
                          return (
                            <Option value={ele._id} key={ele._id}>
                              {ele.companyName}
                            </Option>
                          );
                        })}
                    </Select>
                  </FloatLabel>
                </div>
                <div className="col-12 mb-2 ">
                  <FloatLabel label="Company Code" value={data?.companyCode}>
                    <Field
                      type="text"
                      className="w-100 form-control"
                      value={data?.companyCode}
                      onChange={(e) =>
                        setData({ ...data, companyCode: e.target.value })
                      }
                    />
                  </FloatLabel>
                </div>
                {/* <div className="col-12 mb-2">
                  <FloatLabel label="Description" value={data?.description}>
                    <Field
                      type="text"
                      className="w-100 form-control"
                      value={data?.description}
                      onChange={(e) => {
                        setData({ ...data, description: e.target.value });
                      }}
                    />
                  </FloatLabel>
                </div> */}
              </Form>
            )}
          </Formik>
        </OverlayScrollbarsComponent>
        <footer>
          <Button className="cancelButton" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="button" onClick={handleSubmit}>
            {formType === "edit" ? "Update" : "Save"}
          </Button>
        </footer>
        <ToastContainer />
      </div>
      <Loader open={open} />
    </>
  );
};
export default AddCompanyCode;
