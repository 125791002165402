import React, { useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button } from "antd";
import { branchapipost } from "../../../../utils/servies/common";
import { branchupdate } from "../../../../utils/servies/common";
import { FloatLabel } from '../../../../UIComponent/_index';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from "../../../../Resources/Loader/Loader";

const branch = {
  branchName: "",
};

const AddBranch = (props) => {
  const { formType, selectedBranch, dataGet, backToDashboard } = props;
  let s = formType === "view" ? selectedBranch : "edit" ? selectedBranch : branch;
  const [data, setData] = useState(s);
  const [open,setOpen]=useState(false)
  const handleSave = () => {
    
    if (data?.branchName) {
      setOpen(true); 
      branchapipost(data)
        .then((res) => {
          backToDashboard()
          dataGet()
          setOpen(false)
          console.log(res)
          if (res.message === null) {
            toast.success("Successfully Saved")
          }
          else {
            toast.error(res.message)
          }
        })
        .catch((err) => {
          toast.error("Something went wrong")
          setOpen(false)
          // backToDashboard()
        });
    }
    else {
      toast.warning(" Please Enter Branch ")
      backToDashboard()
    }
  }

  const handleUpdate = () => {
    if (data?.branchName) {
      setOpen(true); 
      branchupdate(data, data?._id)
        .then(() => {
          toast("Successfully Updated")
          dataGet()
          setOpen(false)
          backToDashboard()
        }).catch(() => {
          toast.error("Something Went Wrong")
          backToDashboard()
          setOpen(false)
        })
    }
    else {
      toast.warning("Please Enter Branch")
    }
  }

  const handleSubmit = () => {
    formType === "edit" ? handleUpdate() : handleSave();
  };

  const cancel = () => {
    backToDashboard();
  };

  return (
    <>
      <div className="formContainer h-100">
        <OverlayScrollbarsComponent className="formBody">
          <Formik innerRef={(ref) => { }}>
            {({ values }) => (
              <Form noValidate>
                <div className="innerContainer row">
                  <div className="col-12 mb-2 ">
                    <FloatLabel label="Branch" value={data?.branchName}>
                      <Field
                        as="textarea"
                        className="w-100 form-control"
                        name="name"
                        value={data?.branchName}
                        onChange={(e) => {
                          setData({ ...data, branchName: e.target.value });
                        }}
                        disabled={formType === 'view' ? true : false}
                      />
                    </FloatLabel>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OverlayScrollbarsComponent>
        <footer>
          <Button className="button transButton" onClick={cancel}>
            Cancel
          </Button>
          <Button className="button" onClick={handleSubmit}
            hidden={formType === 'view' ? true : false}>
            {formType === 'edit' ? 'Update' : 'Save'}
          </Button>
        </footer>
        <ToastContainer />
      </div>
      <Loader open={open} />
    </>
  );
};
export default AddBranch;
