import React, { useState } from "react";
import { Button, Drawer, Table, Tooltip, Space, DatePicker, Input } from "antd";
import ViewReports from "./viewReport";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { reportPost } from "../../utils/servies/common";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { CSVLink } from "react-csv";

const Report = () => {
  const [configuration, setConfiguration] = useState({
    pagination: {
      pageSize: 10,
    },
  });

  const [selectedReport, setSelectedReport] = useState({});
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState();
  const [saveDate, setSaveDate] = useState();
  const [filterreport, setFilterReport] = useState([]);
  const [csvData, setCsvData] = useState();

  const { RangePicker } = DatePicker;

  const onChange = (value) => {
    setSaveDate(value);
  };

  const handleSubmit = async(e) => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
        status: e,
      };
      reportPost(value)
        .then(async (res) => {
          setData(res.data);
          setFilterReport(res.data);
          if (res.data.length) {
            toast("Successfully Retrieved");
            var pushdata=[]
            await res.data.map(ele => {
              ele.policyNumber = `'` + ele.policyNumber
              ele.policyDate=moment(ele["policyDate"]).format("DD-MMM-YYYY")
              pushdata.push(ele)
            })
            setCsvData(pushdata)
          } else {
            toast("No data Retrieved");
          }
        })
        .catch(() => {
          toast.error("Something Went Wrong");
        });
    } else {
      toast.warning("Please Select Date");
    }
  };

  const handleAll = () => {
    if (saveDate) {
      let value = {
        startDate: saveDate[0],
        endDate: saveDate[1],
      };
      reportPost(value)
        .then((res) => {
          setData(res.data);
          setFilterReport(res.data);
          if (res.data.length) {
            toast("Successfully Retrieved");
          } else {
            toast("No data Retrieved");
          }
        })
        .catch(() => {
          toast.error("Something Went Wrong");
        });
    } else {
      toast.warning("Please Select Date");
    }
  };
  const viewReport = (element) => {
    setVisible(true);
    setSelectedReport(element);
  };

  const backToDashBoard = () => {
    setVisible(false);
  };

  const onSearch = (searchText) => {
    filterreport.length
      ? setData(
        filterreport.filter(
          (el) =>
            `${el.RegistrationNumber},${el?.companyId?.companyName},${el.customerName},${el.policyNumber},${el.odPremium},${el.netPremium},${el.totalPremium},${el.customerEmail},${el.status},${moment(el.policyDate).format("DD-MMM-YYYY")}`
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
        )
      )
      : setData(filterreport);
  };

  const searchOnChange = (event) => {
    onSearch(event.target.value);
    setConfiguration((config) => {
      return {
        ...config,
        filter: event.target.value,
      };
    });
  };
  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      render: (companyId) => {
        return companyId?.companyName;
      },
    },
    {
      title: "Policy Date",
      dataIndex: "policyDate",
      key: "policyDate",
      render: (_, element) => {
        return moment(element["policyDate"]).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Policy Number",
      dataIndex: "policyNumber",
      key: "policyNumber",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Registration Number",
      dataIndex: "RegistrationNumber",
      key: "RegistrationNumber",
    },
    {
      title: "OD Premium",
      dataIndex: "odPremium",
      key: "odPremium",
    },
    {
      title: "Net Premium",
      dataIndex: "netPremium",
      key: "netPremium",
    },
    {
      title: "Total Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
    },
    {
      title: "User Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
    },
    {
      title: "Customer Mobile",
      dataIndex: "customerMobile",
      key: "customerMobile",
    },
    {
      title: "Policy Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_, element, index) => (
        <div className="actionContainer">
          <Tooltip placement="bottom" title="View">
            <EyeOutlined onClick={() => viewReport(element)} />
          </Tooltip>
        </div>
      ),
      key: "action",
    },
  ];

  const headers = [
    { label: "Created At", key: "createdAt" },
    { label: "Company Name", key: "companyId.companyName" },
    { label: "policyType", key: "policyType.name" },
    { label: "product ", key: "product.productName" },
    { label: "Policy Number", key: "policyNumber" },
    { label: "Policy Date", key: "policyDate" },
    { label: "Customer Name", key: "customerName" },
    { label: "Customer Mobile", key: "customerMobile" },
    { label: "Registeration Number", key: "RegistrationNumber" },
    { label: "Od Premium", key: "odPremium" },
    { label: "Net Premium", key: "netPremium" },
    { label: "Total Premium", key: "totalPremium" },
    { label: "Agent Share", key: "agentShare" },
    { label: "User Email", key: "customerEmail" },
    { label: "policy Status", key: "status" },
  ];

  const csvFile = {
    filename: "Report.csv",
    headers: headers,
    data: csvData ? csvData : "",
  };
  
  return (
    <div className="mainContainer">
      <h4>Booking Data</h4>
      <div className="headerContainer row mt-4">
        <div>
          {/* <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12 col-xs-12 d-flex"> */}
          <Space direction="vertical" size={12}>
            <RangePicker
              format="DD-MM-YYYY"
              onChange={onChange}
              style={{ height: "38px" }}
            />
          </Space>

          {/* <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12 col-xs-12 d-flex justify-content-around">
          <Button
            className="reportAllbutton"
            onClick={() => {
              handleAll();
            }}
          >
            All
          </Button>
          <Button
            className="reportAllbutton"
            onClick={() => {
              handleSubmit("entry pending");
            }}
          >
            Entry Pending
          </Button>
        </div> */}
          &nbsp;&nbsp;
          {/* <div className="col-lg-4 col-md-4 col-xl-4 col-sm-12 col-xs-12 d-flex justify-content-around"> */}
          <Button
            className="reportAllbutton"
            onClick={() => {
              handleSubmit("approved");
            }}
          >
            Approved
          </Button>

          {/* <Button
            className="reportAllbutton"
            onClick={() => {
              handleSubmit("rejected");
            }}
          >
            Rejection
          </Button> */}
          {/* </div> */}

          {/* <div className="row mt-4"></div> */}
          {/* <div className="col-lg-9 col-md-9 col-xl-9 col-sm-12 col-xs-12 d-flex justify-content-end"> */}
          &nbsp;&nbsp;
          <Button className="csvButton" style={{ width: "300px" }}>
            <CSVLink className="csv" {...csvFile}>
              Export All File
            </CSVLink>
          </Button>
          {/* </div>
        <div className="col-lg-3 col-md-3 col-xl-3 col-sm-12 col-xs-12 d-flex justify-content-end"> */}
          &nbsp;&nbsp;
          <Input
            placeholder="Search"
            style={{
              height: "40px",
              width: "262.5px",
            }}
            onInput={(event) => searchOnChange(event)}
          ></Input>
        </div>
      </div>

      <div className="tableContainer mt-4">
        <Table
          columns={columns}
          scroll={{
            x: "90vw",
          }}
          dataSource={data}
          pagination={{
            pageSize: configuration.pagination.pageSize || 10,
            showQuickJumper: true,
            responsive: true,
            total: configuration.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
        />

        <Drawer
          title={`${selectedReport.companyName ? "View" : "Add"} Report`}
          className="drawer-dropDown"
          onClose={() => setVisible(false)}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          zIndex={200}
        >
          {visible ? (
            <ViewReports
              formType={selectedReport.companyName ? "view" : "add"}
              selectedReport={selectedReport}
              backToDashBoard={backToDashBoard}
            />
          ) : null}
        </Drawer>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Report;
