import {
  InboxIcon,
  DashboardIcon,
  PolicyIcon,
  ReportIcon,
  ContactPageIcon,
  StoreIcon,
  ForwardIcon,
  AccountBalanceWalletIcon,
  TollIcon
} from "../Resources/Icons/Icons";
export const menuItemsManager = [
  // {
  //   name: "Dashboard",
  //   icon: <DashboardIcon />,
  //   path: "/admin/dashboard",
  // },
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    path: "/admin/newdashboard",
  },
  {
    name: "Policy",
    path: "/policy",
    icon: <PolicyIcon />,
    defaultCollapsed: false,
    children: [
      {
        name: "Policy Create",
        path: "/policycreate",
        icon: <ForwardIcon />,
      },
      {
        name: "Policy List",
        path: "/policyEntryPending",
        icon: <ForwardIcon />,
      },
      // {
      //   name: "View Mapping Request",
      //   path: "/policyview",
      //   icon: <ForwardIcon />,
      // },
      {
        name: "Search Policy",
        path: "/SearchPolicy",
        icon: <ForwardIcon />,
      },

    ],
  },
  {
    name: "Company Login",
    icon: <StoreIcon />,
    path: "/companyLogin",
  },
  {
    name: "Report",
    icon: <ReportIcon />,
    defaultCollapsed: false,
    children: [
      // {
      //   name: "Booking Data",
      //   path: "/report",
      //   icon: <ForwardIcon />,
      // },
      {
        name: "Reports",
        path: "/reports",
        icon: <ForwardIcon />,
      },
    ],
  },
  {
    name: "Manage User",
    icon: <ContactPageIcon />,
    defaultCollapsed: false,
    children: [
      {
        name: "Add User",
        path: "/adduser",
        icon: <ForwardIcon />,
      },
      {
        name: "User Config",
        path: "/userconfig",
        icon: <ForwardIcon />,
      },
    ],
  },
  {
    name: "Renewal",
    icon: <StoreIcon />,
    path: "/renewal",
  },
  {
    name: "Payout",
    icon: <TollIcon />,
    path: "/payout"
  },
  // {
  //   name: "Payout Points",
  //   icon: <TollIcon />,
  //   path: "/payoutPoints"
  // },
  {
    name: "Wallet",
    icon: <AccountBalanceWalletIcon />,
    path: "/manager/wallet",
  }
];


export const accountMenuItems = [
  {
    name: "Profile",
    icon: <InboxIcon />,
    path: "/profile",
  }
];
