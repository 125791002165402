import React, { useState } from 'react';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button } from "antd";
import { FloatLabel } from '../../../../UIComponent/_index'
import { policyTypePost } from "../../../../utils/servies/common";
import { policyTypeUpdate } from '../../../../utils/servies/common';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from '../../../../Resources/Loader/Loader';


const policyType = {
    name: "",
    description: "",

}

const AddPolicyType = (props) => {
    const { formType, dataGet, backToDashBoard, selectedPolicyType } = props
    const policyData = formType === "edit" ? selectedPolicyType : policyType;
    const [data, setData] = useState(policyData)
    const [open,setOpen] = useState(false)
    const handleSubmit = () => {
        formType === "edit" ? handleUpdate() : handleSave()
    }
    const handleSave = () => {
        if (data?.name) {
            setOpen(true)
            policyTypePost(data)
                .then((res) => {
                    backToDashBoard();
                    dataGet();
                    setOpen(false)
                    if (!res.message) {
                        toast.success("Successfully Saved")
                    }
                    else {
                        toast.error(res.message)
                    }
                })
                .catch(() => {
                    toast.error("Something Went Wrong")
                    backToDashBoard();
                    setOpen(false)
                })
        }
        else {
            toast.warning("Please Enter All Details")
        }
    }

    const handleUpdate = () => {
        if (data.name) {
            setOpen(true)
            policyTypeUpdate(data, data._id).then(() => {
                toast("updated Successfully")
                dataGet()
                backToDashBoard()
                setOpen(false)
            }).catch(() => {
                setOpen(false)
                toast.error("Something Went Wrong")
                backToDashBoard();
            })
        }
        else {
            toast.warning("Please Enter All Details")
        }
    }

    const onCancel = () => {
        backToDashBoard();
    }
    return <>
        <div className="formContainer h-100">
            <OverlayScrollbarsComponent className="formBody">
                <Formik
                    innerRef={(ref) => {
                    }}
                >
                    {({
                    }) => <Form noValidate>
                            <div className="innerContainer row">
                                <div className="col-12 mb-2 ">
                                    <FloatLabel label="Policy Type" value={data?.name} >
                                        <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.name}
                                            onChange={(e) => { setData({ ...data, name: e.target.value }) }}
                                        />
                                    </FloatLabel>
                                </div>
                                {/* <div className="col-12 mb-2">
                                    <FloatLabel label="Descripition" value={data?.description} >
                                        <Field
                                            type="text"
                                            className="w-100 form-control"
                                            value={data?.description}
                                            onChange={(e) => { setData({ ...data, description: e.target.value }) }}
                                        />
                                    </FloatLabel>
                                </div> */}
                            </div>
                        </Form>
                    }
                </Formik>

            </OverlayScrollbarsComponent>
            <footer>
                <Button className="cancelButton" onClick={onCancel}>Cancel</Button>
                <Button className="button" onClick={handleSubmit}>
                    {formType === 'edit' ? 'Update' : 'Save'}
                </Button>
            </footer>
            <ToastContainer />
        </div>
        <Loader open={open} />
    </>
}
export default AddPolicyType;