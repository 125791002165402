import React, { useState, useEffect } from 'react';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button } from "antd";
import { notificationapi } from '../../../../utils/servies/common';
import { FloatLabel } from '../../../../UIComponent/_index'
import {notificationget,notifiUpdate} from '../../../../utils/servies/common';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from '../../../../Resources/Loader/Loader';

const notifi = {
    description: ""
}

const AddNotification = (props) => {
    const {formType,selectedNotification,getData,backToDashBoard} = props
    
    let notification = (formType === "edit")?selectedNotification:notifi
    const [data, setData] = useState(notification)
    const [open,setOpen] = useState(false)
    const handleSave = () => {
        if (data.description) {
            setOpen(true)
            notificationapi(data)
                .then(() => {
                    toast.success("Saved Successfully")
                    getData();
                    backToDashBoard()
                    setOpen(false)
                })
                .catch(() => {
                    toast.error("Something went Wrong ")
                    backToDashBoard()
                    setOpen(false)
                })
        }
        else {
            toast.warning("Enter Description")
        }
    }
    const handleUpdate = () => {
        if (data?.description) {
            setOpen(true)
            notifiUpdate(data, data._id).then(() => {
                toast("Successfully Updated")
                getData()
                backToDashBoard()
                setOpen(false)
            }).catch(() => {
                toast.error("Something Went Wrong ")
                setOpen(false)
            })
        }
        else {
            toast.warning("Please Enter All Details")
        }
      }
    const handleSubmit =async () => {
        formType === 'edit' ? handleUpdate() : handleSave() 
    }
    const handleCancel = ()=>{
        backToDashBoard()
    }
  
    return <>

        <div className="formContainer h-100">
            <OverlayScrollbarsComponent className="formBody">
                <Formik
                    innerRef={(ref) => {

                    }}

                >
                    {({
                        values
                    }) => <Form noValidate>
                            <div className="innerContainer row">
                                <div className="col-12 mb-2 ">
                                    <FloatLabel label="Descripition" value={data.description} >
                                        <Field
                                            as="textarea"
                                            className="w-100 form-control"
                                            value={data?.description}
                                            onChange={(e) => setData({ ...data, description: e.target.value })}
                                        />
                                    </FloatLabel>

                                </div>
                            </div>
                        </Form>
                    }
                </Formik>

            </OverlayScrollbarsComponent>
            <footer>
                <Button className="button transButton"onClick={handleCancel} >Cancel</Button>
                <Button className="button" onClick={handleSubmit}>
                    {formType === 'edit' ? 'Update' : 'Save'}
                </Button>
            </footer>
            <ToastContainer/>
        </div>
        <Loader open={open}/>
    </>
}
export default AddNotification;